var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    data: [],
    lastToastId: null,
};
var toastServiceState = createSlice({
    name: APP_SERVICES_REDUCER_NAMESPACES.toast,
    initialState: initialState,
    reducers: {
        addToast: function (state, action) {
            var id = Date.now();
            state.data = __spreadArray(__spreadArray([], state.data, true), [__assign(__assign({}, action.payload), { id: id })], false);
            state.lastToastId = id;
        },
        addErrorToast: function (state, action) {
            var id = Date.now();
            state.data = __spreadArray(__spreadArray([], state.data, true), [__assign(__assign({}, action.payload), { type: 'error', id: id })], false);
            state.lastToastId = id;
        },
        addSuccessToast: function (state, action) {
            var id = Date.now();
            state.data = __spreadArray(__spreadArray([], state.data, true), [__assign(__assign({}, action.payload), { type: 'success', id: id })], false);
            state.lastToastId = id;
        },
        removeToast: function (state, action) {
            state.data = state.data.filter(function (item) { return item.id !== action.payload; });
            if (!state.data.length) {
                state.lastToastId = null;
            }
        },
    },
});
export var actions = toastServiceState.actions;
export default toastServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { customizationsServiceGetFeatureKeysSelector } from 'common/services/customizations-service/selectors';
import { profileServiceMyDevelopmentSectionEnabledSelector } from 'common/services/profile-service/selectors';
import { MenuSection, OnlyUserMenu } from './modules';
import { useMenuSectionData } from './use-menu-section-data';
import { checkEqual } from 'common/helpers/checkEqual';
import styles from './navigation.module.scss';
export var Navigation = memo(function (_a) {
    var isNotDesktop = _a.isNotDesktop, className = _a.className;
    var myDevelopmentSectionEnabled = useSelector(profileServiceMyDevelopmentSectionEnabledSelector);
    var featureToggleKeys = useSelector(customizationsServiceGetFeatureKeysSelector, checkEqual);
    var userSectionData = useMenuSectionData({
        sectionName: 'user',
        featureToggleKeys: featureToggleKeys,
        isNotDesktop: isNotDesktop,
        hideMyDevelopment: !myDevelopmentSectionEnabled,
    });
    var supervisorSectionData = useMenuSectionData({ sectionName: 'supervisor', featureToggleKeys: featureToggleKeys });
    var managerSectionData = useMenuSectionData({ sectionName: 'manager', featureToggleKeys: featureToggleKeys });
    var adminSectionData = useMenuSectionData({ sectionName: 'admin', featureToggleKeys: featureToggleKeys });
    return (_jsxs("nav", __assign({ className: cs(styles.navigation, className) }, { children: [userSectionData &&
                (!managerSectionData && !adminSectionData ? (_jsx(OnlyUserMenu, { data: userSectionData })) : (_jsx(MenuSection, { hasWrapper: isNotDesktop, data: userSectionData }))), supervisorSectionData && _jsx(MenuSection, { hasWrapper: isNotDesktop, data: supervisorSectionData }), managerSectionData && _jsx(MenuSection, { hasWrapper: isNotDesktop, data: managerSectionData }), adminSectionData && _jsx(MenuSection, { hasWrapper: isNotDesktop, data: adminSectionData })] })));
});

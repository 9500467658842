var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import cs from 'classnames';
import styles from './content-wrapper.module.scss';
var DEFAULT_COMPONENT = 'div';
export var ContentWrapper = function (_a) {
    var component = _a.component, margin = _a.margin, mt = _a.mt, mb = _a.mb, ml = _a.ml, mr = _a.mr, padding = _a.padding, pt = _a.pt, pb = _a.pb, pl = _a.pl, pr = _a.pr, className = _a.className, classNameInner = _a.classNameInner, style = _a.style, children = _a.children;
    var stylePrepared = useMemo(function () {
        if (margin || mt || mb || ml || mr || padding || pt || pb || pl || pr || style) {
            return __assign({ margin: margin, marginBlockStart: mt && "".concat(mt, "rem"), marginBlockEnd: mb && "".concat(mb, "rem"), marginInlineStart: ml && "".concat(ml, "rem"), marginInlineEnd: mr && "".concat(mr, "rem"), padding: padding, paddingBlockStart: pt && "".concat(pt, "rem"), paddingBlockEnd: pb && "".concat(pb, "rem"), paddingInlineStart: pl && "".concat(pl, "rem"), paddingInlineEnd: pr && "".concat(pr, "rem") }, (style || {}));
        }
        return undefined;
    }, [margin, mt, mb, ml, mr, padding, pt, pb, pl, pr, style]);
    var Component = component || DEFAULT_COMPONENT;
    return (_jsx("div", __assign({ className: cs(styles.root, className), style: stylePrepared }, { children: _jsx(Component, __assign({ className: cs(styles.innerContainer, classNameInner) }, { children: children })) })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cs from 'classnames';
import { Icon } from 'ui-kit/icon';
import styles from './control-button.module.scss';
export var ControlButton = function (_a) {
    var _b;
    var icon = _a.icon, label = _a.label, isReverseIcon = _a.isReverseIcon, dataTestid = _a.dataTestid, className = _a.className, onClick = _a.onClick;
    return (_jsxs("p", __assign({ className: cs(styles.controlButton, className), "data-testid": dataTestid, onClick: onClick }, { children: [isReverseIcon ? label : null, _jsx(Icon, { className: cs(styles.icon, (_b = {}, _b[styles.reverse] = isReverseIcon, _b)), width: 1.25, viewBox: "0 0 20 20", component: icon }), isReverseIcon ? null : label] })));
};

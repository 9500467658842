var _a;
import { combineReducers } from 'redux';
import requestServiceReducerType from './request/reducer';
import executorsFilterServiceReducer from './executors-filter-service/reducer';
import managersAndAdminsServiceReducer from './managers-and-admins-service/reducer';
import managersAndAdminsPositionsServiceReducer from './managers-and-admins-positions-service/reducer';
import requestHistoryServiceReducer from './request-history/reducer';
import { REQUEST_PROCESSING_REDUCER_NAMESPACES } from './constants';
export var requestProcessingServicesRootReducer = combineReducers((_a = {},
    _a[REQUEST_PROCESSING_REDUCER_NAMESPACES.request] = requestServiceReducerType,
    _a[REQUEST_PROCESSING_REDUCER_NAMESPACES.executorsFilter] = executorsFilterServiceReducer,
    _a[REQUEST_PROCESSING_REDUCER_NAMESPACES.managersAndAdmins] = managersAndAdminsServiceReducer,
    _a[REQUEST_PROCESSING_REDUCER_NAMESPACES.managersAndAdminsPositions] = managersAndAdminsPositionsServiceReducer,
    _a[REQUEST_PROCESSING_REDUCER_NAMESPACES.requestHistory] = requestHistoryServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/development-block/modules/one-to-one-form/services/constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: null, answersMap: null, prevAnswersMap: null, initialAnswersMap: null, matrixAnswersMap: null, initialMatrixAnswersMap: null, prevMatrixAnswersMap: null, matrixComment: null, initialMatrixComment: null, prevMatrixComment: null, otherOptionCommentInMatrix: null, initialOtherOptionCommentInMatrix: null, prevOtherOptionCommentInMatrix: null, selectedOtherAnswerOptionInMatrixId: null, isSubmitting: false, isModalOpen: false, dependedQuestionsMap: {}, agreements: '', initialAgreements: '', previousAgreements: '' });
var oneToOneSurveyFormServiceState = createSlice({
    name: ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES.oneToOneSurveyForm,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, setAgreements: function (state, action) {
            state.agreements = action.payload;
            state.initialAgreements = action.payload;
        }, setPreviousAgreements: function (state, action) {
            state.previousAgreements = action.payload;
        }, setAnswers: function (state, action) {
            state.answersMap = action.payload;
            state.initialAnswersMap = action.payload;
        }, setPrevAnswers: function (state, action) {
            state.prevAnswersMap = action.payload;
        }, setMatrixAnswers: function (state, action) {
            state.matrixAnswersMap = action.payload;
            state.initialMatrixAnswersMap = action.payload;
        }, setPrevMatrixAnswers: function (state, action) {
            state.prevMatrixAnswersMap = action.payload;
        }, setMatrixComment: function (state, action) {
            state.matrixComment = action.payload;
            state.initialMatrixComment = action.payload;
        }, setPrevMatrixComment: function (state, action) {
            state.prevMatrixComment = action.payload;
        }, setOtherOptionCommentInMatrix: function (state, action) {
            state.otherOptionCommentInMatrix = action.payload;
            state.initialOtherOptionCommentInMatrix = action.payload;
        }, setPrevOtherOptionCommentInMatrix: function (state, action) {
            state.prevOtherOptionCommentInMatrix = action.payload;
        }, setSelectedOtherAnswerOptionInMatrixId: function (state, action) {
            state.selectedOtherAnswerOptionInMatrixId = action.payload;
        }, setDependedQuestionsMap: function (state, action) {
            state.dependedQuestionsMap = action.payload;
        }, editAnswerText: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, text = _a.text;
            if (state.answersMap && state.answersMap[questionId]) {
                state.answersMap[questionId].data.text = text;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editAnswerComment: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, comment = _a.comment;
            if (state.answersMap && state.answersMap[questionId]) {
                state.answersMap[questionId].data.comment = comment;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editMatrixComment: function (state, action) {
            var _a = action.payload, matrixId = _a.matrixId, comment = _a.comment;
            if (state.matrixComment) {
                state.matrixComment[matrixId] = comment;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editOtherOptionCommentInMatrix: function (state, action) {
            var _a = action.payload, matrixId = _a.matrixId, comment = _a.comment;
            if (state.otherOptionCommentInMatrix) {
                state.otherOptionCommentInMatrix[matrixId] = comment;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editSelectedOtherAnswerOptionInMatrixId: function (state, action) {
            var _a = action.payload, matrixId = _a.matrixId, isOtherOption = _a.isOtherOption, selectedAnswerOptionId = _a.selectedAnswerOptionId;
            if (state && state.selectedOtherAnswerOptionInMatrixId && isOtherOption) {
                state.selectedOtherAnswerOptionInMatrixId[matrixId] = selectedAnswerOptionId;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editAgreements: function (state, action) {
            state.agreements = action.payload;
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, addSelectedAnswerOption: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, referenceId = _a.referenceId, selectedAnswerOptionId = _a.selectedAnswerOptionId, text = _a.text, value = _a.value;
            if (state.answersMap && state.answersMap[questionId]) {
                state.answersMap[questionId].data.selectedAnswerOptions = [selectedAnswerOptionId];
                state.answersMap[questionId].data.selectedReferenceIds = [referenceId];
                state.answersMap[questionId].data.singleChoiceText = text;
                state.answersMap[questionId].data.singleChoiceValue = value;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, deleteSelectedAnswerOptions: function (state, action) {
            var questionId = action.payload.questionId;
            if (state.answersMap && state.answersMap[questionId]) {
                state.answersMap[questionId].data.selectedAnswerOptions = [];
                state.answersMap[questionId].data.selectedReferenceIds = [];
                state.answersMap[questionId].data.singleChoiceText = '';
                state.answersMap[questionId].data.singleChoiceValue = null;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, addSelectedMatrixAnswerOption: function (state, action) {
            var _a = action.payload, matrixId = _a.matrixId, questionId = _a.questionId, referenceId = _a.referenceId, selectedAnswerOptionId = _a.selectedAnswerOptionId, text = _a.text, value = _a.value;
            if (state.matrixAnswersMap && state.matrixAnswersMap[matrixId] && state.matrixAnswersMap[matrixId][questionId]) {
                state.matrixAnswersMap[matrixId][questionId].data.selectedAnswerOptions = [selectedAnswerOptionId];
                state.matrixAnswersMap[matrixId][questionId].data.selectedReferenceIds = [referenceId];
                state.matrixAnswersMap[matrixId][questionId].data.singleChoiceText = text;
                state.matrixAnswersMap[matrixId][questionId].data.singleChoiceValue = value;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, deleteSelectedMatrixAnswerOptions: function (state, action) {
            var _a = action.payload, matrixId = _a.matrixId, questionId = _a.questionId;
            if (state.matrixAnswersMap && state.matrixAnswersMap[matrixId] && state.matrixAnswersMap[matrixId][questionId]) {
                state.matrixAnswersMap[matrixId][questionId].data.selectedAnswerOptions = [];
                state.matrixAnswersMap[matrixId][questionId].data.selectedReferenceIds = [];
                state.matrixAnswersMap[matrixId][questionId].data.singleChoiceText = '';
                state.matrixAnswersMap[matrixId][questionId].data.singleChoiceValue = null;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, setIsSubmitting: function (state) {
            state.isSubmitting = true;
        }, resetIsSubmitting: function (state) {
            state.isSubmitting = false;
        }, openConfirmationModal: function (state) {
            state.isModalOpen = true;
        }, closeConfirmationModal: function (state) {
            state.isModalOpen = false;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = oneToOneSurveyFormServiceState.actions;
export default oneToOneSurveyFormServiceState.reducer;

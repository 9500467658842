var ABORT_REQUEST_CONTROLLERS_MAP = new Map();
export var abortRequestsService = Object.freeze({
    getRequestSignalByKey: function (abortRequestKey) {
        if (!ABORT_REQUEST_CONTROLLERS_MAP.has(abortRequestKey))
            ABORT_REQUEST_CONTROLLERS_MAP.set(abortRequestKey, new AbortController());
        return ABORT_REQUEST_CONTROLLERS_MAP.get(abortRequestKey).signal;
    },
    abortRequestByKey: function (keys) {
        return (Array.isArray(keys) ? keys : [keys]).forEach(function (key) {
            var _a;
            if (!ABORT_REQUEST_CONTROLLERS_MAP.has(key))
                return;
            (_a = ABORT_REQUEST_CONTROLLERS_MAP.get(key)) === null || _a === void 0 ? void 0 : _a.abort();
            ABORT_REQUEST_CONTROLLERS_MAP.delete(key);
        });
    },
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ABORT_REQUEST_MESSAGE } from 'common/constants/abort-requests';
export var baseReducers = {
    setInitial: function (state, action) {
        state.initialized = action.payload;
    },
    setLoading: function (state, action) {
        state.loading = action.payload;
        state.error = null;
    },
    setError: function (state, action) {
        var _a;
        var isAbortedRequest = ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.message) === ABORT_REQUEST_MESSAGE;
        if (isAbortedRequest || (action.payload && action.payload.canceled)) {
            if (state.error)
                state.error = null;
        }
        else {
            state.error = action.payload;
        }
        state.loading = false;
        if (!isAbortedRequest && !state.initialized)
            state.initialized = true;
    },
};
export var baseListReducer = __assign({ setCurrentPage: function (state, action) {
        state.currentPage = action.payload;
    }, setPageSize: function (state, action) {
        state.pageSize = action.payload;
    } }, baseReducers);

import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { appServicesRootSelector } from '../app-services-root-selector';
import { DEFAULT_REAL_CURRENCY_NAME, DEFAULT_VIRTUAL_CURRENCY_NAME } from 'common/constants/currency-names';
import { DEFAULT_LOCALE } from 'common/constants/default-locale';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
var rootName = APP_SERVICES_REDUCER_NAMESPACES.common;
var stateName = APP_SERVICES_REDUCER_NAMESPACES.customizations;
export var customizationsRootSelector = createSelector(appServicesRootSelector, function (state) { return (state === null || state === void 0 ? void 0 : state[stateName]) || null; });
export var customizationsServiceDataSelector = createSelector(customizationsRootSelector, function (state) { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.customization) || initialState.data.customization; });
export var customizationsServiceLoadingSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].loading);
};
export var customizationsServiceInitializedSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].initialized);
};
export var customizationsServiceLogoImagePathSelector = function (_a) {
    var _b, _c, _d, _e;
    var _f = rootName, state = _a[_f];
    return ((_e = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.logoImage) === null || _e === void 0 ? void 0 : _e.fullPath) || null;
};
export var customizationsServiceVirtualCurrencyNameSelector = function (_a) {
    var _b, _c, _d;
    var _e = rootName, state = _a[_e];
    return ((_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.virtualCurrencyName) || DEFAULT_VIRTUAL_CURRENCY_NAME;
};
export var customizationsServiceRealCurrencyNameSelector = function (_a) {
    var _b, _c, _d;
    var _e = rootName, state = _a[_e];
    return ((_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.realCurrencyName) || DEFAULT_REAL_CURRENCY_NAME;
};
export var customizationsServiceTealBalanceCurrencyIconPathSelector = function (_a) {
    var _b, _c, _d, _e;
    var _f = rootName, state = _a[_f];
    return ((_e = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.tealBalanceCurrencyIcon) === null || _e === void 0 ? void 0 : _e.fullPath) || null;
};
export var customizationsServiceThankBalanceCurrencyIconPathSelector = function (_a) {
    var _b, _c, _d, _e;
    var _f = rootName, state = _a[_f];
    return ((_e = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.thankBalanceCurrencyIcon) === null || _e === void 0 ? void 0 : _e.fullPath) || null;
};
export var customizationsServiceStylesMapPathSelector = function (_a) {
    var _b, _c, _d, _e;
    var _f = rootName, state = _a[_f];
    return ((_e = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.colorsCustomizationJson) === null || _e === void 0 ? void 0 : _e.fullPath) || null;
};
export var customizationsServiceBannersSelector = function (_a) {
    var _b, _c, _d;
    var _e = rootName, state = _a[_e];
    return ((_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.banners) || [];
};
export var customizationsServicePostsCompanyDataSelector = function (_a) {
    var _b, _c, _d;
    var _e = rootName, state = _a[_e];
    if (!((_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization))
        return { title: null, avatar: null };
    var _f = state[stateName].data.customization, newsCompanyTitle = _f.newsCompanyTitle, newsCompanyAvatar = _f.newsCompanyAvatar;
    return {
        title: newsCompanyTitle,
        avatar: (_d = newsCompanyAvatar === null || newsCompanyAvatar === void 0 ? void 0 : newsCompanyAvatar.fullPath) !== null && _d !== void 0 ? _d : null,
    };
};
export var customizationsServicePostsCompanyTitleSelector = function (_a) {
    var _b, _c, _d, _e;
    var _f = rootName, state = _a[_f];
    return (_e = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.newsCompanyTitle) !== null && _e !== void 0 ? _e : null;
};
export var customizationsServicePostsCompanyAvatarSelector = function (_a) {
    var _b, _c, _d, _e, _f;
    var _g = rootName, state = _a[_g];
    return (_f = (_e = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.customization) === null || _d === void 0 ? void 0 : _d.newsCompanyAvatar) === null || _e === void 0 ? void 0 : _e.fullPath) !== null && _f !== void 0 ? _f : null;
};
export var customizationsServiceGetFeatureKeysSelector = function (_a) {
    var _b, _c;
    var _d = rootName, state = _a[_d];
    return ((_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.companyEnabledFeatureKeys) || [];
};
export var customizationsServicecoIsEnabledFeatureKeySelector = function (key) {
    return function (_a) {
        var _b, _c;
        var _d = rootName, state = _a[_d];
        return ((_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.companyEnabledFeatureKeys.includes(key)) || false;
    };
};
export var customizationsServiceCompanyCultureSelector = function (_a) {
    var _b, _c, _d;
    var _e = rootName, state = _a[_e];
    return (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state[stateName]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.culture.localizationKey) !== null && _d !== void 0 ? _d : DEFAULT_LOCALE;
};

var _a;
import { combineReducers } from 'redux';
import rewardsStatisticsServiceReducer from './rewards-statistics-service/reducer';
import rewardsStatisticsChartServiceReducer from './rewards-statistics-chart-service/reducer';
import rewardsStatisticsFilterServiceReducer from './rewards-statistics-filter-service/reducer';
import { MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var modularAnalyticsRewardsStatisticsRootReducer = combineReducers((_a = {},
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.rewardsStatistics] = rewardsStatisticsServiceReducer,
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.rewardsStatisticsChart] = rewardsStatisticsChartServiceReducer,
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.rewardsStatisticsFilter] = rewardsStatisticsFilterServiceReducer,
    _a));

import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ControlButton } from '../../../control-button';
import { HelpIcon } from 'common/icons';
import { useOpenCreateRequestFromAction } from 'common/components/create-support-reuest-form';
import { OPEN_SUPPORT_REQUEST_FORM_MENU_BUTTON_TESTID } from 'common/layouts/main-layout/constants';
export var Footer = function (_a) {
    var className = _a.className;
    var t = useTranslation().t;
    var openCreateSupportRequestForm = useOpenCreateRequestFromAction();
    return (_jsx(ControlButton, { className: className, label: t('common.menu.openSupportRequestFormButtonText'), icon: HelpIcon, dataTestid: OPEN_SUPPORT_REQUEST_FORM_MENU_BUTTON_TESTID, onClick: openCreateSupportRequestForm }));
};

export var SortingActivitiesType;
(function (SortingActivitiesType) {
    SortingActivitiesType["isActive"] = "isActive";
    SortingActivitiesType["isArchived"] = "isArchived";
    SortingActivitiesType["isAutomated"] = "isAutomated";
    SortingActivitiesType["isRequest"] = "isRequest";
})(SortingActivitiesType || (SortingActivitiesType = {}));
export var ActivitiesVariantType;
(function (ActivitiesVariantType) {
    ActivitiesVariantType["activities"] = "activities";
    ActivitiesVariantType["activitySuggestions"] = "activitySuggestions";
})(ActivitiesVariantType || (ActivitiesVariantType = {}));

var _a;
import { combineReducers } from 'redux';
import employeesFilterServiceReducer from './employees-filter-service/reducer';
import employeesServiceReducer from './employees-service/reducer';
import questionsFilterServiceReducer from './questions-filter-service/reducer';
import questionsServiceReducer from './questions-service/reducer';
import finishModalsServiceReducer from './finish-modals-service/reducer';
import questionCategoriesReducer from './question-categories-service/reducer';
import { SURVEY_FORM_SERVICES_REDUCER_NAMESPACES } from './constants';
export var surveyFormServicesRootReducer = combineReducers((_a = {},
    _a[SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.employeesFilter] = employeesFilterServiceReducer,
    _a[SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.employees] = employeesServiceReducer,
    _a[SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.questionsFiter] = questionsFilterServiceReducer,
    _a[SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.questions] = questionsServiceReducer,
    _a[SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.finishModals] = finishModalsServiceReducer,
    _a[SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.questionCategories] = questionCategoriesReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import cs from 'classnames';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import styles from './menu-button.module.scss';
var getClassNames = function (_a) {
    var _b;
    var isActive = _a.isActive;
    return cs(styles.menuButton, (_b = {}, _b[styles.active] = isActive, _b));
};
var MenuButtonContent = function (_a) {
    var icon = _a.icon, text = _a.text;
    return (_jsxs(_Fragment, { children: [icon && _jsx(Icon, { className: styles.icon, width: 1.25, viewBox: "0 0 20 20", component: icon }), _jsx(Typography, __assign({ variant: "body-xs", component: "span" }, { children: text }))] }));
};
export var MenuButton = function (_a) {
    var icon = _a.icon, text = _a.text, link = _a.link, isActive = _a.isActive, onClick = _a.onClick;
    if (link) {
        return (_jsx(NavLink, __assign({ className: getClassNames, to: link }, { children: _jsx(MenuButtonContent, { icon: icon, text: text }) })));
    }
    return (_jsx("div", __assign({ className: getClassNames({ isActive: isActive !== null && isActive !== void 0 ? isActive : false }), onClick: onClick }, { children: _jsx(MenuButtonContent, { icon: icon, text: text }) })));
};

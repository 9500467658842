var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box } from 'ui-kit/box';
import { Button } from 'ui-kit/button';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import { FallbackIcon, PlatformLogo } from 'common/icons';
import { createErrorHandler } from 'common/utils/create-error-handler/create-error-handler';
import styles from './fallback-component.module.scss';
export var FallbackComponent = function (_a) {
    var error = _a.error, resetErrorBoundary = _a.resetErrorBoundary;
    var t = useTranslation().t;
    var errorHandler = createErrorHandler(error, resetErrorBoundary);
    return (_jsxs(Box, __assign({ className: styles.container, display: "flex", justify: "center", direction: "column", alignItems: "center" }, { children: [_jsx("header", __assign({ className: styles.logoWrapper }, { children: _jsx(Icon, { className: styles.logo, viewBox: "0 0 119 34", component: PlatformLogo }) })), _jsx(Icon, { width: 20, height: 20, viewBox: "0 0 200 200", component: FallbackIcon }), _jsx("br", {}), _jsx(Typography, __assign({ variant: "h2" }, { children: t('fallbackPage.title') })), _jsx(Typography, __assign({ color: "semi-light" }, { children: t('fallbackPage.subtitle') })), _jsx("br", {}), errorHandler.onReset && (_jsx(Button, __assign({ type: "button", onClick: errorHandler.onReset }, { children: t('fallbackPage.reloadButton') })))] })));
};

var _a;
import { combineReducers } from 'redux';
import settingsEmissionServiceReducer from '../settings-page/services/emission-service/reducer';
import featureManagementFormServiceReducer from '../settings-page/services/feature-management-form-service/reducer';
import settingServiceReducer from './settings-service/reducer';
import editEmissionServiceReducer from '../edit-emission-page/services/edit-emission-service/reducer';
import notificationsServiceReducer from '../settings-page/modules/notifications/service/reducer';
import { SETTINGS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var settingsServicesRootReducer = combineReducers((_a = {},
    _a[SETTINGS_SERVICES_REDUCER_NAMESPACES.emissionList] = settingsEmissionServiceReducer,
    _a[SETTINGS_SERVICES_REDUCER_NAMESPACES.editEmission] = editEmissionServiceReducer,
    _a[SETTINGS_SERVICES_REDUCER_NAMESPACES.settingsBase] = settingServiceReducer,
    _a[SETTINGS_SERVICES_REDUCER_NAMESPACES.featureManagementForm] = featureManagementFormServiceReducer,
    _a[SETTINGS_SERVICES_REDUCER_NAMESPACES.notifications] = notificationsServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cs from 'classnames';
import { Box } from 'ui-kit/box';
import { Icon } from 'ui-kit/icon';
import { ReactComponent as StarIcon } from './star.svg';
import styles from './score-button.module.scss';
var SELECTED_COLOR = '#FFC403';
var BASE_COLOR = '#D9D9D9';
export var ScoreButton = function (_a) {
    var isActive = _a.isActive, className = _a.className, onActivate = _a.onActivate, rest = __rest(_a, ["isActive", "className", "onActivate"]);
    return (_jsx(Box, __assign({ className: cs(styles.scoreButton, className), onClick: onActivate }, rest, { children: _jsx(Icon, { style: { fill: isActive ? SELECTED_COLOR : BASE_COLOR }, width: 3, viewBox: "0 0 48 48", component: StarIcon }) })));
};

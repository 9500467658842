var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from 'ui-kit/typography';
import { checkIsRtlDirection } from 'common/helpers/check-is-rtl-direction';
export var Counter = function (_a) {
    var isError = _a.isError, value = _a.value, maxLength = _a.maxLength;
    var isRtlDirection = checkIsRtlDirection();
    return (_jsx(Typography, __assign({ variant: "body-s", color: isError ? 'error' : 'light' }, { children: isRtlDirection ? "".concat(maxLength, "/").concat(value) : "".concat(value, "/").concat(maxLength) })));
};

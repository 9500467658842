var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: null, valuablesOptions: [] });
var gratitudesSettingsServiceState = createSlice({
    name: SHARED_SERVICES_REDUCER_NAMESPACES.gratitudesSettings,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (state.valuablesOptions.length === 0) {
                state.valuablesOptions = action.payload.valuables.map(function (value) { return ({
                    label: value,
                    value: value,
                }); });
            }
            if (!state.initialized)
                state.initialized = true;
        }, addValuablesOption: function (state, action) {
            state.valuablesOptions.push({
                label: action.payload,
                value: action.payload,
            });
        }, reset: function () { return initialState; } }, baseReducers),
});
export var actions = gratitudesSettingsServiceState.actions;
export default gratitudesSettingsServiceState.reducer;

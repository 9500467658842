var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { baseListReducer } from 'common/utils/base-reduсer';
import { SURVEY_FORM_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, pageSize: 10, editableQuestionData: null, selectedQuestions: [], showChoiceQuestionsTemplateForm: false, showCreateQustionForm: false, removableQuestionIndex: null, presetQuestionData: null });
var questionsServiceState = createSlice({
    name: SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.questions,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, resetData: function (state) {
            state.data = {
                total: 0,
                pageData: {
                    page: 0,
                    pageSize: 0,
                },
                content: [],
            };
            state.pageSize = 10;
            state.initialized = false;
        }, selectQuestion: function (state, action) {
            state.selectedQuestions = __spreadArray(__spreadArray([], state.selectedQuestions, true), [action.payload], false);
        }, removeSelectedQuestion: function (state, action) {
            state.selectedQuestions = state.selectedQuestions.filter(function (item) { return item.id !== action.payload; });
        }, resetSelectedQuestions: function (state) {
            state.selectedQuestions = [];
        }, showCreateQustionForm: function (state) {
            state.showCreateQustionForm = true;
        }, hideCreateQustionForm: function (state) {
            state.showCreateQustionForm = false;
        }, showChoiceQuestionsTemplateForm: function (state) {
            state.showChoiceQuestionsTemplateForm = true;
        }, hideChoiceQuestionsTemplateForm: function (state) {
            state.showChoiceQuestionsTemplateForm = false;
        }, setRemovableQuestionIndex: function (state, action) {
            state.removableQuestionIndex = action.payload;
        }, resetRemovableQuestionIndex: function (state) {
            state.removableQuestionIndex = null;
        }, setEditableQuestionData: function (state, action) {
            state.editableQuestionData = action.payload;
        }, removeEditableQuestionData: function (state) {
            state.editableQuestionData = null;
        }, setPresetQuestionData: function (state, action) {
            state.presetQuestionData = action.payload;
        }, removePresetQuestionData: function (state) {
            state.presetQuestionData = null;
        } }, baseListReducer),
});
export var actions = questionsServiceState.actions;
export default questionsServiceState.reducer;

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Forbidden } from 'pages/error-pages/forbidden';
import { userService } from 'common/utils/user-service';
export var RolesRoute = function (_a) {
    var title = _a.title, owner = _a.owner, children = _a.children;
    var isAccess = !owner || userService.hasRole(owner);
    useEffect(function () {
        if (title)
            document.title = isAccess ? title : '403 Forbidden';
    }, [title, isAccess]);
    return isAccess ? _jsx(_Fragment, { children: children }) : _jsx(Forbidden, {});
};

var _a;
import { combineReducers } from 'redux';
import { USER_SERVICES_REDUCER_NAMESPACES } from './constants';
import { operationsServicesRootReducer, } from '../profile-page/modules/operations-list/services/root-reducer';
import ordersServiceReducer from '../profile-page/modules/orders-list/service/reducer';
import userSurveysServiceReducer from '../profile-page/modules/user-surveys/service/reducer';
import userSupportRequestsServiceReducer from '../profile-page/modules/support-requests/service/reducer';
import titleServiceReducer from '../profile-page/modules/title/service/reducer';
import { profileSettingsServicesRootReducer, } from '../settings-profile-page/service/root-reducer';
import myAchievementsServiceReducer from 'pages/user-pages/profile/profile-page/modules/my-achievements/service/reducer';
export var userServicesRootReducer = combineReducers((_a = {},
    _a[USER_SERVICES_REDUCER_NAMESPACES.operations] = operationsServicesRootReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.orders] = ordersServiceReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.userSurveys] = userSurveysServiceReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.userSupportRequests] = userSupportRequestsServiceReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.title] = titleServiceReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.settingsProfile] = profileSettingsServicesRootReducer,
    _a[USER_SERVICES_REDUCER_NAMESPACES.myAchievements] = myAchievementsServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box } from 'ui-kit/box';
import { Button } from 'ui-kit/button';
import { CLOSE_OPTIONS_LIST_BUTTON_POSTFIX_TESTID, RESET_SELECTED_OPTIONS_BUTTON_POSTFIX_TESTID, } from '../../constants';
var CLOSE_BUTTON_STYLE = { marginInlineStart: 'auto' };
export var OptionsListFooter = function (_a) {
    var name = _a.name, disabledResetButton = _a.disabledResetButton, onReset = _a.onReset, onClose = _a.onClose;
    var t = useTranslation().t;
    return (_jsxs(Box, __assign({ display: "flex" }, { children: [_jsx(Button, __assign({ type: "button", variant: "text", disabled: disabledResetButton, dataTestid: "".concat(name, "-").concat(RESET_SELECTED_OPTIONS_BUTTON_POSTFIX_TESTID), onClick: onReset }, { children: t('uiKit.optionsList.resetButtonText') })), _jsx(Button, __assign({ type: "button", variant: "text", dataTestid: "".concat(name, "-").concat(CLOSE_OPTIONS_LIST_BUTTON_POSTFIX_TESTID), style: CLOSE_BUTTON_STYLE, onClick: onClose }, { children: t('uiKit.optionsList.closeButtonText') }))] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
export var useActionsSelector = function (actions) {
    var dispatch = useDispatch();
    return useMemo(function () {
        return Object.entries(actions).reduce(function (acc, _a) {
            var _b;
            var key = _a[0], action = _a[1];
            var callableAction = action;
            return __assign(__assign({}, acc), (_b = {}, _b[key] = function () {
                var a = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    a[_i] = arguments[_i];
                }
                return dispatch(callableAction.apply(void 0, a));
            }, _b));
        }, {});
    }, [dispatch, actions]);
};

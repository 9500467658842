import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import intervalToDuration from 'date-fns/intervalToDuration';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import ar from 'date-fns/locale/ar';
import { t } from 'i18next';
import { pluralize } from './pluralize';
import { capitalizeFirstLetter } from './capitalize-first-latter';
import { getLocale } from './get-locale';
import { ONE_MINUTE } from 'common/constants/common-time';
var LOCALE_MAP = {
    ru: ru,
    'ru-RU': ru,
    en: en,
    'en-GB': en,
    'en-US': en,
    ar: ar,
};
var DEF_FORMAT = 'PP';
var locale = getLocale();
var currentLocale = LOCALE_MAP[locale] || en;
var getCurrentFormattedDate = function (date, targetFormat) {
    var curretnFormat = targetFormat || DEF_FORMAT;
    if (!targetFormat || !['dd MMMM', 'd MMMM'].includes(curretnFormat)) {
        return format(date, curretnFormat, { locale: currentLocale });
    }
    return format(date, locale.includes('ru') ? curretnFormat : 'MMMM d', { locale: currentLocale });
};
var getCurrentFormattedUTCDate = function (date, targetFormat) {
    var currentFormat = targetFormat || DEF_FORMAT;
    var UTCDate = date.valueOf() + date.getTimezoneOffset() * 60000;
    if (!targetFormat || !['dd MMMM', 'd MMMM'].includes(currentFormat)) {
        return format(UTCDate, currentFormat, { locale: currentLocale });
    }
    return format(UTCDate, locale.includes('ru') ? currentFormat : 'MMMM d', { locale: currentLocale });
};
export var getUTCTimestamp = function (date) {
    var currentDate = typeof date === 'string' ? new Date(date) : date;
    currentDate.setUTCHours(0, 0, 0);
    return currentDate.valueOf() + currentDate.getTimezoneOffset() * ONE_MINUTE;
};
export var getMonthName = function (monthNumber, isCapitalizeFirstLetter) {
    if (isCapitalizeFirstLetter === void 0) { isCapitalizeFirstLetter = true; }
    var monthName = format(new Date().setMonth(monthNumber, 1), 'LLLL', { locale: currentLocale });
    if (isCapitalizeFirstLetter) {
        return capitalizeFirstLetter(monthName);
    }
    return monthName;
};
export var formatISODate = function (date, isWithoutTimeZone) {
    if (isWithoutTimeZone === void 0) { isWithoutTimeZone = false; }
    var currentDate = typeof date === 'string' ? new Date(date) : date;
    if (isWithoutTimeZone)
        return formatISO(currentDate).slice(0, 19) + 'Z';
    return formatISO(currentDate);
};
export var getFormatISODateWithTime = function (date, dateStart, dateEnd) {
    var currentDate = typeof date === 'string' ? new Date(date) : date;
    var currentDateStart = typeof dateStart === 'string' ? new Date(dateStart) : dateStart;
    var currentDateEnd = dateEnd ? (typeof dateEnd === 'string' ? new Date(dateEnd) : dateEnd) : null;
    var timeNow = Date.now();
    var timeCurrent = currentDate.getTime() > timeNow ? timeNow : currentDate.getTime();
    if (currentDateStart.getTime() >= timeCurrent) {
        return formatISO(currentDateStart);
    }
    if (currentDateEnd && currentDateEnd.getTime() < timeCurrent) {
        return formatISO(currentDateEnd);
    }
    return formatISO(timeCurrent);
};
export var getFormattedDate = function (date, targetFormat) {
    var currentDate = typeof date === 'string' ? new Date(date) : date;
    return getCurrentFormattedDate(currentDate, targetFormat);
};
export var getFormattedDateMissTimeZone = function (date, targetFormat) {
    return getFormattedDate(date.slice(0, 10), targetFormat);
};
export var getFormattedUTCDate = function (date, targetFormat) {
    var currentDate = typeof date === 'string' ? new Date(date) : date;
    return getCurrentFormattedUTCDate(currentDate, targetFormat);
};
export var getFormattedDateWithoutTimeZone = function (date, targetFormat) {
    return getCurrentFormattedDate(new Date(date.slice(0, 10)), targetFormat);
};
export var getDistanceToNow = function (date) {
    var currentDate = typeof date === 'string' ? new Date(date) : date;
    var _a = intervalToDuration({
        start: currentDate,
        end: Date.now(),
    }), years = _a.years, months = _a.months;
    var preparedYears = years ? "".concat(years, " ").concat(pluralize(years, t('pluralize.year').split('.')), " ") : '';
    var preparedMonth = months ? "".concat(months, " ").concat(pluralize(months, t('pluralize.month').split('.'))) : '';
    return preparedYears + preparedMonth;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getFormattedDate } from 'common/helpers/get-formatted-date';
import { NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES } from './constants';
export var notificationDrawerServiceIsOpenSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Boolean(state.isOpen);
};
export var notificationDrawerServiceDataSelector = function (_a) {
    var _b, _c;
    var _d = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_d];
    if (!((_b = state.data) === null || _b === void 0 ? void 0 : _b.content)) {
        return [];
    }
    return (_c = state.data) === null || _c === void 0 ? void 0 : _c.content.map(function (el) { return (__assign(__assign({}, el), { createdOn: getFormattedDate(el.createdOn, 'Pp') })); });
};
export var notificationDrawerServiceLoadingSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Boolean(state.loading);
};
export var notificationDrawerServiceLastNotificationDateSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return state.lastNotificationDate;
};
export var notificationDrawerServiceIsNotReadSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Boolean(state.onlyNotRead);
};
export var notificationDrawerServicePageSizeSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Number(state.pageSize);
};
export var notificationDrawerServiceTotalPagesSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Number(state.totalPages || 1);
};
export var notificationDrawerServiceCurrentPageSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Number(state.currentPage);
};
export var notificationDrawerServiceInitializedSelector = function (_a) {
    var _b = NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer, state = _a[_b];
    return Boolean(state.initialized);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { sidebarServiceIsPinnedMenuSelector } from '../../../../service/sidebar-service/selectors';
import { Box } from 'ui-kit/box';
import { Paper } from 'ui-kit/paper';
import { Icon } from 'ui-kit/icon';
import { AngleDoubleRightIcon } from 'common/layouts/icons';
import styles from './toggle-menu-button.module.scss';
export var ToggleMenuButton = function (_a) {
    var _b;
    var onClick = _a.onClick;
    var isPinned = useSelector(sidebarServiceIsPinnedMenuSelector);
    return (_jsx("div", __assign({ className: cs(styles.toggleMenuButtonWrapper, (_b = {}, _b[styles.hidden] = isPinned, _b)) }, { children: _jsx(Paper, __assign({ className: styles.toggleMenuButton, onClick: onClick }, { children: _jsx(Box, __assign({ px: 8, py: 2, alignItems: "center", justify: "center" }, { children: _jsx(Icon, { component: AngleDoubleRightIcon }) })) })) })));
};

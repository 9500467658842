var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import cs from 'classnames';
import { Box } from 'ui-kit/box';
import { LazyImage } from 'ui-kit/lazy-image';
import { FILE_END_POINT } from 'common/constants/common';
import styles from './user-avatar.module.scss';
export var UserAvatar = function (_a) {
    var _b;
    var _c = _a.size, size = _c === void 0 ? 'middle' : _c, firstName = _a.firstName, lastName = _a.lastName, imagePath = _a.imagePath, isContrast = _a.isContrast, withBorder = _a.withBorder, className = _a.className, onClick = _a.onClick, rest = __rest(_a, ["size", "firstName", "lastName", "imagePath", "isContrast", "withBorder", "className", "onClick"]);
    var handleClick = function (e) {
        e.stopPropagation();
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    return (_jsx(Box, __assign({ className: cs(styles.userAvatar, (_b = {}, _b[styles.contrast] = isContrast, _b[styles.border] = withBorder, _b), styles[size], className), justify: "center", alignItems: "center", display: "inline-flex", onClick: handleClick }, rest, { children: imagePath ? (_jsx(LazyImage, { src: "".concat(FILE_END_POINT, "/").concat(imagePath) })) : (_jsx("span", { children: "".concat(firstName[0]).concat(lastName[0]) })) })));
};

import { createSlice } from '@reduxjs/toolkit';
import { PLACE_MARKET_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    category: undefined,
    categoryId: undefined,
};
var productsFilterServiceState = createSlice({
    name: PLACE_MARKET_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        choiceSortingVarian: function (state, action) {
            state.category = action.payload;
        },
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        setCategoryId: function (state, action) {
            state.categoryId = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = productsFilterServiceState.actions;
export default productsFilterServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatusType } from 'common/types/server-api-dtos';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { loading: true, pageSize: 12, data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, acceptApplicationData: null, rejectApplicationData: null });
var applicationsServiceState = createSlice({
    name: APPLICATIONS_SERVICES_REDUCER_NAMESPACES.supervisorApplicationsList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData, content = _a.content;
            if (!state.initialized || state.data.total !== total) {
                state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            }
            state.data.total = total;
            state.data.pageData = pageData;
            state.data.content = pageData.page > 0 ? __spreadArray(__spreadArray([], state.data.content, true), content, true) : __spreadArray([], content, true);
            state.currentPage = pageData.page + 1;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, changeAcceptApplicationData: function (state, action) {
            if (action.payload) {
                var data = state.data.content.find(function (item) { return item.id === action.payload; });
                if (data) {
                    state.acceptApplicationData = {
                        id: data.id,
                        activityId: data.activityId,
                        participatedEmployeeId: data.participatedEmployeeId,
                        participationDate: data.participationDate,
                        description: data.description,
                        declinationReason: null,
                        requestStatus: RequestStatusType.Confirmed,
                    };
                }
            }
            else {
                state.acceptApplicationData = null;
            }
        }, changeRejectApplicationData: function (state, action) {
            if (action.payload) {
                var data = state.data.content.find(function (item) { return item.id === action.payload; });
                if (data) {
                    state.rejectApplicationData = {
                        id: data.id,
                        activityId: data.activityId,
                        participatedEmployeeId: data.participatedEmployeeId,
                        participationDate: data.participationDate,
                        description: data.description,
                        declinationReason: null,
                        requestStatus: RequestStatusType.Declined,
                    };
                }
            }
            else {
                state.rejectApplicationData = null;
            }
        }, editApplication: function (state, action) {
            state.data.content = state.data.content.map(function (item) { return (item.id === action.payload.id ? action.payload : item); });
            state.loading = false;
        }, removeApplication: function (state, action) {
            state.data.content = state.data.content.filter(function (_a) {
                var id = _a.id;
                return id !== action.payload;
            });
            state.loading = false;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = applicationsServiceState.actions;
export default applicationsServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { setIn } from 'final-form';
export function makeValidateSync(validator, options) {
    return function (values) {
        try {
            validator.validateSync(values, __assign({ abortEarly: false, context: values }, options));
            return {};
        }
        catch (err) {
            return err.inner.reduce(function (formError, innerError) {
                return setIn(formError, innerError.path, innerError.message);
            }, {});
        }
    };
}

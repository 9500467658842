import { useEffect } from 'react';
import { useLocation } from 'react-router';
export var RoutesObserver = function (_a) {
    var onClose = _a.onClose;
    var location = useLocation();
    useEffect(function () {
        onClose();
    }, [onClose, location]);
    return null;
};

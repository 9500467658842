var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNotificationDrawerActions } from 'common/layouts/main-layout/service/notification-drawer-service/hooks';
import { useProfileActions } from 'common/services/profile-service/hooks';
import { notificationDrawerServiceDataSelector } from 'common/layouts/main-layout/service/notification-drawer-service/selectors';
import { EmptyNotificationList, NotificationListItem } from './modules';
import { openNewTab } from 'common/helpers/open-new-tab';
import { checkEqual } from 'common/helpers/checkEqual';
import styles from './notification-list-container.module.scss';
export var NotificationListContainer = forwardRef(function (_a, ref) {
    var children = _a.children;
    var readNotificationAction = useNotificationDrawerActions().readNotificationAction;
    var setHasUnreadNotifications = useProfileActions().setHasUnreadNotifications;
    var data = useSelector(notificationDrawerServiceDataSelector, checkEqual);
    var handleReadNotification = useCallback(function (pushId, isRead) {
        readNotificationAction({
            pushId: pushId,
            isRead: !isRead,
            successCb: isRead ? function () { return setHasUnreadNotifications(true); } : undefined,
        });
    }, [readNotificationAction, setHasUnreadNotifications]);
    var handleRedirectNotification = useCallback(function (link) {
        if (link) {
            openNewTab(link);
        }
    }, []);
    var notificationsList = data.map(function (item) { return (_createElement(NotificationListItem, __assign({}, item, { key: item.id, handleRedirect: handleRedirectNotification, handleRead: handleReadNotification }))); });
    return (_jsxs("div", __assign({ className: styles.wrapper, ref: ref }, { children: [notificationsList.length ? notificationsList : _jsx(EmptyNotificationList, {}), children] })));
});

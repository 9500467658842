var _a;
import { combineReducers } from 'redux';
import awardsForFormSelectServiceReducer from './awards-for-form-select-service/reducer';
import awardsFilterServiceReducer from './filter-service/reducer';
import awardsListServiceReducer from './awards-list/reducer';
import { AWARDS_PAGE_SERVICES_REDUCER_NAMESPACES } from './constants';
export var awardsPageServicesRootReducer = combineReducers((_a = {},
    _a[AWARDS_PAGE_SERVICES_REDUCER_NAMESPACES.awardsForFormSelect] = awardsForFormSelectServiceReducer,
    _a[AWARDS_PAGE_SERVICES_REDUCER_NAMESPACES.awardsFilter] = awardsFilterServiceReducer,
    _a[AWARDS_PAGE_SERVICES_REDUCER_NAMESPACES.awardsList] = awardsListServiceReducer,
    _a));

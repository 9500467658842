var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, } from 'react';
import cs from 'classnames';
import './typography.scss';
var componentMap = (_a = {
        h0: 'h1',
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h5',
        subtitle2: 'h6'
    },
    _a['body-m'] = 'p',
    _a['body-s'] = 'p',
    _a['body-l'] = 'p',
    _a['body-xs'] = 'p',
    _a);
var DEFAULT_COMPONENT = 'p';
export var Typography = forwardRef(function (_a, ref) {
    var variant = _a.variant, variantSm = _a.variantSm, variantMd = _a.variantMd, variantLg = _a.variantLg, variantXl = _a.variantXl, align = _a.align, alignSm = _a.alignSm, alignMd = _a.alignMd, alignLg = _a.alignLg, alignXl = _a.alignXl, component = _a.component, color = _a.color, weight = _a.weight, fontSize = _a.fontSize, htmlString = _a.htmlString, dataTestid = _a.dataTestid, className = _a.className, style = _a.style, children = _a.children, rest = __rest(_a, ["variant", "variantSm", "variantMd", "variantLg", "variantXl", "align", "alignSm", "alignMd", "alignLg", "alignXl", "component", "color", "weight", "fontSize", "htmlString", "dataTestid", "className", "style", "children"]);
    var classes = cs('typography', variant, variantSm && "sm-".concat(variantSm), variantMd && "md-".concat(variantMd), variantLg && "lg-".concat(variantLg), variantXl && "xl-".concat(variantXl), align && "align-".concat(align), alignSm && "sm-align-".concat(alignSm), alignMd && "md-align-".concat(alignMd), alignLg && "lg-align-".concat(alignLg), alignXl && "xl-align-".concat(alignXl), color && "color-".concat(color), weight && "weight-".concat(weight), className, { htmlString: htmlString });
    var size = !!fontSize ? { fontSize: "".concat(fontSize, "rem") } : undefined;
    var currentStyles = style && size ? __assign(__assign({}, style), size) : style || size;
    var variantType = variant || 'body-m';
    var Component = component ? component : componentMap[variantType] || DEFAULT_COMPONENT;
    return (_jsx(Component, __assign({ ref: ref, className: classes, dangerouslySetInnerHTML: htmlString ? { __html: htmlString } : undefined, "data-testid": dataTestid, style: currentStyles }, rest, { children: children })));
});

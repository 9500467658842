var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { t } from 'i18next';
import axios from 'axios';
import { EE, EventsBus } from '../events-bus';
import { userService } from '../user-service';
import { abortRequestsService } from '../abort-requests-service';
import { getLocale } from 'common/helpers/get-locale';
import { getFormData } from '../form/get-form-data';
import { ONE_SECOND } from 'common/constants/common-time';
import { ABORT_REQUEST_MESSAGE } from 'common/constants/abort-requests';
import { AXIOS_URL } from 'common/constants/common';
var HTTP_METHODS = Object.freeze({
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
});
var TIME_ZONE = new Intl.DateTimeFormat().resolvedOptions().timeZone;
var REQUESTS_TIMEOUT = ONE_SECOND * 30;
var CANCEL = '#Cancel';
var cancelTokenSource = null;
var axiosInstance = axios.create({
    baseURL: AXIOS_URL,
    timeout: REQUESTS_TIMEOUT,
    withCredentials: true,
});
var getAuthHeaders = function () { return ({
    'Accept-Language': getLocale(),
    'X-Timezone': TIME_ZONE,
}); };
var PendingRequestsMap = new Map();
var getCanceledData = function (pendingKey) {
    if (!cancelTokenSource) {
        cancelTokenSource = axios.CancelToken.source();
    }
    var cancelToken = cancelTokenSource.token;
    var canclelMessage = pendingKey && "".concat(CANCEL, " - ").concat(pendingKey);
    var key = pendingKey && Symbol.for("PendingKey - ".concat(pendingKey));
    if (key) {
        var source = axios.CancelToken.source();
        cancelToken = source.token;
        if (PendingRequestsMap.has(key)) {
            var s = PendingRequestsMap.get(key);
            s === null || s === void 0 ? void 0 : s.cancel(canclelMessage);
        }
        PendingRequestsMap.set(key, source);
    }
    return {
        key: key,
        cancelToken: cancelToken,
        canclelMessage: canclelMessage,
    };
};
var request = function (url, method, data, requestSettings) {
    if (data === void 0) { data = {}; }
    if (requestSettings === void 0) { requestSettings = {}; }
    var _a = requestSettings.responseType, responseType = _a === void 0 ? 'json' : _a, _b = requestSettings.contentType, contentType = _b === void 0 ? 'application/json' : _b, errorMessage = requestSettings.errorMessage, ignoreFlashMessage = requestSettings.ignoreFlashMessage, pendingKey = requestSettings.pendingKey, abortRequestKey = requestSettings.abortRequestKey;
    var signal = undefined;
    if (abortRequestKey)
        signal = abortRequestsService.getRequestSignalByKey(abortRequestKey);
    var _c = getCanceledData(pendingKey), key = _c.key, cancelToken = _c.cancelToken, canclelMessage = _c.canclelMessage;
    var requestConfig = {
        method: method,
        url: url,
        responseType: responseType,
        data: contentType === 'multipart/form-data' && typeof data === 'object' ? getFormData(data) : data,
        transformResponse: function (r) { return (r && r.data ? r.data : r); },
        headers: __assign(__assign({}, getAuthHeaders()), { 'Content-Type': contentType }),
        signal: signal,
        errorMessage: errorMessage,
        ignoreFlashMessage: ignoreFlashMessage,
        cancelToken: cancelToken,
    };
    return new Promise(function (resolve, reject) {
        return axiosInstance
            .request(requestConfig)
            .then(function (_a) {
            var data = _a.data;
            var preparedData = data;
            if (typeof data === 'string') {
                try {
                    preparedData = JSON.parse(data);
                }
                catch (e) { }
            }
            if (key && PendingRequestsMap.has(key))
                PendingRequestsMap.delete(key);
            return resolve(preparedData);
        })
            .catch(function (error) {
            if (key && PendingRequestsMap.has(key)) {
                if (canclelMessage !== error.message) {
                    PendingRequestsMap.delete(key);
                }
                else {
                    error.canceled = true;
                }
            }
            reject(error);
        });
    });
};
export var get = function (url, data, requestSettings) {
    if (data === void 0) { data = {}; }
    if (requestSettings === void 0) { requestSettings = {}; }
    return request(url, HTTP_METHODS.GET, data, requestSettings);
};
export var post = function (url, data, requestSettings) {
    if (data === void 0) { data = {}; }
    if (requestSettings === void 0) { requestSettings = {}; }
    return request(url, HTTP_METHODS.POST, data, requestSettings);
};
export var put = function (url, data, requestSettings) {
    if (data === void 0) { data = {}; }
    if (requestSettings === void 0) { requestSettings = {}; }
    return request(url, HTTP_METHODS.PUT, data, requestSettings);
};
export var del = function (url, data, requestSettings) {
    if (data === void 0) { data = {}; }
    if (requestSettings === void 0) { requestSettings = {}; }
    return request(url, HTTP_METHODS.DELETE, data, requestSettings);
};
var isRefreshTokenExpired = false;
axiosInstance.interceptors.request.use(function (config) {
    if (userService.isLoggedIn()) {
        var successCb = function () {
            config.headers.Authorization = "Bearer ".concat(userService.getToken());
            return Promise.resolve(config);
        };
        var errorCb = function () {
            if (!isRefreshTokenExpired) {
                isRefreshTokenExpired = true;
                EE.emit(EventsBus.logout);
            }
            if (cancelTokenSource) {
                cancelTokenSource.cancel('RefreshToken expired');
            }
            return userService.doLogin();
        };
        return userService.updateToken(successCb, errorCb);
    }
    return config;
});
axiosInstance.interceptors.response.use(function (response) { return response; }, function (error) {
    var _a, _b, _c, _d;
    var data = ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) ? JSON.parse(error.response.data) : null;
    if (!isRefreshTokenExpired &&
        error.message !== ABORT_REQUEST_MESSAGE &&
        !((_b = error.config) === null || _b === void 0 ? void 0 : _b.ignoreFlashMessage) &&
        !((_c = error.message) === null || _c === void 0 ? void 0 : _c.includes(CANCEL)) &&
        (!error.response || error.response.status !== 401)) {
        var message = ((_d = error.config) === null || _d === void 0 ? void 0 : _d.errorMessage) || (data === null || data === void 0 ? void 0 : data.exception) || t('ruquest.somethingWrong');
        EE.emit(EventsBus.showMessage, {
            message: message,
        });
    }
    return Promise.reject(data || error);
});

import { createSlice } from '@reduxjs/toolkit';
import { USERS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialModalFilterValeus = {
    isActive: undefined,
    role: undefined,
    creationDatesInterval: undefined,
};
export var initialState = {
    searchString: '',
    isShowForm: false,
    modalFilterValues: initialModalFilterValeus,
    isShowModalFilter: false,
};
var usersFilterServiceState = createSlice({
    name: USERS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        setModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterFieldValue: function (state, action) {
            state.modalFilterValues[action.payload] = undefined;
        },
        resetModalFilterValues: function (state) {
            state.modalFilterValues = initialModalFilterValeus;
        },
        showModalFilter: function (state) {
            state.isShowModalFilter = true;
        },
        hideModalFilter: function (state) {
            state.isShowModalFilter = false;
        },
        showForm: function (state) {
            state.isShowForm = true;
        },
        hideForm: function (state) {
            state.isShowForm = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = usersFilterServiceState.actions;
export default usersFilterServiceState.reducer;

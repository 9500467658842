import { createSlice } from '@reduxjs/toolkit';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    currentUserCanProcess: undefined,
    statuses: undefined,
};
var applicationsFilterServiceState = createSlice({
    name: APPLICATIONS_SERVICES_REDUCER_NAMESPACES.supervisorApplicationsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        setFilterData: function (state, action) {
            var _a = action.payload, currentUserCanProcess = _a.currentUserCanProcess, statuses = _a.statuses;
            state.currentUserCanProcess = currentUserCanProcess;
            state.statuses = statuses;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = applicationsFilterServiceState.actions;
export default applicationsFilterServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { FileStatus } from '../../../types';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import { CloseCircleIcon, PictureIcon, WarningIcon } from '../../icons';
import styles from './files-list.module.scss';
var FileLink = function (_a) {
    var file = _a.file, children = _a.children;
    var ref = useRef(null);
    useEffect(function () {
        var href = '';
        if (ref.current) {
            href = window.URL.createObjectURL(file);
            ref.current.href = href;
            ref.current.target = '_blank';
        }
        return function () {
            if (href) {
                URL.revokeObjectURL(href);
            }
        };
    }, [file]);
    return (_jsx("a", __assign({ ref: ref, style: { color: 'inherit' } }, { children: children })));
};
var FilesListItem = function (_a) {
    var file = _a.file, onRemove = _a.onRemove;
    var isError = file.status === FileStatus.rejected;
    return (_jsxs("div", __assign({ className: styles.listItem }, { children: [_jsx(Icon, { className: styles.icon, color: isError ? 'error' : 'primary', component: isError ? WarningIcon : PictureIcon }), _jsx(Typography, __assign({ className: styles.text, variant: "body-s", color: isError ? 'error' : 'primary' }, { children: isError ? (_jsxs(_Fragment, { children: [file.name, "\u00A0\u00A0\u00A0", file.message] })) : (_jsxs(_Fragment, { children: [_jsx(FileLink, __assign({ file: file }, { children: file.name })), _jsx(Icon, { className: styles.removeIcon, width: 1, viewBox: "0 0 16 16", component: CloseCircleIcon, onClick: onRemove(file.name) })] })) }))] })));
};
export var FilesList = function (_a) {
    var files = _a.files, removeItem = _a.removeItem;
    return files.length ? (_jsx("div", __assign({ className: styles.filesListWrapper }, { children: files.map(function (file) { return (_jsx(FilesListItem, { file: file, onRemove: removeItem }, file.name)); }) }))) : null;
};

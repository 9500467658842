var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from 'pages/services/constants';
export var initialState = __assign(__assign({}, baseStateProperties), { cancelTransactionData: null, cancellationReason: null });
var cancelTransactionModalServiceState = createSlice({
    name: SHARED_SERVICES_REDUCER_NAMESPACES.cancelTransactionModal,
    initialState: initialState,
    reducers: __assign({ setCancelTransactionData: function (state, action) {
            state.cancelTransactionData = action.payload;
        }, setCancellationReason: function (state, action) {
            state.cancellationReason = action.payload;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = cancelTransactionModalServiceState.actions;
export default cancelTransactionModalServiceState.reducer;

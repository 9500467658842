import { createSlice } from '@reduxjs/toolkit';
import { REQUEST_PROCESSING_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    positionIds: [],
};
var executorsFilterServiceState = createSlice({
    name: REQUEST_PROCESSING_REDUCER_NAMESPACES.executorsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        selectPositions: function (state, action) {
            state.positionIds = action.payload;
        },
        removeSelectedPosition: function (state, action) {
            state.positionIds = state.positionIds.filter(function (id) { return id !== action.payload; });
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = executorsFilterServiceState.actions;
export default executorsFilterServiceState.reducer;

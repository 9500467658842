import { useEffect, useState, useRef, useLayoutEffect } from 'react';
export var useOnScreen = function (_a) {
    var ref = _a.ref, disableAfterShow = _a.disableAfterShow, root = _a.root, rootMargin = _a.rootMargin, threshold = _a.threshold;
    var observerRef = useRef(null);
    var _b = useState(false), isOnScreen = _b[0], setIsOnScreen = _b[1];
    useEffect(function () {
        observerRef.current = new IntersectionObserver(function (_a) {
            var entry = _a[0];
            return setIsOnScreen(entry.isIntersecting);
        }, {
            root: root,
            rootMargin: rootMargin,
            threshold: threshold,
        });
        return function () {
            var _a;
            (_a = observerRef.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        };
    }, [root, rootMargin, threshold]);
    useEffect(function () {
        var _a;
        ref.current && ((_a = observerRef.current) === null || _a === void 0 ? void 0 : _a.observe(ref.current));
    }, [ref]);
    useLayoutEffect(function () {
        if (observerRef.current && disableAfterShow && isOnScreen) {
            observerRef.current.disconnect();
        }
    }, [isOnScreen, disableAfterShow]);
    return isOnScreen;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { getInitialAnswersData } from './helpers';
import { TAKE_SURVEY_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: null, answersMap: null, isAnonymous: false, userInfo: { firstName: '', lastName: '', middleName: '' }, isSubmitting: false, isOpenModal: false });
var takeSurveyFormServiceState = createSlice({
    name: TAKE_SURVEY_SERVICES_REDUCER_NAMESPACES.takeSurveyForm,
    initialState: initialState,
    reducers: __assign({ setInitialData: function (state, action) {
            state.data = action.payload.data;
            state.answersMap = action.payload.initialAnswersData;
            state.isAnonymous = action.payload.isAnonymous;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, resetInitialAnswers: function (state) {
            if (state.initialized && state.data) {
                state.answersMap = getInitialAnswersData(state.data.questions);
            }
        }, editAnswerText: function (state, action) {
            var _a;
            var _b = action.payload, questionId = _b.questionId, text = _b.text;
            if ((_a = state.answersMap) === null || _a === void 0 ? void 0 : _a[questionId]) {
                state.answersMap[questionId].data.text = text;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editAnswerComment: function (state, action) {
            var _a;
            var _b = action.payload, questionId = _b.questionId, comment = _b.comment;
            if ((_a = state.answersMap) === null || _a === void 0 ? void 0 : _a[questionId]) {
                state.answersMap[questionId].data.comment = comment;
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, editIsAnonymous: function (state, action) {
            state.isAnonymous = action.payload;
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, addSelectedAnswerOption: function (state, action) {
            var _a;
            var _b = action.payload, questionId = _b.questionId, selectedAnswerOptionId = _b.selectedAnswerOptionId, isMultiple = _b.isMultiple, isSelectedOtherOption = _b.isSelectedOtherOption;
            if ((_a = state.answersMap) === null || _a === void 0 ? void 0 : _a[questionId]) {
                if (isMultiple) {
                    state.answersMap[questionId].data.selectedAnswerOptions = __spreadArray(__spreadArray([], state.answersMap[questionId].data.selectedAnswerOptions, true), [
                        selectedAnswerOptionId,
                    ], false);
                }
                else {
                    state.answersMap[questionId].data.selectedAnswerOptions = [selectedAnswerOptionId];
                }
                if (isSelectedOtherOption !== undefined) {
                    state.answersMap[questionId].isSelectedOtherOption = isSelectedOtherOption;
                }
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, deleteSelectedAnswerOptions: function (state, action) {
            var _a;
            var _b = action.payload, questionId = _b.questionId, selectedAnswerOptionId = _b.selectedAnswerOptionId, isMultiple = _b.isMultiple, isSelectedOtherOption = _b.isSelectedOtherOption;
            if ((_a = state.answersMap) === null || _a === void 0 ? void 0 : _a[questionId]) {
                if (isMultiple) {
                    state.answersMap[questionId].data.selectedAnswerOptions = state.answersMap[questionId].data.selectedAnswerOptions.filter(function (id) { return id !== selectedAnswerOptionId; });
                }
                else {
                    state.answersMap[questionId].data.selectedAnswerOptions = [];
                }
                if (isSelectedOtherOption !== undefined) {
                    state.answersMap[questionId].isSelectedOtherOption = isSelectedOtherOption;
                }
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, setUserFieldValue: function (state, action) {
            var _a = action.payload, field = _a.field, value = _a.value;
            if (state.userInfo) {
                state.userInfo[field] = value.trim();
            }
            if (state.isSubmitting)
                state.isSubmitting = false;
        }, setUserInfodValue: function (state, action) {
            state.userInfo = {
                firstName: action.payload.firstName.trim(),
                lastName: action.payload.lastName.trim(),
                middleName: action.payload.middleName.trim(),
            };
        }, setIsSubmitting: function (state) {
            state.isSubmitting = true;
        }, resetIsSubmitting: function (state) {
            state.isSubmitting = false;
        }, openFinishModal: function (state) {
            state.isOpenModal = true;
        }, closeFinishModal: function (state) {
            state.isOpenModal = false;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = takeSurveyFormServiceState.actions;
export default takeSurveyFormServiceState.reducer;

import { get, put } from 'common/utils/request';
import { prepareParamsToQueryString } from 'common/helpers/prepare-params-to-query-string';
export var fetchNotificationsApi = function (_a) {
    var page = _a.page, pageSize = _a.pageSize, deviceType = _a.deviceType, onlyNotRead = _a.onlyNotRead;
    return get("/push-notifications-history".concat(prepareParamsToQueryString({
        deviceType: deviceType,
        onlyNotRead: onlyNotRead,
        'pageData.page': page,
        'pageData.pageSize': pageSize,
    }, true)), {}, { abortRequestKey: 'fetchNotifications' });
};
export var readNotificationApi = function (_a) {
    var pushId = _a.pushId, deviceType = _a.deviceType, isRead = _a.isRead;
    return put("/push-notifications-history".concat(prepareParamsToQueryString({
        deviceType: deviceType,
        pushId: pushId,
        isRead: isRead,
    }, true)));
};

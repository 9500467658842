var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { LocalizationAnswerType, LocalizationRequestKeysType, LocalizationType } from 'common/types/server-api-dtos';
import { LOCALIZATION_URL, STAND } from 'common/constants/common';
import { ONE_MINUTE } from 'common/constants/common-time';
var PREV_LOCAL_STORAGE_PREFIX = 'i18next_res_';
var CURRENT_LOCAL_STORAGE_PREFIX = 'i18next_res_v1';
var isDevelopmentMode = STAND === 'dev';
var loadPath = function (languages) {
    var basePath = "".concat(languages[0], "-translation");
    var prevLocalStorageKey = "".concat(PREV_LOCAL_STORAGE_PREFIX).concat(basePath);
    if (localStorage.getItem(prevLocalStorageKey))
        localStorage.removeItem(prevLocalStorageKey);
    var backendPath = "".concat(LOCALIZATION_URL, "/translations?type=").concat(LocalizationType.Frontend, "&culture={{lng}}&requestKeysType=").concat(LocalizationRequestKeysType.AllKeysWithoutEmpty, "&answerType=").concat(LocalizationAnswerType.Message);
    var data = localStorage.getItem("".concat(CURRENT_LOCAL_STORAGE_PREFIX).concat(basePath));
    if (!data) {
        return backendPath;
    }
    var timestamp = JSON.parse(data).i18nStamp;
    if (timestamp - Date.now() <= 0) {
        return backendPath;
    }
    return false;
};
var mainBackends = [
    HttpBackend,
    resourcesToBackend(function (language, namespace) { return import("./locales/".concat(language, "/").concat(namespace, ".json")); }),
];
var mainBackendOptions = [
    {
        loadPath: loadPath,
    },
];
var backends = isDevelopmentMode ? mainBackends : __spreadArray([LocalStorageBackend], mainBackends, true);
var backendOptions = isDevelopmentMode
    ? mainBackendOptions
    : __spreadArray([
        {
            expirationTime: ONE_MINUTE * 10,
            prefix: CURRENT_LOCAL_STORAGE_PREFIX,
        }
    ], mainBackendOptions, true);
i18next.use(LanguageDetector).use(initReactI18next).use(ChainedBackend).init({
    fallbackLng: 'en',
    debug: isDevelopmentMode,
    backend: {
        backends: backends,
        backendOptions: backendOptions,
    },
});
i18next.changeLanguage(navigator.language);
export default i18next;

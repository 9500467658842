import { createSlice } from '@reduxjs/toolkit';
import { COPY_LINK_MODAL_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    isOpenModal: false,
    id: '',
    surveyName: '',
    surveyType: null,
};
var copyLinkModalModalServiceState = createSlice({
    name: COPY_LINK_MODAL_REDUCER_NAMESPACES.copyLinkModal,
    initialState: initialState,
    reducers: {
        openCopyLinkModal: function (state, action) {
            state.isOpenModal = true;
            state.id = action.payload.id;
            state.surveyName = action.payload.publicName;
            state.surveyType = action.payload.surveyType;
        },
        closeCopyLinkModal: function () { return initialState; },
    },
});
export var actions = copyLinkModalModalServiceState.actions;
export default copyLinkModalModalServiceState.reducer;

var _a;
import { combineReducers } from 'redux';
import surveyResultsServiceReducer from './survey-results-service/reducer';
import questionAnswerCommentsServiceReducer from './question-answer-comments-service/reducer';
import questionAnswerCommentsFilterServiceReducer from './question-answer-comments-filter-service/reducer';
import { SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var surveyResultsRootServicesReducer = combineReducers((_a = {},
    _a[SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.surveyResults] = surveyResultsServiceReducer,
    _a[SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.questionAnswerComments] = questionAnswerCommentsServiceReducer,
    _a[SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.questionAnswerCommentsFilter] = questionAnswerCommentsFilterServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useMemo } from 'react';
import { TabsObservableContext } from './tabs-observable-context';
function notifySubscribers(value, fnMap) {
    if (fnMap === void 0) { fnMap = []; }
    for (var _i = 0, fnMap_1 = fnMap; _i < fnMap_1.length; _i++) {
        var fn = fnMap_1[_i];
        fn(value);
    }
}
export var TabsObservableProvider = function (_a) {
    var disabled = _a.disabled, initialActiveTab = _a.initialActiveTab, initialDisabledTabs = _a.initialDisabledTabs, children = _a.children, additionalTabClickHandler = _a.additionalTabClickHandler;
    var stateRef = useRef({
        currentActiveTab: initialActiveTab !== null && initialActiveTab !== void 0 ? initialActiveTab : 0,
        disabledTabs: initialDisabledTabs
            ? Array.isArray(initialDisabledTabs)
                ? initialDisabledTabs
                : [initialDisabledTabs]
            : [],
        subscribersMap: {},
        activeTabsSubscribersMap: [],
        disabledTabsubSubscribersMap: {},
        allDisabledTabsSubscribersMap: [],
    });
    var actions = useMemo(function () { return ({
        subscribeActiveByIndex: function (tabIndex, fn) {
            var state = stateRef.current;
            if (!state.subscribersMap[tabIndex])
                state.subscribersMap[tabIndex] = [];
            state.subscribersMap[tabIndex].push(fn);
            return function () {
                state.subscribersMap[tabIndex].filter(function (subscriber) { return subscriber !== fn; });
            };
        },
        subscribeActiveTab: function (fn) {
            var state = stateRef.current;
            state.activeTabsSubscribersMap.push(fn);
            return function () {
                state.activeTabsSubscribersMap.filter(function (subscriber) { return subscriber !== fn; });
            };
        },
        setActiveTab: function (newActiveTabIndex) {
            var state = stateRef.current;
            if (!disabled &&
                newActiveTabIndex !== state.currentActiveTab &&
                !state.disabledTabs.includes(newActiveTabIndex)) {
                additionalTabClickHandler === null || additionalTabClickHandler === void 0 ? void 0 : additionalTabClickHandler(newActiveTabIndex);
                notifySubscribers(newActiveTabIndex, state.subscribersMap[newActiveTabIndex]);
                notifySubscribers(newActiveTabIndex, state.subscribersMap[state.currentActiveTab]);
                notifySubscribers(newActiveTabIndex, state.activeTabsSubscribersMap);
                state.currentActiveTab = newActiveTabIndex;
            }
        },
        getActiveTab: function () {
            return stateRef.current.currentActiveTab;
        },
        subscribeDisabledTabByIndex: function (tabIndex, fn) {
            var state = stateRef.current;
            if (state.disabledTabsubSubscribersMap[tabIndex])
                state.disabledTabsubSubscribersMap[tabIndex] = [];
            state.disabledTabsubSubscribersMap[tabIndex].push(fn);
            return function () {
                state.disabledTabsubSubscribersMap[tabIndex].filter(function (subscriber) { return subscriber !== fn; });
            };
        },
        subscribeAllDisabledTabs: function (fn) {
            var state = stateRef.current;
            state.allDisabledTabsSubscribersMap.push(fn);
            return function () {
                state.allDisabledTabsSubscribersMap.filter(function (subscriber) { return subscriber !== fn; });
            };
        },
        toggleDisabledTab: function (tabIndex) {
            if (disabled)
                return;
            var state = stateRef.current;
            if (state.disabledTabs.includes(tabIndex)) {
                state.disabledTabs = state.disabledTabs.filter(function (item) { return item !== tabIndex; });
            }
            else {
                state.disabledTabs.push(tabIndex);
            }
            notifySubscribers(state.disabledTabs, state.disabledTabsubSubscribersMap[tabIndex]);
            notifySubscribers(state.disabledTabs, state.allDisabledTabsSubscribersMap);
        },
        setDisabledTabs: function (newDisabledTabs) {
            var state = stateRef.current;
            state.disabledTabs = newDisabledTabs;
            for (var _i = 0, newDisabledTabs_1 = newDisabledTabs; _i < newDisabledTabs_1.length; _i++) {
                var index = newDisabledTabs_1[_i];
                notifySubscribers(newDisabledTabs, state.disabledTabsubSubscribersMap[index]);
            }
            notifySubscribers(newDisabledTabs, state.allDisabledTabsSubscribersMap);
        },
        getDisabledTabs: function () {
            return stateRef.current.disabledTabs;
        },
    }); }, [additionalTabClickHandler, disabled]);
    return _jsx(TabsObservableContext.Provider, __assign({ value: actions }, { children: children }));
};

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useOpenCreateRequestFromAction } from '../create-support-reuest-form';
import { OPEN_SUPPORT_RUQUEST_FORM_HASH_KEY } from 'common/constants/common';
export var HashUrlListener = function () {
    var openCreateSupportRequestForm = useOpenCreateRequestFromAction();
    var location = useLocation();
    var navigate = useNavigate();
    useEffect(function () {
        if (location.hash === OPEN_SUPPORT_RUQUEST_FORM_HASH_KEY) {
            openCreateSupportRequestForm();
            navigate(location.pathname + location.search, { replace: true });
        }
    }, [navigate, openCreateSupportRequestForm, location]);
    return null;
};

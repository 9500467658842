import { t } from 'i18next';
import { FAILED_IMPORTED_ERROR } from 'common/constants/failed-imported-error';
export var createErrorHandler = function (error, resetErrorBoundary) {
    var normalizedError = {
        message: error.message || t('fallbackPage.title'),
        onReset: function () {
            var _a, _b;
            resetErrorBoundary();
            if ((_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.onLine) {
                (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.reload();
            }
        },
        resetMessage: t('fallbackPage.subtitle'),
    };
    if (error.message.includes(FAILED_IMPORTED_ERROR)) {
        normalizedError.message = t('fallbackPage.serverConnectLost');
    }
    return normalizedError;
};

import { createSlice } from '@reduxjs/toolkit';
import { PROFILE_SETTINGS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    isShowModal: false,
};
var usersFilterServiceState = createSlice({
    name: PROFILE_SETTINGS_SERVICES_REDUCER_NAMESPACES.resetPassword,
    initialState: initialState,
    reducers: {
        showModal: function (state) {
            state.isShowModal = true;
        },
        hideModal: function (state) {
            state.isShowModal = false;
        },
    },
});
export var actions = usersFilterServiceState.actions;
export default usersFilterServiceState.reducer;

export var SHARED_SERVICES_REDUCER_NAMESPACES = {
    shared: 'shared',
    positions: 'positions',
    tags: 'tags',
    transactionCategory: 'transaction-category',
    tariffs: 'tariffs',
    tariffsInfo: 'tariffs-info',
    productCategories: 'product-categories',
    activitiesCategories: 'activities-categories',
    activitiesMetaData: 'activities-meta-data',
    postsCategories: 'posts-categories',
    approvalChains: 'approval-chains',
    employeesForSelects: 'employees-for-selects',
    gratitudesSettings: 'gratitudes-settings',
    feedback: 'feedback',
    cancelTransactionModal: 'cancel-transaction-modal',
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import cs from 'classnames';
import { ONE_SECOND } from 'common/constants/common-time';
import styles from './modal-overlay.module.scss';
export var ModalOverlay = function (_a) {
    var _b;
    var isOpen = _a.isOpen, isClickable = _a.isClickable, children = _a.children, onClose = _a.onClose;
    var ref = useRef(null);
    var isHiddenScrollRef = useRef(false);
    useEffect(function () {
        var overlay = ref.current;
        var timer = null;
        var close = function (e) {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        var scrollCb = function () {
            window.dispatchEvent(new CustomEvent('scroll'));
        };
        if (isOpen) {
            timer = setTimeout(function () {
                window.addEventListener('keydown', close);
                overlay === null || overlay === void 0 ? void 0 : overlay.addEventListener('scroll', scrollCb);
            }, ONE_SECOND * 3);
            if (document.body.style.overflow === 'hidden') {
                isHiddenScrollRef.current = true;
            }
            else {
                document.body.style.overflow = 'hidden';
            }
        }
        return function () {
            if (isHiddenScrollRef.current) {
                isHiddenScrollRef.current = false;
            }
            else {
                document.body.style.overflow = 'unset';
            }
            if (timer) {
                clearTimeout(timer);
            }
            window.removeEventListener('keydown', close);
            overlay === null || overlay === void 0 ? void 0 : overlay.removeEventListener('scroll', scrollCb);
        };
    }, [onClose, isOpen]);
    var clickHandler = useCallback(function (e) {
        if (e.target === e.currentTarget) {
            onClose();
        }
    }, [onClose]);
    return (_jsx("div", __assign({ className: cs(styles.overlay, (_b = {}, _b[styles.active] = isOpen, _b[styles.clickable] = isClickable, _b)), ref: ref, role: "button", tabIndex: 0, onClick: isClickable ? clickHandler : undefined }, { children: children })));
};

var _a;
import { combineReducers } from 'redux';
import awardRatingServiceReducer from '../awards-rating/service/reducer';
import balanceRatingServiceReducer from '../balance-rating/service/reducer';
import gratitudeRatingServiceReducer from '../gratitudes-rating/service/reducer';
import { RATINGS_ROOT_SERVICE_REDUCER_NAMESPACES } from './constants';
export var ratingsRootServicesReducer = combineReducers((_a = {},
    _a[RATINGS_ROOT_SERVICE_REDUCER_NAMESPACES.awardRating] = awardRatingServiceReducer,
    _a[RATINGS_ROOT_SERVICE_REDUCER_NAMESPACES.balanceRating] = balanceRatingServiceReducer,
    _a[RATINGS_ROOT_SERVICE_REDUCER_NAMESPACES.gratitudeRating] = gratitudeRatingServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cs from 'classnames';
import { useFieldState } from 'ui-kit/controllers/selects/hooks/use-field-state';
import styles from './options-list-wrapper.module.scss';
export var OptionsListWrapper = function (_a) {
    var _b;
    var footer = _a.footer, children = _a.children, setSubscriber = _a.setSubscriber, onScroll = _a.onScroll;
    var isActive = useFieldState({ key: 'isActive', initialValue: false, setSubscriber: setSubscriber });
    return (_jsxs("div", __assign({ className: cs(styles.optionsListWrapper, (_b = {}, _b[styles.active] = isActive, _b)), onScroll: onScroll }, { children: [_jsx("div", __assign({ className: styles.contentWrapper }, { children: children })), footer && _jsx("div", __assign({ className: styles.footer }, { children: footer }))] })));
};

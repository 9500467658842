import { createSelector } from 'reselect';
import { appServicesRootSelector } from '../app-services-root-selector';
import { APP_SERVICES_REDUCER_NAMESPACES } from '../constants';
var rootName = APP_SERVICES_REDUCER_NAMESPACES.common;
var stateName = APP_SERVICES_REDUCER_NAMESPACES.showThanksBalance;
export var showThanksBalanceServiceRootSelector = createSelector(appServicesRootSelector, function (state) { return state && state[stateName]; });
export var showThanksBalanceServiceDataSelector = createSelector(showThanksBalanceServiceRootSelector, function (state) { return Boolean(state === null || state === void 0 ? void 0 : state.data); });
export var showThanksBalanceServiceLoadingSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].loading);
};
export var showThanksBalanceServiceInitializedSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].initialized);
};

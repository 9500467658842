var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { LoaderCover } from 'ui-kit/loader';
import { Box } from 'ui-kit/box';
export var LoaderWrapperWithSelector = function (_a) {
    var children = _a.children, className = _a.className, loadingSelector = _a.loadingSelector;
    var loading = useSelector(loadingSelector);
    return (_jsxs(Box, __assign({ className: className, display: "block", position: "relative" }, { children: [loading && _jsx(LoaderCover, {}), children] })));
};

var _a;
import { combineReducers } from 'redux';
import activitiesStatisticsServiceReducer from './activities-statistics-service/reducer';
import activitiesStatisticsChartServiceReducer from './activities-statistics-chart-service/reducer';
import activitiesStatisticsFilterServiceReducer from './activities-statistics-filter-service/reducer';
import { MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var modularAnalyticsStatisticsRootReducer = combineReducers((_a = {},
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.activitiesStatistics] = activitiesStatisticsServiceReducer,
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.activitiesStatisticsChart] = activitiesStatisticsChartServiceReducer,
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.activitiesStatisticsFilter] = activitiesStatisticsFilterServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { baseListReducer } from 'common/utils/base-reduсer/index';
import { ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
var initialData = {
    total: 0,
    pageData: {
        page: 0,
        pageSize: 0,
    },
    content: [],
};
export var initialState = __assign(__assign({ data: initialData }, baseStateListProperties), { pageSize: 5, loading: true });
var eNPSAnswersListServiceState = createSlice({
    name: ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.eNPSAnswersList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = eNPSAnswersListServiceState.actions;
export default eNPSAnswersListServiceState.reducer;

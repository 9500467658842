import { createSlice } from '@reduxjs/toolkit';
import { MANAGER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    categoryId: null,
    isOpenFiter: false,
    state: null,
    modalFilterValues: {
        createdOnInterval: null,
        publishedOnInterval: null,
    },
};
var managerPostsFilterServiceState = createSlice({
    name: MANAGER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES.postsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        selectCategoryId: function (state, action) {
            state.categoryId = action.payload;
        },
        setPostsState: function (state, action) {
            state.state = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterFieldValue: function (state, action) {
            state.modalFilterValues[action.payload] = null;
        },
        resetModalFilterValues: function (state) {
            state.modalFilterValues.createdOnInterval = null;
            state.modalFilterValues.publishedOnInterval = null;
        },
        openFilter: function (state) {
            state.isOpenFiter = true;
        },
        closeFilter: function (state) {
            state.isOpenFiter = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = managerPostsFilterServiceState.actions;
export default managerPostsFilterServiceState.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { SURVEY_FORM_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    modalFilterValues: {
        tagIds: [],
        hireDatesInterval: null,
        probationPeriodDatesInterval: null,
        fireDatesInterval: null,
    },
    openFilter: false,
    disabled: true,
};
var employeesFilterServiceState = createSlice({
    name: SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.employeesFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        },
        openFilter: function (state) {
            state.openFilter = true;
        },
        filterTagIds: function (state, action) {
            if (action.payload.length) {
                state.modalFilterValues.tagIds = state.modalFilterValues.tagIds.filter(function (id) { return action.payload.includes(id); });
            }
            else {
                state.modalFilterValues.tagIds = [];
            }
        },
        resetFieldValueByModalForm: function (state, action) {
            state.modalFilterValues[action.payload] = null;
        },
        removeSelectedTagId: function (state, action) {
            state.modalFilterValues.tagIds = state.modalFilterValues.tagIds.filter(function (id) { return id !== action.payload; });
        },
        closeFilter: function (state) {
            state.openFilter = false;
        },
        setDisabled: function (state, action) {
            state.disabled = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = employeesFilterServiceState.actions;
export default employeesFilterServiceState.reducer;

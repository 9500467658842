var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useImperativeHandle } from 'react';
import { FormSpy, useForm } from 'react-final-form';
export function FormButtonSetWrapper(_a) {
    var observeDirty = _a.observeDirty, observeValues = _a.observeValues, refHandlers = _a.refHandlers, children = _a.children;
    var _b = useForm(), submit = _b.submit, restart = _b.restart;
    useImperativeHandle(refHandlers, function () { return ({ reset: restart }); }, [restart]);
    return (_jsx(FormSpy, __assign({ subscription: { submitFailed: true, valid: true, submitting: true, dirty: observeDirty, values: observeValues } }, { children: function (_a) {
            var submitFailed = _a.submitFailed, validating = _a.validating, valid = _a.valid, submitting = _a.submitting, dirty = _a.dirty, values = _a.values;
            return children({
                submitLoading: submitting || validating,
                submitDisabled: submitFailed && !valid,
                dirty: dirty,
                valid: valid,
                values: values,
                submitForm: submit,
            });
        } })));
}

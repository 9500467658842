var _a;
import { FeedbackSectionType, FeedbackTriggerType } from '../types/server-api-dtos';
export var FeedbackTriggerMap = (_a = {},
    _a[FeedbackTriggerType.ActivityCreated] = FeedbackSectionType.Activities,
    _a[FeedbackTriggerType.ActivityUpdated] = FeedbackSectionType.Activities,
    _a[FeedbackTriggerType.CurrencyAccrues] = FeedbackSectionType.Activities,
    _a[FeedbackTriggerType.TealRequestCreated] = FeedbackSectionType.Activities,
    _a[FeedbackTriggerType.OneToOneCommentCreated] = FeedbackSectionType.OneToOnes,
    _a[FeedbackTriggerType.OneToOneCreated] = FeedbackSectionType.OneToOnes,
    _a[FeedbackTriggerType.OneToOneUpdated] = FeedbackSectionType.OneToOnes,
    _a[FeedbackTriggerType.ProductOrdered] = FeedbackSectionType.ProductOrders,
    _a[FeedbackTriggerType.SupportRequestCreated] = FeedbackSectionType.SupportRequests,
    _a[FeedbackTriggerType.SupportRequestResolved] = FeedbackSectionType.SupportRequests,
    _a[FeedbackTriggerType.SurveyCompleted] = FeedbackSectionType.Surveys,
    _a[FeedbackTriggerType.SurveyCreated] = FeedbackSectionType.Surveys,
    _a[FeedbackTriggerType.SurveyUpdated] = FeedbackSectionType.Surveys,
    _a);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { sidebarServiceIsOpenMenuSelector } from 'common/layouts/main-layout/service/sidebar-service/selectors';
export var SidebarWrapper = function (_a) {
    var className = _a.className, children = _a.children, onClose = _a.onClose;
    var isOpenMenu = useSelector(sidebarServiceIsOpenMenuSelector);
    return (_jsx("aside", __assign({ className: className, onMouseLeave: isOpenMenu ? onClose : undefined }, { children: children })));
};

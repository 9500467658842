import { fetchProfileApi, fetchProfileBalanceAndNotificationsMetaApi } from './api';
import { notificationDrawerServiceLastNotificationDateSelector } from 'common/layouts/main-layout/service/notification-drawer-service/selectors';
import { actions } from './reducer';
export var fetchProfileAction = function () { return function (dispatch) {
    dispatch(actions.setLoading(true));
    return fetchProfileApi()
        .then(function (data) {
        dispatch(actions.setData(data));
    })
        .catch(function (error) {
        console.log('fetchProfileAction ->', error);
        dispatch(actions.setError(error));
    });
}; };
export var fetchProfileBalanceAndNotificationsMetaAction = function () { return function (dispatch, getState) {
    var checkDate = notificationDrawerServiceLastNotificationDateSelector(getState());
    return fetchProfileBalanceAndNotificationsMetaApi(checkDate)
        .then(function (data) {
        dispatch(actions.setBalanceAndNotificationsMetaData(data));
    })
        .catch(function (error) {
        console.log('fetchProfileBalanceAndNotificationsMetaAction ->', error);
        dispatch(actions.setError(error));
    });
}; };

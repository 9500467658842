var _a;
import { combineReducers } from 'redux';
import orderssFilterServiceReducer from './filter-service/reducer';
import orderssServiceReducer from './orders-service/reducer';
import courierCompaniesServiceReducer from './courier-companies/reducer';
import { ORDERS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var ordersServicesRootReducer = combineReducers((_a = {},
    _a[ORDERS_SERVICES_REDUCER_NAMESPACES.filter] = orderssFilterServiceReducer,
    _a[ORDERS_SERVICES_REDUCER_NAMESPACES.ordersList] = orderssServiceReducer,
    _a[ORDERS_SERVICES_REDUCER_NAMESPACES.courierCompanies] = courierCompaniesServiceReducer,
    _a));

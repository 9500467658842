var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatusType } from 'common/types/server-api-dtos';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, pageSize: 15, acceptApplicationData: null, rejectApplicationData: null, loading: true });
var applicationsServiceState = createSlice({
    name: APPLICATIONS_SERVICES_REDUCER_NAMESPACES.applicationsList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, changeAcceptApplicationData: function (state, action) {
            if (action.payload) {
                var data = state.data.content.find(function (item) { return item.id === action.payload; });
                if (data) {
                    state.acceptApplicationData = {
                        id: data.id,
                        activityId: data.activityId,
                        participatedEmployeeId: data.participatedEmployeeId,
                        participationDate: data.participationDate,
                        description: data.description,
                        declinationReason: null,
                        requestStatus: RequestStatusType.Confirmed,
                    };
                }
            }
            else {
                state.acceptApplicationData = null;
            }
        }, changeRejectApplicationData: function (state, action) {
            if (action.payload) {
                var data = state.data.content.find(function (item) { return item.id === action.payload; });
                if (data) {
                    state.rejectApplicationData = {
                        id: data.id,
                        activityId: data.activityId,
                        participatedEmployeeId: data.participatedEmployeeId,
                        participationDate: data.participationDate,
                        description: data.description,
                        declinationReason: null,
                        requestStatus: RequestStatusType.Declined,
                    };
                }
            }
            else {
                state.rejectApplicationData = null;
            }
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = applicationsServiceState.actions;
export default applicationsServiceState.reducer;

import { fetchCustomizationsApi } from './api';
import { customizationService } from 'common/utils/customization-service';
import { actions } from './reducer';
export var fetchCustomizationsAction = function (cb) {
    return function (dispatch) {
        dispatch(actions.setLoading(true));
        return fetchCustomizationsApi()
            .then(function (data) {
            customizationService.setData(data);
            dispatch(actions.setData(data));
            cb === null || cb === void 0 ? void 0 : cb(data);
        })
            .catch(function (error) {
            console.log('fetchCustomizationsAction ->', error);
            dispatch(actions.setInitial(true));
            dispatch(actions.setError(error));
        });
    };
};
export var setCustomizationsAction = function (data) {
    return function (dispatch) {
        customizationService.setData(data);
        dispatch(actions.setData(data));
    };
};
export var resetCustomizationsAction = function () { return function (dispatch) {
    dispatch(actions.setData(customizationService.reset()));
}; };

import { createSlice } from '@reduxjs/toolkit';
import { SIDEBAR_SERVICES_REDUCER_NAMESPACES } from './constants';
export var initialState = {
    isOpenMenu: false,
    isPinned: false,
};
var sidebarServiceState = createSlice({
    name: SIDEBAR_SERVICES_REDUCER_NAMESPACES.sidebar,
    initialState: initialState,
    reducers: {
        toggleIsPinnedMenu: function (state) {
            if (state.isPinned) {
                state.isOpenMenu = false;
                state.isPinned = false;
            }
            else {
                state.isOpenMenu = true;
                state.isPinned = true;
            }
        },
        setIsOpenMenu: function (state, action) {
            state.isOpenMenu = action.payload;
        },
        toggleOpenMenu: function (state) {
            state.isOpenMenu = !state.isOpenMenu;
        },
        reset: function () { return initialState; },
    },
});
export var actions = sidebarServiceState.actions;
export default sidebarServiceState.reducer;

var _a;
import { combineReducers } from 'redux';
import navigationStatisticsDashboardServiceReducer from '../navigation-statistics-dashboard-page/service/reducer';
import { teamStatisticsRootReducer } from '../team-statistics-page/services/root-reducer';
import { modularAnalyticsStatisticsRootReducer, } from '../modular-analytics-activities-page/services/root-reducer';
import { modularAnalyticsRewardsStatisticsRootReducer, } from '../modular-analytics-rewards-page/services/root-reducer';
import { modularAnalyticsGratitudesStatisticsRootReducer, } from '../modular-analytics-gratitudes-page/services/root-reducer';
import { corporateCurrencyStatisticsRootReducer, } from '../corporate-currency-statistics-page/service/root-reducer';
import { surveysStatisticsRootReducer, } from '../surveys-statistic-page/services/root-reducer';
import { STATISTICS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var statisticsRootReducer = combineReducers((_a = {},
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.navigationStatisticsDashboard] = navigationStatisticsDashboardServiceReducer,
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.teamStatistics] = teamStatisticsRootReducer,
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.activitiesStatistics] = modularAnalyticsStatisticsRootReducer,
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.rewardsStatistics] = modularAnalyticsRewardsStatisticsRootReducer,
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.corporateCurrencyStatistics] = corporateCurrencyStatisticsRootReducer,
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.gratitudesStatistics] = modularAnalyticsGratitudesStatisticsRootReducer,
    _a[STATISTICS_SERVICES_REDUCER_NAMESPACES.surveysStatistics] = surveysStatisticsRootReducer,
    _a));

import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNotificationDrawerActions } from 'common/layouts/main-layout/service/notification-drawer-service/hooks';
import { notificationDrawerServiceCurrentPageSelector, notificationDrawerServiceIsNotReadSelector, notificationDrawerServiceLoadingSelector, notificationDrawerServicePageSizeSelector, notificationDrawerServiceTotalPagesSelector, } from 'common/layouts/main-layout/service/notification-drawer-service/selectors';
import { Observer } from 'common/components/observer';
import { abortRequestsService } from 'common/utils/abort-requests-service';
export var NotificationListController = function (_a) {
    var notificationContainerRef = _a.notificationContainerRef;
    var fetchNotificationsAction = useNotificationDrawerActions().fetchNotificationsAction;
    var pageSize = useSelector(notificationDrawerServicePageSizeSelector);
    var onlyNotRead = useSelector(notificationDrawerServiceIsNotReadSelector);
    var totalPages = useSelector(notificationDrawerServiceTotalPagesSelector);
    var loading = useSelector(notificationDrawerServiceLoadingSelector);
    var currentPage = useSelector(notificationDrawerServiceCurrentPageSelector);
    useEffect(function () { return function () {
        abortRequestsService.abortRequestByKey('fetchNotifications');
    }; }, []);
    useEffect(function () {
        fetchNotificationsAction({
            page: 0,
            pageSize: pageSize,
            onlyNotRead: onlyNotRead,
            successCb: function () {
                var _a;
                (_a = notificationContainerRef === null || notificationContainerRef === void 0 ? void 0 : notificationContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0 });
            },
        });
    }, [fetchNotificationsAction, pageSize, onlyNotRead]);
    var fetchNotificationList = useCallback(function () {
        fetchNotificationsAction({ page: currentPage + 1, pageSize: pageSize });
    }, [fetchNotificationsAction, currentPage, pageSize]);
    if (loading)
        return null;
    return (_jsx(Observer, { isObserved: currentPage + 1 < totalPages, cb: fetchNotificationList, style: { height: '2rem', width: '100%' } }));
};

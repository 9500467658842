import Keycloak from 'keycloak-js';
import decodeJWT from 'jwt-decode';
import { LOGIN_URL, REALM } from 'common/constants/common';
var INIT_OPTIONS = {
    url: LOGIN_URL,
    realm: REALM || 'teal-hr',
    clientId: 'frontend',
};
var _kc = new Keycloak(INIT_OPTIONS);
var initAuthorization = function (onAuthenticatedCallback) {
    _kc
        .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: "".concat(window.location.origin, "/silent-sso.html"),
        responseMode: 'query',
        checkLoginIframe: false,
    })
        .then(function () {
        onAuthenticatedCallback();
    })
        .catch(console.error);
};
var doLogin = _kc.login;
var doLogout = _kc.logout;
var getToken = function () { return _kc.token || ''; };
var isLoggedIn = function () { return !!_kc.token; };
var getCorrectLoginUrl = function () { return _kc.createLoginUrl(); };
var updateToken = function (successCallback, errorCallback) {
    return _kc.updateToken(5).then(successCallback).catch(errorCallback);
};
var getRoles = function () {
    try {
        var token = getToken();
        if (!token)
            return [];
        var role = decodeJWT(token).role;
        return role ? (Array.isArray(role) ? role : [role]) : [];
    }
    catch (e) {
        return [];
    }
};
var hasRole = function (roles) {
    var userRoles = getRoles();
    return (Array.isArray(roles) ? roles : [roles]).some(function (role) { return userRoles.includes(role); });
};
export var userService = {
    initAuthorization: initAuthorization,
    doLogin: doLogin,
    doLogout: doLogout,
    isLoggedIn: isLoggedIn,
    getToken: getToken,
    updateToken: updateToken,
    getRoles: getRoles,
    hasRole: hasRole,
    getCorrectLoginUrl: getCorrectLoginUrl,
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { baseListReducer } from 'common/utils/base-reduсer';
import { MANAGER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { pageSize: 12, data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    } });
var managerPostsListsServiceState = createSlice({
    name: MANAGER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES.postsLists,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData, content = _a.content;
            if (!state.initialized || state.data.total !== total) {
                state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            }
            state.data.total = total;
            state.data.pageData = pageData;
            state.data.content = pageData.page > 0 ? __spreadArray(__spreadArray([], state.data.content, true), content, true) : __spreadArray([], content, true);
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, editPostLikesCount: function (state, action) {
            var _a = action.payload, id = _a.id, reactionsCount = _a.reactionsCount, postIsLiked = _a.postIsLiked;
            state.data.content = state.data.content.map(function (item) {
                if (item.id !== id)
                    return item;
                return __assign(__assign({}, item), { reactionsCount: reactionsCount, currentUserReacted: postIsLiked });
            });
        }, editPostViewsCount: function (state, action) {
            var _a = action.payload, id = _a.id, viewsCount = _a.viewsCount;
            state.data.content = state.data.content.map(function (item) {
                if (item.id !== id)
                    return item;
                return __assign(__assign({}, item), { viewsCount: viewsCount });
            });
        }, editPostCommentsCount: function (state, action) {
            var _a = action.payload, postId = _a.postId, commentsCount = _a.commentsCount;
            state.data.content = state.data.content.map(function (item) {
                if (item.id !== postId)
                    return item;
                return __assign(__assign({}, item), { commentsCount: commentsCount });
            });
        }, editPost: function (state, action) {
            state.data.content = state.data.content.map(function (item) {
                if (item.id !== action.payload.id)
                    return item;
                return action.payload;
            });
            state.loading = false;
        }, removePost: function (state, action) {
            state.data.content = state.data.content.filter(function (item) { return item.id !== action.payload; });
            state.loading = false;
        }, resetPostsData: function (state) {
            state.data = initialState.data;
            state.currentPage = initialState.currentPage;
            state.pageSize = initialState.pageSize;
            state.totalPages = initialState.totalPages;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = managerPostsListsServiceState.actions;
export default managerPostsListsServiceState.reducer;

import { editConversionApi, fetchConversionApi } from './api';
import { actions } from './reducer';
export var fetchConversionAction = function () { return function (dispatch) {
    dispatch(actions.setLoading(true));
    return fetchConversionApi()
        .then(function (data) {
        dispatch(actions.setData(data));
    })
        .catch(function (error) {
        console.log('fetchConversionAction ->', error);
        dispatch(actions.setError(error));
    });
}; };
export var editConversionAction = function (values, successCb) {
    return function (dispatch) {
        dispatch(actions.setLoading(true));
        return editConversionApi(values)
            .then(function (_a) {
            var conversion = _a.tealExchangeRate;
            dispatch(actions.setData({ conversion: conversion }));
            successCb === null || successCb === void 0 ? void 0 : successCb();
        })
            .catch(function (error) {
            console.log('editConversionAction ->', error);
            dispatch(actions.setLoading(false));
        });
    };
};

var _a;
import { combineReducers } from 'redux';
import { addressBookEmployeesRootReducer, } from '../address-book-page/services/root-reducer';
import { EMPLOYEES_BOOK_SERVICES_REDUCER_NAMESPACES } from '../address-book-page/services/constants';
import addressBookEmployeeInfoServiceReducer from '../employee-info-page/service/reducer';
import { ADDRESS_BOOK_EMPLOYEE_INFO_SERVICES_REDUCER_NAMESPACES } from '../employee-info-page/service/constants';
export var addressBookRootServicesReducer = combineReducers((_a = {},
    _a[EMPLOYEES_BOOK_SERVICES_REDUCER_NAMESPACES.addressBookEmployees] = addressBookEmployeesRootReducer,
    _a[ADDRESS_BOOK_EMPLOYEE_INFO_SERVICES_REDUCER_NAMESPACES.addressBookEmployeeInfo] = addressBookEmployeeInfoServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { EDIT_EMPLOYEE_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { supervisors: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, peopleManagers: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, peopleManagerDelegates: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, supervisorsTotalPages: 0, peopleManagersTotalPages: 0, peopleManagerDelegatesTotalPages: 0 });
var employeesForSelectsServiceState = createSlice({
    name: EDIT_EMPLOYEE_SERVICES_REDUCER_NAMESPACES.employeesForSelects,
    initialState: initialState,
    reducers: __assign({ setSupervisors: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData, content = _a.content;
            state.supervisorsTotalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.supervisors.total = total;
            state.supervisors.pageData = pageData;
            state.supervisors.content = pageData.page > 0 ? __spreadArray(__spreadArray([], state.supervisors.content, true), content, true) : __spreadArray([], content, true);
            if (!state.initialized)
                state.initialized = true;
        }, setPeopleManagers: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData, content = _a.content;
            state.peopleManagersTotalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.peopleManagers.total = total;
            state.peopleManagers.pageData = pageData;
            state.peopleManagers.content = pageData.page > 0 ? __spreadArray(__spreadArray([], state.peopleManagers.content, true), content, true) : __spreadArray([], content, true);
            if (!state.initialized)
                state.initialized = true;
        }, setPeopleManagerDelegates: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData, content = _a.content;
            state.peopleManagerDelegatesTotalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.peopleManagerDelegates.total = total;
            state.peopleManagerDelegates.pageData = pageData;
            state.peopleManagerDelegates.content =
                pageData.page > 0 ? __spreadArray(__spreadArray([], state.peopleManagerDelegates.content, true), content, true) : __spreadArray([], content, true);
            if (!state.initialized)
                state.initialized = true;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = employeesForSelectsServiceState.actions;
export default employeesForSelectsServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { Box } from 'ui-kit/box';
import { Icon } from 'ui-kit/icon';
import { HasNotificationIcon } from 'common/icons';
import styles from './with-notification-wrapper.module.scss';
export var WithNotificationWrapper = function (_a) {
    var children = _a.children, hasNotificationSelector = _a.hasNotificationSelector, className = _a.className, wrapperClassName = _a.wrapperClassName, rest = __rest(_a, ["children", "hasNotificationSelector", "className", "wrapperClassName"]);
    var hasNotification = useSelector(hasNotificationSelector ? hasNotificationSelector : function () { return false; });
    return (_jsxs(Box, __assign({ className: wrapperClassName, position: "relative" }, rest, { children: [children, hasNotification && _jsx(Icon, { className: cs(styles.notificationIcon, className), component: HasNotificationIcon })] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import cs from 'classnames';
import { ControllerWrapper } from '../controller-wrapper';
import { Counter } from './modules';
import { checkTouchDevice } from 'common/helpers/check-touch-device';
import styles from './text-area.module.scss';
var DEFAULT_ROWS_COUNT = 3;
var editElementHeight = function (element, minRows) {
    element.style.height = 'auto';
    var lineHeightPx = parseInt(getComputedStyle(element).lineHeight);
    var linesCount = element.scrollHeight / lineHeightPx;
    element.style.height = (linesCount > minRows ? linesCount : minRows) * (lineHeightPx / 16) + 'rem';
};
export var TextArea = forwardRef(function (_a, ref) {
    var _b;
    var rows = _a.rows, value = _a.value, label = _a.label, isError = _a.isError, disabled = _a.disabled, className = _a.className, maxLength = _a.maxLength, additional = _a.additional, helperText = _a.helperText, isRequired = _a.isRequired, wrapperClassName = _a.wrapperClassName, onBlur = _a.onBlur, rest = __rest(_a, ["rows", "value", "label", "isError", "disabled", "className", "maxLength", "additional", "helperText", "isRequired", "wrapperClassName", "onBlur"]);
    var textAreaRef = useRef(null);
    var isHoveredRef = useRef(false);
    useImperativeHandle(ref, function () { return ({
        textAreaRef: textAreaRef,
        focus: function () { var _a; return (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
    }); });
    useEffect(function () {
        if (textAreaRef.current)
            editElementHeight(textAreaRef.current, rows !== null && rows !== void 0 ? rows : DEFAULT_ROWS_COUNT);
    }, [value, rows]);
    var actions = useMemo(function () { return ({
        clickHandler: function () {
            var _a;
            if (!disabled && document.activeElement !== textAreaRef.current) {
                (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
        },
        hoverOnHandler: function () {
            if (!disabled)
                isHoveredRef.current = true;
        },
        hoverOffHandler: function () {
            if (!disabled)
                isHoveredRef.current = false;
        },
        blurHandler: function () {
            var _a;
            if (checkTouchDevice() || !isHoveredRef.current) {
                onBlur === null || onBlur === void 0 ? void 0 : onBlur();
                return;
            }
            navigator.userAgent.includes('Firefox')
                ? setTimeout(function () {
                    var _a;
                    (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                }, 0)
                : (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        },
    }); }, [onBlur, disabled]);
    return (_jsx(ControllerWrapper, __assign({ wrapperClassName: wrapperClassName, name: rest.name, label: label, helperText: helperText, isRequired: isRequired, isError: isError }, { children: _jsxs("div", __assign({ className: cs(styles.textAreaWrapper, (_b = {}, _b[styles.error] = isError, _b[styles.disabled] = disabled, _b), className), onClick: actions.clickHandler, onMouseEnter: actions.hoverOnHandler, onMouseLeave: actions.hoverOffHandler }, { children: [_jsx("textarea", __assign({ className: styles.textArea, ref: textAreaRef, rows: rows !== null && rows !== void 0 ? rows : DEFAULT_ROWS_COUNT, value: value, disabled: disabled, "data-testid": rest.name, onBlur: actions.blurHandler }, rest)), additional || maxLength ? (_jsxs("div", __assign({ className: styles.additionalWrapper }, { children: [maxLength ? (_jsx(Counter, { isError: isError, value: value ? value.toString().length : 0, maxLength: maxLength })) : null, additional] }))) : null] })) })));
});

import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { customizationsServiceInitializedSelector } from 'common/services/customizations-service/selectors';
import { userService } from 'common/utils/user-service';
export var RequiredAuth = function () {
    var isCustomizationsInialized = useSelector(customizationsServiceInitializedSelector);
    if (userService.isLoggedIn()) {
        return isCustomizationsInialized ? _jsx(Outlet, {}) : null;
    }
    userService.doLogin();
    return null;
};

import { fetchNeedFeedbackApi, createFeedbackApi } from './api';
import { actions } from './reducer';
import { FeedbackTriggerMap } from 'common/constants/feedback';
export var fetchNeedFeedbackAction = function (feedbackTriggerType) {
    return function (dispatch) {
        var feedbackSectionType = FeedbackTriggerMap[feedbackTriggerType];
        dispatch(actions.setLoading(true));
        fetchNeedFeedbackApi(feedbackSectionType)
            .then(function (needFeedback) {
            if (needFeedback) {
                dispatch(actions.setFeedbackTrigger(feedbackTriggerType));
            }
        })
            .catch(function (error) {
            console.log('fetchNeedFeedbackAction ->', error);
            dispatch(actions.setError(error));
        });
    };
};
export var createFeedbackAction = function (data, successCb) {
    return function (dispatch) {
        dispatch(actions.setLoading(true));
        return createFeedbackApi(data)
            .then(function () {
            successCb === null || successCb === void 0 ? void 0 : successCb();
        })
            .catch(function (error) {
            console.log('createFeedbackAction ->', error);
            dispatch(actions.setError(error));
        });
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { Typography } from 'ui-kit/typography';
import { Box } from 'ui-kit/box';
import { Button } from 'ui-kit/button';
import { Tooltip } from 'ui-kit/tooltip';
import { Icon } from 'ui-kit/icon';
import { OneLineEmailIcon, EmailWithNotificationIcon } from 'common/icons';
import styles from './notification-list-item.module.scss';
export var NotificationListItem = function (_a) {
    var _b;
    var titleString = _a.titleString, bodyString = _a.bodyString, referenceTo = _a.referenceTo, createdOn = _a.createdOn, isRead = _a.isRead, id = _a.id, handleRead = _a.handleRead, handleRedirect = _a.handleRedirect;
    var t = useTranslation().t;
    var handleCardClick = function () {
        if (referenceTo) {
            !isRead && handleRead(id, isRead);
            handleRedirect(referenceTo);
        }
    };
    var handleReadButtonClick = function (e) {
        e.stopPropagation();
        handleRead(id, isRead);
    };
    return (_jsxs(Box, __assign({ direction: "column", position: "relative", gap: 1, className: cs(styles.cardWrapper, (_b = {}, _b[styles.link] = !!referenceTo, _b)), onClick: handleCardClick }, { children: [_jsx(Typography, __assign({ className: styles.title, variant: "body-s", weight: "bold" }, { children: titleString })), _jsx(Typography, __assign({ variant: "body-s" }, { children: bodyString })), _jsx(Typography, __assign({ className: styles.date, variant: "body-xs", color: "semi-light" }, { children: createdOn })), !isRead ? (_jsx(_Fragment, { children: _jsx(Tooltip, __assign({ content: t('common.notificationDrawer.actions.readTooltipText'), placement: "left" }, { children: _jsx(Button, __assign({ className: styles.readButton, variant: "text", onClickCapture: handleReadButtonClick }, { children: _jsx(Icon, { component: EmailWithNotificationIcon, color: "text", width: 1.25, viewBox: "0 0 20 20" }) })) })) })) : (_jsx(_Fragment, { children: _jsx(Tooltip, __assign({ content: t('common.notificationDrawer.actions.unreadTooltipText'), placement: "left" }, { children: _jsx(Button, __assign({ className: styles.readButton, variant: "text", onClickCapture: handleReadButtonClick }, { children: _jsx(Icon, { component: OneLineEmailIcon, color: "text", width: 1.25 }) })) })) }))] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNotificationDrawerActions } from '../../service/notification-drawer-service/hooks';
import { profileServiceTealBalanceSelector, profileServiceThanksBalanceSelector, profileServiceUserHasUnreadNotificationsSelector, } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { FormattedNumber } from 'ui-kit/formatted-number';
import { Button } from 'ui-kit/button';
import { Icon } from 'ui-kit/icon';
import { BellIcon } from 'common/icons';
import { CurrentUserAvatar } from 'common/components/current-user-avatar';
import { CurrencyIcon } from 'common/components/currency-icon';
import { ThanksCurrencyIcon } from 'common/components/thanks-currency-icon';
import { CheckRenderThanksBalance } from 'common/components/check-render-thanks-balance';
import { WithNotificationWrapper } from '../with-notification-wrapper';
import styles from './desktop-header.module.scss';
var UserInfo = function (_a) {
    var ml = _a.ml;
    var tealBalance = useSelector(profileServiceTealBalanceSelector);
    var thankBalance = useSelector(profileServiceThanksBalanceSelector);
    return (_jsxs(Box, __assign({ className: styles.infoWrapper, ml: ml, alignItems: "center", wrap: "nowrap" }, { children: [_jsx(FormattedNumber, { withoutPrefixGap: true, value: tealBalance, variant: "h6", component: "span", color: "primary", prefix: _jsx(CurrencyIcon, { className: styles.balanceIcon, width: 1.25 }) }), _jsx(CheckRenderThanksBalance, { children: _jsx(FormattedNumber, { withoutPrefixGap: true, value: thankBalance, variant: "h6", component: "span", color: "primary", prefix: _jsx(ThanksCurrencyIcon, { className: styles.balanceIcon, width: 1.25 }) }) }), _jsx(CurrentUserAvatar, { ml: 2 })] })));
};
export var DesktopHeader = function () {
    var openDrawer = useNotificationDrawerActions().openDrawer;
    return (_jsxs(Box, __assign({ className: styles.header, display: "flex", wrap: "nowrap", alignItems: "center", justify: "flex-end", component: "header" }, { children: [_jsx(WithNotificationWrapper, __assign({ hasNotificationSelector: profileServiceUserHasUnreadNotificationsSelector, onClick: function () { return openDrawer(); } }, { children: _jsx(Button, __assign({ variant: "text" }, { children: _jsx(Icon, { component: BellIcon, width: 1.25, viewBox: "0 0 20 20" }) })) })), _jsx(Link, __assign({ className: styles.link, to: "/profile" }, { children: _jsx(UserInfo, { ml: 8 }) }))] })));
};

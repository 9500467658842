export var ChartType;
(function (ChartType) {
    ChartType["NumberOfEmployees"] = "NumberOfEmployees";
    ChartType["EmployeeTurnover"] = "EmployeeTurnover";
})(ChartType || (ChartType = {}));
export var ChartPeriodType;
(function (ChartPeriodType) {
    ChartPeriodType["ByDays"] = "ByDays";
    ChartPeriodType["ByMonths"] = "ByMonths";
})(ChartPeriodType || (ChartPeriodType = {}));
export var ExperienceGroupType;
(function (ExperienceGroupType) {
    ExperienceGroupType["UpToThreeMonths"] = "UpToThreeMonths";
    ExperienceGroupType["BetweenThreeAndSixMonths"] = "BetweenThreeAndSixMonths";
    ExperienceGroupType["BetweenSixAndTwelveMonths"] = "BetweenSixAndTwelveMonths";
    ExperienceGroupType["BetweenOneAndThreeYears"] = "BetweenOneAndThreeYears";
    ExperienceGroupType["BetweenThreeAndFiveYears"] = "BetweenThreeAndFiveYears";
    ExperienceGroupType["BetweenFiveAndTenYears"] = "BetweenFiveAndTenYears";
    ExperienceGroupType["OverTenYears"] = "OverTenYears";
})(ExperienceGroupType || (ExperienceGroupType = {}));
export var AgeGroupType;
(function (AgeGroupType) {
    AgeGroupType["EarlyGeneration"] = "EarlyGeneration";
    AgeGroupType["BabyBoomerGeneration"] = "BabyBoomerGeneration";
    AgeGroupType["GenerationX"] = "GenerationX";
    AgeGroupType["GenerationY"] = "GenerationY";
    AgeGroupType["GenerationZ"] = "GenerationZ";
    AgeGroupType["GenerationAlpha"] = "GenerationAlpha";
})(AgeGroupType || (AgeGroupType = {}));

import { createSlice } from '@reduxjs/toolkit';
import { SURVEY_FORM_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    questionType: undefined,
    categoryId: undefined,
};
var questionsFilterServiceState = createSlice({
    name: SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.questionsFiter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        choiceSortingVariant: function (state, action) {
            state.questionType = action.payload;
        },
        selectCategory: function (state, action) {
            state.categoryId = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = questionsFilterServiceState.actions;
export default questionsFilterServiceState.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { USER_SERVICES_REDUCER_NAMESPACES } from 'pages/user-pages/profile/services/constants';
export var initialState = {
    activeTab: 0,
};
var titleServiceState = createSlice({
    name: USER_SERVICES_REDUCER_NAMESPACES.title,
    initialState: initialState,
    reducers: {
        setActiveTab: function (state, action) {
            state.activeTab = action.payload;
        },
        resetState: function () { return initialState; },
    },
});
export var actions = titleServiceState.actions;
export default titleServiceState.reducer;

import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sidebarServiceIsPinnedMenuSelector } from 'common/layouts/main-layout/service/sidebar-service/selectors';
import { useSidebarActions } from '../../../../service/sidebar-service/hooks';
export var ResizeObserver = function (_a) {
    var isToggledMenuRef = _a.isToggledMenuRef, onClose = _a.onClose;
    var reset = useSidebarActions().reset;
    var isPinnedMenu = useSelector(sidebarServiceIsPinnedMenuSelector);
    var location = useLocation();
    var _b = useState(window.matchMedia('screen and (max-width: 1425px)').matches), isToggled = _b[0], setIsToggled = _b[1];
    useEffect(function () {
        if (!isPinnedMenu)
            onClose();
    }, [onClose, location, isPinnedMenu]);
    useLayoutEffect(function () {
        if (isToggled) {
            isToggledMenuRef.current = true;
        }
        else {
            reset();
            isToggledMenuRef.current = false;
        }
    }, [reset, isToggledMenuRef, isToggled]);
    useLayoutEffect(function () {
        var cb = function () {
            if (window.matchMedia('screen and (min-width: 1425px)').matches && isToggled) {
                setIsToggled(false);
            }
            else if (window.matchMedia('screen and (max-width: 1425px)').matches && !isToggled) {
                setIsToggled(true);
            }
        };
        window.addEventListener('resize', cb);
        return function () { return window.removeEventListener('resize', cb); };
    }, [reset, isToggledMenuRef, isToggled]);
    return null;
};

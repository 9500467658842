export var OneToOneResultFilterDto;
(function (OneToOneResultFilterDto) {
    OneToOneResultFilterDto["WithGoodResult"] = "WithGoodResult";
    OneToOneResultFilterDto["WithMinorProblems"] = "WithMinorProblems";
    OneToOneResultFilterDto["WithProblems"] = "WithProblems";
    OneToOneResultFilterDto["WithoutOneToOne"] = "WithoutOneToOne";
})(OneToOneResultFilterDto || (OneToOneResultFilterDto = {}));
export var OneToOneInitiatorDtoType;
(function (OneToOneInitiatorDtoType) {
    OneToOneInitiatorDtoType["None"] = "None";
    OneToOneInitiatorDtoType["Employee"] = "Employee";
    OneToOneInitiatorDtoType["Supervisor"] = "Supervisor";
})(OneToOneInitiatorDtoType || (OneToOneInitiatorDtoType = {}));
export var OneToOneResultDto;
(function (OneToOneResultDto) {
    OneToOneResultDto["None"] = "None";
    OneToOneResultDto["Good"] = "Good";
    OneToOneResultDto["MinorProblems"] = "MinorProblems";
    OneToOneResultDto["Problems"] = "Problems";
})(OneToOneResultDto || (OneToOneResultDto = {}));

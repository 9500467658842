var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { SETTINGS_SERVICES_REDUCER_NAMESPACES } from '../../../services/constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
        total: 0,
    }, pageSize: 15, emissionDeleteTargetId: null });
var emissionServiceState = createSlice({
    name: SETTINGS_SERVICES_REDUCER_NAMESPACES.emissionList,
    initialState: initialState,
    reducers: __assign({ setEmissionList: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, deleteEmission: function (state, action) {
            state.data.content = state.data.content.filter(function (_a) {
                var id = _a.id;
                return id !== action.payload;
            });
            state.data.total = state.data.total - 1;
        }, setEmissionDeleteTargetId: function (state, action) {
            state.emissionDeleteTargetId = action.payload;
        }, reset: function () { return initialState; } }, baseListReducer),
});
export var actions = emissionServiceState.actions;
export default emissionServiceState.reducer;

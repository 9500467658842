var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import cs from 'classnames';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { HELPER_TEXT_POSTFIX_TESTID } from '../constants';
import styles from './controller-wrapper.module.scss';
var Label = memo(function (_a) {
    var label = _a.label, labelAdditional = _a.labelAdditional, isRequired = _a.isRequired;
    return label || labelAdditional ? (_jsxs(Box, __assign({ className: styles.label, display: "flex", alignItems: "center" }, { children: [label ? (_jsxs(Typography, __assign({ variant: "body-s" }, { children: [label, "\u00A0", isRequired && (_jsx(Typography, __assign({ color: "error", component: "span" }, { children: "*\u00A0" })))] }))) : null, labelAdditional] }))) : null;
});
export var ControllerWrapper = function (_a) {
    var name = _a.name, label = _a.label, hidden = _a.hidden, isError = _a.isError, children = _a.children, helperText = _a.helperText, isRequired = _a.isRequired, labelAdditional = _a.labelAdditional, wrapperClassName = _a.wrapperClassName;
    return (_jsxs("div", __assign({ className: cs(styles.controllerWrapper, wrapperClassName), hidden: hidden }, { children: [_jsx(Label, { label: label, labelAdditional: labelAdditional, isRequired: isRequired }), children, helperText ? (_jsx(Typography, __assign({ className: styles.helperText, variant: "body-s", dataTestid: "".concat(name, "-").concat(HELPER_TEXT_POSTFIX_TESTID), color: isError ? 'error' : undefined }, { children: helperText }))) : null] })));
};

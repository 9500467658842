import { createSlice } from '@reduxjs/toolkit';
import { THANKS_JOIN_SERVICE_REDUCER_NAMESPACES } from './constants';
export var initialState = {
    data: null,
};
var thanksJoinServiceState = createSlice({
    name: THANKS_JOIN_SERVICE_REDUCER_NAMESPACES.thanksJoin,
    initialState: initialState,
    reducers: {
        openThanksJoinForm: function (state, action) {
            state.data = action.payload;
        },
        closeThanksJoinForm: function (state) {
            state.data = null;
        },
    },
});
export var actions = thanksJoinServiceState.actions;
export default thanksJoinServiceState.reducer;

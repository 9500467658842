import { createSlice } from '@reduxjs/toolkit';
import { EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialModalFilterValues = {
    employeeIds: [],
    oneToOneResultFilter: [],
    dateOfTheLastOneToOne: null,
};
export var initialState = {
    searchString: '',
    isFilterOpen: false,
    modalFilterValues: initialModalFilterValues,
};
var developmentBlockEmployeesFilterServiceState = createSlice({
    name: EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterField: function (state, action) {
            var key = action.payload;
            if (key === 'dateOfTheLastOneToOne') {
                state.modalFilterValues[key] = null;
            }
            else {
                state.modalFilterValues[key] = [];
            }
        },
        removeEmployeeId: function (state, action) {
            var employeesId = action.payload;
            if (state.modalFilterValues.employeeIds.includes(employeesId)) {
                state.modalFilterValues.employeeIds = state.modalFilterValues.employeeIds.filter(function (id) { return employeesId !== id; });
            }
        },
        removeOneToOneResultFilter: function (state, action) {
            var oneToOneResult = action.payload;
            if (state.modalFilterValues.oneToOneResultFilter.includes(oneToOneResult)) {
                state.modalFilterValues.oneToOneResultFilter = state.modalFilterValues.oneToOneResultFilter.filter(function (id) { return oneToOneResult !== id; });
            }
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        },
        openFilter: function (state) {
            state.isFilterOpen = true;
        },
        closeFilter: function (state) {
            state.isFilterOpen = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = developmentBlockEmployeesFilterServiceState.actions;
export default developmentBlockEmployeesFilterServiceState.reducer;

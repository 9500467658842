var _a;
import { combineReducers } from 'redux';
import corporateCurrencyStatisticsServiceReducer from './corporate-currency-statistics-service/reducer';
import corporateCurrencyChartServiceReducer from './corporate-currency-chart-service/reducer';
import corporateCurrencyFilterServiceReducer from './corporate-currency-filter-service/reducer';
import corporateCurrencyWidgetsServiceReducer from './corporate-currency-widgets-service/reducer';
import { CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES } from './constants';
export var corporateCurrencyEmployeesStatisticsRootReducer = combineReducers((_a = {},
    _a[CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES.corporateCurrencyEmployeesStatistics] = corporateCurrencyStatisticsServiceReducer,
    _a[CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES.corporateCurrencyEmployeesStatisticsChart] = corporateCurrencyChartServiceReducer,
    _a[CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES.corporateCurrencyEmployeesStatisticsFilter] = corporateCurrencyFilterServiceReducer,
    _a[CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES.corporateCurrencyEmployeesStatisticsWidgets] = corporateCurrencyWidgetsServiceReducer,
    _a));

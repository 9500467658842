var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { useSidebarActions } from '../../service/sidebar-service/hooks';
import { Menu, ResizeObserver, ToggleMenuButton, SidebarWrapper } from './modules';
import styles from './sidebar.module.scss';
export var Sidebar = function () {
    var setIsOpenMenu = useSidebarActions().setIsOpenMenu;
    var isToggledMenuRef = useRef(false);
    var handleOpenMenu = useCallback(function () {
        if (isToggledMenuRef.current)
            setIsOpenMenu(true);
    }, [setIsOpenMenu]);
    var handleCloseMenu = useCallback(function () {
        if (isToggledMenuRef.current)
            setIsOpenMenu(false);
    }, [setIsOpenMenu]);
    return (_jsxs(SidebarWrapper, __assign({ className: styles.sidebar, onClose: handleCloseMenu }, { children: [_jsx(Menu, {}), _jsx(ToggleMenuButton, { onClick: handleOpenMenu }), _jsx(ResizeObserver, { isToggledMenuRef: isToggledMenuRef, onClose: handleCloseMenu })] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { INITIAL_ANSWER_DATA_BASIC } from 'common/constants/take-survey-common';
export var getInitialAnswersData = function (questions) {
    var answersData = {};
    questions.forEach(function (_a) {
        var id = _a.id, generalSettings = _a.generalSettings;
        answersData[id] = {
            data: __assign(__assign({}, INITIAL_ANSWER_DATA_BASIC), { questionId: id }),
            isRequired: generalSettings.isRequired,
            isSelectedOtherOption: false,
        };
    });
    return answersData;
};

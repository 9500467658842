var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { SettingFormType } from '../../types';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { SURVEY_FORM_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, pageSize: 10, employeesCount: 0, allEmployeesCount: 0, openFormType: null });
var employeesServiceState = createSlice({
    name: SURVEY_FORM_SERVICES_REDUCER_NAMESPACES.employees,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, setEmployeesCount: function (state, action) {
            state.employeesCount = action.payload;
        }, setAllEmployeesCount: function (state, action) {
            state.allEmployeesCount = action.payload;
        }, openChoiceTagsForm: function (state) {
            state.openFormType = SettingFormType.TAGS_CONTROL_FORM;
        }, openChoiceEmployeesForm: function (state) {
            state.openFormType = SettingFormType.EMPLOYEES_CONTROL_FORM;
        }, closeForm: function (state) {
            state.initialized = false;
            state.openFormType = null;
        }, resetData: function (state) {
            state.data = {
                total: 0,
                pageData: {
                    page: 0,
                    pageSize: 0,
                },
                content: [],
            };
            state.pageSize = 10;
        }, reset: function () { return initialState; } }, baseListReducer),
});
export var actions = employeesServiceState.actions;
export default employeesServiceState.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { AWARDS_SERVICES_REDUCER_NAMESPACES } from '../../../services/constants';
export var initialState = {
    intitialValues: null,
};
var editAwardStatusFormServiceState = createSlice({
    name: AWARDS_SERVICES_REDUCER_NAMESPACES.editAwardStatusForm,
    initialState: initialState,
    reducers: {
        setInitialValues: function (state, action) {
            state.intitialValues = action.payload;
        },
        removeInitialValues: function () { return initialState; },
    },
});
export var actions = editAwardStatusFormServiceState.actions;
export default editAwardStatusFormServiceState.reducer;

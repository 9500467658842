var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PageLoader } from 'ui-kit/loader';
import App from './app';
import { setupStore } from './redux/configure-store';
import { userService } from 'common/utils/user-service';
import 'app/i18n-service/i18n';
import 'common/styles/index.scss';
var store = setupStore();
var rootNode = document.getElementById('appRoot');
var root = createRoot(rootNode);
var renderApp = function () {
    root.render(_jsx(Suspense, __assign({ fallback: _jsx(PageLoader, {}) }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(Router, { children: _jsx(App, {}) }) })) })));
};
userService.initAuthorization(renderApp);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useRef } from 'react';
export var useObservable = function (state) {
    var stateRef = useRef(state);
    var stateSubcribersRef = useRef(Object.keys(state).reduce(function (acc, key) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[key] = [], _a)));
    }, {}));
    var notifySubscribers = useCallback(function (key, value) {
        for (var _i = 0, _a = stateSubcribersRef.current[key] || []; _i < _a.length; _i++) {
            var fn = _a[_i];
            fn(value);
        }
    }, []);
    var setSubscriber = useCallback(function (key, fn) {
        stateSubcribersRef.current[key].push(fn);
        return function () {
            stateSubcribersRef.current[key].filter(function (subscriber) { return subscriber !== fn; });
        };
    }, []);
    var changeState = useCallback(function (key, value) {
        if (stateRef.current[key] !== value) {
            notifySubscribers(key, value);
            stateRef.current[key] = value;
        }
    }, [notifySubscribers]);
    var getStateValueByKey = useCallback(function (key) { return stateRef.current[key]; }, []);
    return { changeState: changeState, setSubscriber: setSubscriber, getStateValueByKey: getStateValueByKey };
};

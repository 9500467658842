var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { ORDERS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialModalFilterValues = {
    statuses: [],
    receiveTypes: [],
    productType: null,
    createDatesInterval: null,
};
export var initialState = {
    searchString: '',
    isOpenModalFilter: false,
    modalFilterValues: initialModalFilterValues,
};
var ordersFilterServiceState = createSlice({
    name: ORDERS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        openFilterModal: function (state) {
            state.isOpenModalFilter = true;
        },
        closeFilterModal: function (state) {
            state.isOpenModalFilter = false;
        },
        setModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterValues: function (state) {
            state.modalFilterValues = initialModalFilterValues;
        },
        resetModalFilterField: function (state, action) {
            var _a;
            state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_a = {}, _a[action.payload] = initialModalFilterValues[action.payload], _a));
        },
        removeSelectedStatus: function (state, action) {
            state.modalFilterValues.statuses = state.modalFilterValues.statuses.filter(function (el) { return el !== action.payload; });
        },
        removeSelectedReceiveType: function (state, action) {
            state.modalFilterValues.receiveTypes = state.modalFilterValues.receiveTypes.filter(function (el) { return el !== action.payload; });
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = ordersFilterServiceState.actions;
export default ordersFilterServiceState.reducer;

var _a;
import { combineReducers } from 'redux';
import surveysStatisticsServiceReducer from './surveys-filter-service/reducer';
import corporateCurrencyChartServiceReducer from './chart-service/reducer';
import surveyTableStatisticsServiceReducer from './table-statistic-service/reducer';
import { SURVEYS_STATISTIC_SERVICES_REDUCER_NAMESPACES } from './constants';
export var surveysStatisticsRootReducer = combineReducers((_a = {},
    _a[SURVEYS_STATISTIC_SERVICES_REDUCER_NAMESPACES.surveysStatisticsFilter] = surveysStatisticsServiceReducer,
    _a[SURVEYS_STATISTIC_SERVICES_REDUCER_NAMESPACES.surveysStatisticsTable] = surveyTableStatisticsServiceReducer,
    _a[SURVEYS_STATISTIC_SERVICES_REDUCER_NAMESPACES.surveysStatisticsChart] = corporateCurrencyChartServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { ORDERS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    } });
var courierCompaniesServiceState = createSlice({
    name: ORDERS_SERVICES_REDUCER_NAMESPACES.courierCompanies,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, addCourierCompany: function (state, action) {
            state.data.content = __spreadArray(__spreadArray([], state.data.content, true), [action.payload], false);
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = courierCompaniesServiceState.actions;
export default courierCompaniesServiceState.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { APPLICATIONS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    statuses: undefined,
    currentUserCanProcess: undefined,
    isOpenModal: false,
};
var applicationsFilterServiceState = createSlice({
    name: APPLICATIONS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        setFilterData: function (state, action) {
            var _a = action.payload, currentUserCanProcess = _a.currentUserCanProcess, statuses = _a.statuses;
            state.currentUserCanProcess = currentUserCanProcess;
            state.statuses = statuses;
        },
        showModal: function (state) {
            state.isOpenModal = true;
        },
        closeModal: function (state) {
            state.isOpenModal = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = applicationsFilterServiceState.actions;
export default applicationsFilterServiceState.reducer;

export var APP_SERVICES_REDUCER_NAMESPACES = {
    common: 'common',
    profile: 'profile',
    companyAdditionalInfo: 'company-additional-info',
    toast: 'toast',
    customizations: 'customizations',
    supportRequestTypes: 'support-request-types',
    conversion: 'conversion',
    showThanksBalance: 'show-thanks-balance',
};

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import { STAND } from 'common/constants/common';
export var setupStore = function () {
    return configureStore({
        reducer: rootReducer,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: false,
            });
        },
        devTools: STAND === 'dev',
    });
};

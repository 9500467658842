import { checkShowThanksBalanceApi } from './api';
import { actions } from './reducer';
export var checkShowThanksBalanceAction = function () { return function (dispatch) {
    dispatch(actions.setLoading(true));
    return checkShowThanksBalanceApi()
        .then(function (data) {
        dispatch(actions.setData(data));
    })
        .catch(function (error) {
        console.log('checkShowThanksBalanceAction ->', error);
        dispatch(actions.setError(error));
    });
}; };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { customizationsServiceLogoImagePathSelector } from 'common/services/customizations-service/selectors';
import { LazyImage } from 'ui-kit/lazy-image';
import { LogoIcon } from './icons';
import { FILE_END_POINT } from 'common/constants/common';
import styles from './logo.module.scss';
var LOGO_IMAGE_SIZES_MAP = {
    small: styles.small,
    medium: styles.medium,
};
export var Logo = function (_a) {
    var size = _a.size;
    var logoImageSrc = useSelector(customizationsServiceLogoImagePathSelector);
    if (!logoImageSrc)
        return _jsx(LogoIcon, { className: cs(styles.defaultLogo, LOGO_IMAGE_SIZES_MAP[size !== null && size !== void 0 ? size : 'medium']), viewBox: "0 0 96 32" });
    return (_jsx("div", __assign({ className: cs(styles.customlogoWrapper, LOGO_IMAGE_SIZES_MAP[size !== null && size !== void 0 ? size : 'medium']) }, { children: _jsx(LazyImage, { className: styles.customlogo, src: "".concat(FILE_END_POINT, "/").concat(logoImageSrc) }) })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { baseReducers } from 'common/utils/base-reduсer';
import { REQUEST_PROCESSING_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { data: null, answerFormInitialValues: null, isOpenEditRequestPriorityForm: false, isOpenEditRequestExecutorsForm: false, confirmModal: false });
var requestServiceState = createSlice({
    name: REQUEST_PROCESSING_REDUCER_NAMESPACES.request,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, setAnswerFormInitialValues: function (state, action) {
            state.answerFormInitialValues = action.payload;
        }, openEditRequestPriorityForm: function (state) {
            state.isOpenEditRequestPriorityForm = true;
        }, closeEditRequestPriorityForm: function (state) {
            state.isOpenEditRequestPriorityForm = false;
        }, openEditRequestExecutorsForm: function (state) {
            state.isOpenEditRequestExecutorsForm = true;
        }, closeEditRequestExecutorsForm: function (state) {
            state.isOpenEditRequestExecutorsForm = false;
        }, openConfirmModal: function (state) {
            state.confirmModal = true;
        }, closeConfirmModal: function (state) {
            state.confirmModal = false;
        }, resetData: function () { return initialState; } }, baseReducers),
});
export var actions = requestServiceState.actions;
export default requestServiceState.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { SortOrderType } from '../../types';
import { ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var INITIAL_MODAL_FILTER_VALUES = {
    sortOrder: SortOrderType.Desc,
    employeeGroups: [],
};
export var initialState = {
    searchString: '',
    showModalFilter: false,
    modalFilterValues: INITIAL_MODAL_FILTER_VALUES,
};
var eNPSAnswersListFilterServiceState = createSlice({
    name: ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.eNPSAnswersListFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        setModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        removeEmployeeGroup: function (state, action) {
            state.modalFilterValues.employeeGroups = state.modalFilterValues.employeeGroups.filter(function (item) { return item !== action.payload; });
        },
        resetModalFilterValues: function (state) {
            state.modalFilterValues = INITIAL_MODAL_FILTER_VALUES;
        },
        showModalFilter: function (state) {
            state.showModalFilter = true;
        },
        hideModalFilter: function (state) {
            state.showModalFilter = false;
        },
        resetState: function () { return initialState; },
    },
});
export var actions = eNPSAnswersListFilterServiceState.actions;
export default eNPSAnswersListFilterServiceState.reducer;

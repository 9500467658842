var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationDrawerActions } from '../../service/notification-drawer-service/hooks';
import { notificationDrawerServiceIsOpenSelector, notificationDrawerServiceLoadingSelector, } from '../../service/notification-drawer-service/selectors';
import { Drawer } from 'ui-kit/drawer';
import { Tabs } from 'ui-kit/tabs';
import { Box } from 'ui-kit/box';
import { LoaderWrapperWithSelector } from 'common/components/loader-wrapper-with-selector';
import { NotificationListContainer, NotificationListController, NotificationListActions } from './modules';
import styles from './notification-drawer.module.scss';
export var NotificationDrawer = function () {
    var t = useTranslation().t;
    var _a = useNotificationDrawerActions(), closeDrawer = _a.closeDrawer, setOnlyNotRead = _a.setOnlyNotRead;
    var notificationContainerRef = useRef(null);
    var tabs = [t('common.notificationDrawer.tabs.all'), t('common.notificationDrawer.tabs.notRead')];
    var onTabClick = useCallback(function (tabIndex) {
        setOnlyNotRead(!!tabIndex);
    }, [setOnlyNotRead]);
    return (_jsx(Drawer, __assign({ isClosedByOverlayClick: true, className: styles.drawer, side: "right", isOpenSelector: notificationDrawerServiceIsOpenSelector, size: "small", title: t('common.notificationDrawer.title'), onClose: closeDrawer }, { children: _jsxs(LoaderWrapperWithSelector, __assign({ className: styles.contentWrapper, loadingSelector: notificationDrawerServiceLoadingSelector }, { children: [_jsx(NotificationListActions, { pb: 12, notificationContainerRef: notificationContainerRef }), _jsx(Box, __assign({ display: "block", px: 8 }, { children: _jsx(Tabs, { tabs: tabs, additionalTabClickHandler: onTabClick }) })), _jsx(NotificationListContainer, __assign({ ref: notificationContainerRef }, { children: _jsx(NotificationListController, { notificationContainerRef: notificationContainerRef }) }))] })) })));
};

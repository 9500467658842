export var SurveyLifeStatusType;
(function (SurveyLifeStatusType) {
    SurveyLifeStatusType["Planned"] = "Planned";
    SurveyLifeStatusType["InProgress"] = "InProgress";
    SurveyLifeStatusType["Finished"] = "Finished";
    SurveyLifeStatusType["Archived"] = "Archived";
})(SurveyLifeStatusType || (SurveyLifeStatusType = {}));
export var SurveyType;
(function (SurveyType) {
    SurveyType["Custom"] = "Custom";
    SurveyType["EmployeeAssessment"] = "EmployeeAssessment";
    SurveyType["EmployeeNetPromoterScore"] = "EmployeeNetPromoterScore";
    SurveyType["New360"] = "New360";
    SurveyType["OneToOne"] = "OneToOne";
})(SurveyType || (SurveyType = {}));
export var QuestionType;
(function (QuestionType) {
    QuestionType["Open"] = "Open";
    QuestionType["Scale"] = "Scale";
    QuestionType["SingleChoice"] = "SingleChoice";
    QuestionType["MultipleChoice"] = "MultipleChoice";
    QuestionType["Matrix"] = "Matrix";
})(QuestionType || (QuestionType = {}));
export var LogicalOperationType;
(function (LogicalOperationType) {
    LogicalOperationType["None"] = "None";
    LogicalOperationType["And"] = "And";
    LogicalOperationType["Or"] = "Or";
})(LogicalOperationType || (LogicalOperationType = {}));
export var ComparisonOperationType;
(function (ComparisonOperationType) {
    ComparisonOperationType["None"] = "None";
    ComparisonOperationType["Equal"] = "Equal";
    ComparisonOperationType["NotEqual"] = "NotEqual";
})(ComparisonOperationType || (ComparisonOperationType = {}));

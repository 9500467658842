export var EmployeesStatisticsFilterType;
(function (EmployeesStatisticsFilterType) {
    EmployeesStatisticsFilterType["EmployeesCount"] = "EmployeesCount";
    EmployeesStatisticsFilterType["StaffTurnover"] = "StaffTurnover";
})(EmployeesStatisticsFilterType || (EmployeesStatisticsFilterType = {}));
export var StatisticsIntervalVariant;
(function (StatisticsIntervalVariant) {
    StatisticsIntervalVariant["Week"] = "Week";
    StatisticsIntervalVariant["Month"] = "Month";
    StatisticsIntervalVariant["ThreeMonth"] = "ThreeMonth";
    StatisticsIntervalVariant["Year"] = "Year";
})(StatisticsIntervalVariant || (StatisticsIntervalVariant = {}));

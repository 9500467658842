export var SupportRequestPriorityDto;
(function (SupportRequestPriorityDto) {
    SupportRequestPriorityDto["Undefined"] = "Undefined";
    SupportRequestPriorityDto["Low"] = "Low";
    SupportRequestPriorityDto["Medium"] = "Medium";
    SupportRequestPriorityDto["High"] = "High";
    SupportRequestPriorityDto["Critical"] = "Critical";
})(SupportRequestPriorityDto || (SupportRequestPriorityDto = {}));
export var SupportRequestStatusDto;
(function (SupportRequestStatusDto) {
    SupportRequestStatusDto["Created"] = "Created";
    SupportRequestStatusDto["InProgress"] = "InProgress";
    SupportRequestStatusDto["Closed"] = "Closed";
})(SupportRequestStatusDto || (SupportRequestStatusDto = {}));
export var SupportRequestLogActionType;
(function (SupportRequestLogActionType) {
    SupportRequestLogActionType["SupportRequestCreated"] = "SupportRequestCreated";
    SupportRequestLogActionType["StatusChanged"] = "StatusChanged";
    SupportRequestLogActionType["ExecutorAdded"] = "ExecutorAdded";
    SupportRequestLogActionType["CommentAdded"] = "CommentAdded";
    SupportRequestLogActionType["PriorityChanged"] = "PriorityChanged";
    SupportRequestLogActionType["PriorityRemoved"] = "PriorityRemoved";
    SupportRequestLogActionType["ExecutorRemoved"] = "ExecutorRemoved";
    SupportRequestLogActionType["AnswerChanged"] = "AnswerChanged";
    SupportRequestLogActionType["AnswerAdded"] = "AnswerAdded";
    SupportRequestLogActionType["AnswerRemoved"] = "AnswerRemoved";
    SupportRequestLogActionType["FilesAdded"] = "FilesAdded";
    SupportRequestLogActionType["FilesChanged"] = "FilesChanged";
    SupportRequestLogActionType["FilesRemoved"] = "FilesRemoved";
    SupportRequestLogActionType["SupportRequestArchived"] = "SupportRequestArchived";
    SupportRequestLogActionType["SupportRequestUnarchived"] = "SupportRequestUnarchived";
})(SupportRequestLogActionType || (SupportRequestLogActionType = {}));

import FormData from 'form-data';
var isSimpleObject = function (data) { return !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob); };
function buildFormData(formData, data, parentKey) {
    if (Array.isArray(data)) {
        data.forEach(function (value, index) {
            if (typeof value == 'object' && isSimpleObject(value)) {
                buildFormData(formData, value, "".concat(parentKey, "[").concat(index, "]"));
            }
            else {
                formData.append(parentKey, value);
            }
        });
    }
    else if (data && typeof data === 'object' && isSimpleObject(data)) {
        var currentData_1 = data;
        Object.keys(currentData_1).forEach(function (key) {
            buildFormData(formData, currentData_1[key], "".concat(parentKey, "[").concat(key, "]"));
        });
    }
    else {
        formData.append(parentKey, data);
    }
}
export var getFormData = function (data) {
    var formData = new FormData();
    var keys = Object.keys(data);
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var k = keys_1[_i];
        var item = data[k];
        buildFormData(formData, item, k);
    }
    return formData;
};

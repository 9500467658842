import { createSlice } from '@reduxjs/toolkit';
import { ActivitiesVariantType } from '../../types';
import { ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    isActiveActivities: true,
    isOpenCreateActivityForm: false,
    activitiesVariant: ActivitiesVariantType.activities,
    isAutomated: undefined,
    categoryId: null,
};
var activitiesFilterServiceState = createSlice({
    name: ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES.activitiesFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        setIsAutomated: function (state, action) {
            state.isAutomated = action.payload;
        },
        changeCategoryId: function (state, action) {
            state.categoryId = action.payload;
        },
        editActiveTab: function (state, action) {
            state.activitiesVariant = action.payload.activitiesVariant;
            state.isActiveActivities = action.payload.isActiveActivities;
        },
        openCreateActivityForm: function (state) {
            state.isOpenCreateActivityForm = true;
        },
        closeCreateActivityForm: function (state) {
            state.isOpenCreateActivityForm = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = activitiesFilterServiceState.actions;
export default activitiesFilterServiceState.reducer;

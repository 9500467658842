var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Portal } from '../portal';
import { ModalLayout, ModalFooter, ModalOverlay, BaseModalFooter } from './modules';
import { useMounted } from 'common/hooks/use-mounted';
var Modal = function (_a) {
    var isOpen = _a.isOpen, isShowCloseIcon = _a.isShowCloseIcon, isClosedByOverlayClick = _a.isClosedByOverlayClick, children = _a.children, onClose = _a.onClose, rest = __rest(_a, ["isOpen", "isShowCloseIcon", "isClosedByOverlayClick", "children", "onClose"]);
    var mounted = useMounted({ isOpen: isOpen });
    if (!mounted) {
        return null;
    }
    return (_jsx(Portal, { children: _jsx(ModalOverlay, __assign({ isOpen: isOpen, isClickable: isClosedByOverlayClick, onClose: onClose }, { children: _jsx(ModalLayout, __assign({ isOpen: isOpen, isShowCloseIcon: isShowCloseIcon !== null && isShowCloseIcon !== void 0 ? isShowCloseIcon : true }, rest, { onClose: onClose }, { children: children })) })) }));
};
Modal.Footer = ModalFooter;
Modal.BaseFooter = BaseModalFooter;
export { Modal };

import { createSelector } from 'reselect';
import { sharedServicesRootSelector } from '../root-selector';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from '../constants';
var rootName = SHARED_SERVICES_REDUCER_NAMESPACES.shared;
var stateName = SHARED_SERVICES_REDUCER_NAMESPACES.feedback;
export var feedbackServiceRootSelector = createSelector(sharedServicesRootSelector, function (state) { return state && state[stateName]; });
export var feedbackServiceTriggerSelector = createSelector(feedbackServiceRootSelector, function (state) { return state === null || state === void 0 ? void 0 : state.feedbackTriggerType; });
export var feedbackServiceLoadingSelector = function (_a) {
    var _b = rootName, state = _a[_b];
    return Boolean(state[stateName].loading);
};

import { useLayoutEffect, useState } from 'react';
import { checkEqual } from '../helpers/checkEqual';
var SMALL_MOBILE = 'only screen and (max-width: 480px)';
var MOBILE = 'only screen and (max-width: 576px)';
var TABLET = 'only screen and (min-width: 577px) and (max-width: 991px)';
var DESKTOP = 'only screen and (min-width: 992px)';
var DESKTOP_XL = 'only screen and (min-width: 1200px)';
var getCurrentState = function () {
    var isMobile = window.matchMedia(MOBILE).matches;
    var isDesktop = window.matchMedia(DESKTOP).matches;
    return {
        isSmallMobile: window.matchMedia(SMALL_MOBILE).matches,
        isMobile: isMobile,
        isTablet: window.matchMedia(TABLET).matches,
        isDesktop: isDesktop,
        isDesktopXL: window.matchMedia(DESKTOP_XL).matches,
        isExceptDesktop: !isDesktop,
        isExceptMobile: !isMobile,
    };
};
var changeState = function (_a) {
    var subscribeScreen = _a.subscribeScreen, prevState = _a.prevState, onChangeState = _a.onChangeState;
    var currentState = getCurrentState();
    var subscribeScreenArray = subscribeScreen && [subscribeScreen];
    if ((subscribeScreenArray === null || subscribeScreenArray === void 0 ? void 0 : subscribeScreenArray.includes('except-mobile')) && currentState.isExceptMobile !== prevState.isExceptMobile) {
        onChangeState(currentState);
        return;
    }
    if ((subscribeScreenArray === null || subscribeScreenArray === void 0 ? void 0 : subscribeScreenArray.includes('except-desktop')) && currentState.isExceptDesktop !== prevState.isExceptDesktop) {
        onChangeState(currentState);
        return;
    }
    if (!subscribeScreenArray) {
        if (!checkEqual(currentState, prevState))
            onChangeState(currentState);
        return;
    }
    if (subscribeScreenArray.includes('small-mobile') && currentState.isSmallMobile !== prevState.isSmallMobile) {
        onChangeState(currentState);
        return;
    }
    if (subscribeScreenArray.includes('mobile') && currentState.isMobile !== prevState.isMobile) {
        onChangeState(currentState);
        return;
    }
    if (subscribeScreenArray.includes('tablet') && currentState.isTablet !== prevState.isTablet) {
        onChangeState(currentState);
        return;
    }
    if (subscribeScreenArray.includes('desktop') && currentState.isDesktop !== prevState.isDesktop) {
        onChangeState(currentState);
        return;
    }
    if (subscribeScreenArray.includes('desktop-xl') && currentState.isDesktopXL !== prevState.isDesktopXL) {
        onChangeState(currentState);
        return;
    }
};
export var useBreakpoints = function (subscribeScreen) {
    var _a = useState(getCurrentState), state = _a[0], setState = _a[1];
    useLayoutEffect(function () {
        var onResize = function () {
            changeState({ subscribeScreen: subscribeScreen, prevState: state, onChangeState: setState });
        };
        window.addEventListener('resize', onResize);
        return function () {
            window.removeEventListener('resize', onResize);
        };
    }, [state, subscribeScreen]);
    return state;
};

import * as Yup from 'yup';
import { t } from 'i18next';
import { htmlDecode } from '../helpers/html-decode';
import { sanitizeHtml } from '../helpers/sanitize-html';
import { getTextWithoutMentions } from '../helpers/get-text-without-mentions';
import { FORBIDDEN_CYRILLIC_LETTERS, NO_WHITESPACE } from 'common/constants/regexp';
import { TEN_MB } from '../constants/file-sizes';
export var PasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(4, t('common.errorTexts.minLength', { count: 4 }))
        .max(30, t('common.errorTexts.maxLength', { count: 30 }))
        .matches(FORBIDDEN_CYRILLIC_LETTERS, t('common.errorTexts.onlyLatin'))
        .matches(NO_WHITESPACE, t('common.errorTexts.noWhitespace'))
        .required(t('common.passwordErrors.passwordReq')),
});
export var getPhoneSchema = function (field) {
    return Yup.string().test(field, t('employeesPage.formErrors.phoneInvalid'), function (value) {
        if (!value)
            return true;
        var currentValue = value.replace(/[^\d]/g, '');
        if (currentValue.length < 10 || currentValue.length > 18)
            return false;
        return true;
    });
};
export var getEmailSchema = function (fieldName) {
    var _a;
    if (fieldName === void 0) { fieldName = 'email'; }
    return Yup.object().shape((_a = {},
        _a[fieldName] = Yup.string()
            .matches(FORBIDDEN_CYRILLIC_LETTERS, t('common.errorTexts.onlyLatin'))
            .email(t('common.errorTexts.emailInvalid'))
            .required(t('common.errorTexts.emailReq')),
        _a));
};
export var getTextEditorStringSchema = function (fieldName, chartsCount) {
    return Yup.string()
        .transform(function (value) { return (value ? htmlDecode(sanitizeHtml(getTextWithoutMentions(value))) : ''); })
        .test(fieldName, t('common.errorTexts.maxLength', { count: chartsCount }), function (value) {
        if (!value)
            return true;
        return value.length <= chartsCount;
    });
};
export var getMaxFilesSizeSchema = function (maxFilesSize, maxFilesSizeInMB) {
    if (maxFilesSize === void 0) { maxFilesSize = TEN_MB; }
    if (maxFilesSizeInMB === void 0) { maxFilesSizeInMB = 10; }
    return Yup.array().test('maxFilesSize', t('common.errorTexts.maxfilesSize', { size: "".concat(maxFilesSizeInMB) }), function (value) { return !value || value.reduce(function (a, b) { return a + b.size; }, 0) <= maxFilesSize; });
};

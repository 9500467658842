var _a;
import { combineReducers } from 'redux';
import developmentBlockEmployeesFilterServiceReducer from './filter-service/reducer';
import developmentBlockEmployeesListServiceReducer from './employees-list-service/reducer';
import developmentBlockEmployeesOptionsServiceReducer from './employees-options-service/reducer';
import { EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES } from './constants';
export var developmentBlockEmployeesRootReducer = combineReducers((_a = {},
    _a[EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES.filter] = developmentBlockEmployeesFilterServiceReducer,
    _a[EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES.employeesList] = developmentBlockEmployeesListServiceReducer,
    _a[EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES.employeesOptions] = developmentBlockEmployeesOptionsServiceReducer,
    _a));

import { createSlice } from '@reduxjs/toolkit';
import { getThirtyDaysInterval } from 'pages/manager-pages/statistics/helpers/get-thirty-days-interval';
import { CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    modalFilterValues: {
        period: getThirtyDaysInterval(),
    },
    isFilterModalOpen: false,
};
var corporateCurrencyFilterServiceState = createSlice({
    name: CORPORATE_CURRENCY_EMPLOYEES_SERVICES_REDUCER_NAMESPACES.corporateCurrencyEmployeesStatisticsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterFieldValue: function (state, action) {
            state.modalFilterValues[action.payload] = null;
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = { period: null };
        },
        openFilterModal: function (state) {
            state.isFilterModalOpen = true;
        },
        closeFilterModal: function (state) {
            state.isFilterModalOpen = false;
        },
        removePeriod: function (state) {
            state.modalFilterValues.period = null;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = corporateCurrencyFilterServiceState.actions;
export default corporateCurrencyFilterServiceState.reducer;

export var prepareParamsToQueryString = function (params, isFirst) {
    return Object.entries(params).reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        var _b = Array.isArray(value) ? value : [value], currentValue = _b[0], isNeedEncode = _b[1];
        if (['number', 'boolean'].includes(typeof currentValue) || !!currentValue) {
            acc.queryString += "".concat(acc.isFirstItem && isFirst ? '?' : '&').concat(key, "=").concat(isNeedEncode ? encodeURIComponent(currentValue) : currentValue);
            if (acc.isFirstItem)
                acc.isFirstItem = false;
        }
        return acc;
    }, { queryString: '', isFirstItem: true }).queryString;
};

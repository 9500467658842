var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cs from 'classnames';
import { Icon } from 'ui-kit/icon';
import { CloseSquareIcon } from './icons';
import styles from './input-base-root.module.scss';
export var InputBaseRoot = forwardRef(function (_a, ref) {
    var _b, _c, _d;
    var value = _a.value, placeholder = _a.placeholder, disabled = _a.disabled, prefix = _a.prefix, postfix = _a.postfix, isError = _a.isError, isDeactivated = _a.isDeactivated, withButton = _a.withButton, labels = _a.labels, className = _a.className, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, onClear = _a.onClear, onClick = _a.onClick, rest = __rest(_a, ["value", "placeholder", "disabled", "prefix", "postfix", "isError", "isDeactivated", "withButton", "labels", "className", "onMouseEnter", "onMouseLeave", "onClear", "onClick"]);
    var prefixView = prefix ? _jsx("span", __assign({ className: styles.prefix }, { children: prefix })) : null;
    var postfixView = postfix ? (_jsx("span", __assign({ className: cs(styles.postfix, (_b = {}, _b[styles.button] = !disabled && withButton, _b)) }, { children: postfix }))) : null;
    var labelView = !value && labels ? (_jsx("span", __assign({ className: styles.labels, title: labels }, { children: labels }))) : null;
    var cleanButton = onClear && value ? (_jsx("div", __assign({ className: styles.clearButton }, { children: _jsx(Icon, { className: styles.clearIcon, color: "error", component: CloseSquareIcon, onClick: onClear }) }))) : null;
    return (_jsxs("div", __assign({ className: cs(styles.inputWrapper, (_c = {},
            _c[styles.withPrefix] = prefix,
            _c[styles.withPostfix] = postfix,
            _c[styles.cleanable] = onClear,
            _c[styles.disabled] = disabled,
            _c), className), tabIndex: -1, onClick: onClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, { children: [prefixView, _jsx("input", __assign({ className: cs(styles.input, (_d = {},
                    _d[styles.error] = isError,
                    _d[styles.deactivated] = isDeactivated,
                    _d)), ref: ref, value: value, placeholder: !labels ? placeholder : undefined, disabled: disabled, readOnly: isDeactivated, "data-testid": rest.name }, rest)), labelView, postfixView, cleanButton] })));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notificationDrawerServiceInitializedSelector, notificationDrawerServiceLoadingSelector, } from 'common/layouts/main-layout/service/notification-drawer-service/selectors';
import { Typography } from 'ui-kit/typography';
import { Box } from 'ui-kit/box';
export var EmptyNotificationList = function () {
    var t = useTranslation().t;
    var initialized = useSelector(notificationDrawerServiceInitializedSelector);
    var loading = useSelector(notificationDrawerServiceLoadingSelector);
    return initialized && !loading ? (_jsxs(Box, __assign({ display: "flex", direction: "column", alignItems: "center", gap: 4, py: 12 }, { children: [_jsx(Typography, __assign({ variant: "body-s", component: "h4", weight: "bold" }, { children: t('common.notificationDrawer.emptyCard.title') })), _jsx(Typography, __assign({ variant: "body-s" }, { children: t('common.notificationDrawer.emptyCard.description') }))] }))) : null;
};

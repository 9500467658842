var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { baseReducers } from 'common/utils/base-reduсer';
import { OPERATION_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateProperties), { searchString: '', isFilterModalOpen: false, modalFilterValues: {
        category: [],
        walletTypes: [],
        period: null,
    }, loading: true });
var operationsFilterServiceState = createSlice({
    name: OPERATION_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: __assign({ changeSearchString: function (state, action) {
            state.searchString = action.payload;
        }, changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        }, resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        }, openFilterModal: function (state) {
            state.isFilterModalOpen = true;
        }, closeFilterModal: function (state) {
            state.isFilterModalOpen = false;
        }, removeCategoryId: function (state, action) {
            var _a, _b;
            state.modalFilterValues.category = (_b = (_a = state.modalFilterValues) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return action.payload !== id; });
        }, removePeriod: function (state) {
            state.modalFilterValues.period = null;
        }, removeWalletTypeId: function (state, action) {
            var _a, _b;
            state.modalFilterValues.walletTypes = (_b = (_a = state.modalFilterValues) === null || _a === void 0 ? void 0 : _a.walletTypes) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return action.payload !== id; });
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = operationsFilterServiceState.actions;
export default operationsFilterServiceState.reducer;

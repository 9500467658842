var _a;
import { combineReducers } from 'redux';
import requestsFilterServiceReducer from './filter-service/reducer';
import requestsListServiceReducer from './requests-list-service/reducer';
import requestsAuthorsServiceReducer from './requests-authors-service/reducer';
import supportRequestAuthorsServiceReducer from './support-request-authors-service/reducer';
import requestsExecutorsServiceReducer from './requests-executors-service/reducer';
import supportRequestTypesForFilterServiceReducer from './support-request-types-for-filter-service/reducer';
import { REQUESTS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var requestsServicesRootReducer = combineReducers((_a = {},
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.filter] = requestsFilterServiceReducer,
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.requestsList] = requestsListServiceReducer,
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.requestsAuthors] = requestsAuthorsServiceReducer,
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.supportRequestAuthors] = supportRequestAuthorsServiceReducer,
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.supportRequestsExecutors] = requestsExecutorsServiceReducer,
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.supportRequestTypesForFilter] = supportRequestTypesForFilterServiceReducer,
    _a));

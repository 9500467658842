var _a;
import { combineReducers } from 'redux';
import oneToOneSurveyFormServiceReducer from './one-ton-one-survey-service/reducer';
import oneToOneSettingFormServiceReducer from './one-to-one-setting-form-service/reducer';
import finishModalsServiceReducer from './finish-modal-service/reducer';
import { ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES } from './constants';
export var oneToOneFormRootServicesReducer = combineReducers((_a = {},
    _a[ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES.oneToOneSurveyForm] = oneToOneSurveyFormServiceReducer,
    _a[ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES.oneToOneSettingForm] = oneToOneSettingFormServiceReducer,
    _a[ONE_TO_ONE_FORM_SERVICES_REDUCER_NAMESPACES.finishModal] = finishModalsServiceReducer,
    _a));

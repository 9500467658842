export var VOID_MODAL_FILTER_VALUES = {
    period: null,
    category: [],
    typeSorting: null,
    statusSorting: null,
    createdOn: null,
};
export var REWARDS_DETAILED_TABLE_KEYS = {
    id: 'id',
    productType: 'productType',
    lastOrderDate: 'lastOrderDate',
    ordersCount: 'ordersCount',
    uniqueOrdersCount: 'uniqueOrdersCount',
    companyExpenses: 'companyExpenses',
    indexNumber: 'indexNumber',
    name: 'name',
    createdOn: 'createdOn',
    daysFromCreation: 'daysFromCreation',
    isArchived: 'isArchived',
    categories: 'categories',
    digitalPrice: 'digitalPrice',
    realPrice: 'realPrice',
};

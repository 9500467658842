import * as Yup from 'yup';
import { t } from 'i18next';
export var NEED_COMMENT_SCORE = 3;
export var FeedbackModalFieldsSchema = Yup.object().shape({
    score: Yup.number().required().nullable(),
    commentText: Yup.string().when('score', function (value, schema) {
        return value && value <= NEED_COMMENT_SCORE
            ? schema
                .max(1000, t('common.errorTexts.maxLength', { count: 1000 }))
                .required(t('common.feedbackModal.commentError'))
            : schema;
    }),
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseUserDetailPostReducers, baseUserDetailPostState, } from '../../../services/detail-post-service/user/reducer';
import { DETAIL_GRATITUDE_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseUserDetailPostState), { initialValues: null });
var userDetailGratitudePostServiceState = createSlice({
    name: DETAIL_GRATITUDE_POST_ROOT_SERVICES_REDUCER_NAMESPACES.userDetailGratitudePost,
    initialState: initialState,
    reducers: __assign(__assign({}, baseUserDetailPostReducers), { setData: function (state, action) {
            state.data = action.payload.data;
            state.initialValues = action.payload.initialValues;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, resetState: function () { return initialState; } }),
});
export var actions = userDetailGratitudePostServiceState.actions;
export default userDetailGratitudePostServiceState.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { TRANSACTIONS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    modalFilterValues: {
        category: [],
        walletTypes: [],
        period: null,
    },
    isFilterModalOpen: false,
};
var transactionsFilterServiceState = createSlice({
    name: TRANSACTIONS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        },
        openFilterModal: function (state) {
            state.isFilterModalOpen = true;
        },
        closeFilterModal: function (state) {
            state.isFilterModalOpen = false;
        },
        removeCategoryId: function (state, action) {
            var _a, _b;
            state.modalFilterValues.category = (_b = (_a = state.modalFilterValues) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return action.payload !== id; });
        },
        removePeriod: function (state) {
            state.modalFilterValues.period = null;
        },
        removeWalletTypeId: function (state, action) {
            var _a, _b;
            state.modalFilterValues.walletTypes = (_b = (_a = state.modalFilterValues) === null || _a === void 0 ? void 0 : _a.walletTypes) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return action.payload !== id; });
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = transactionsFilterServiceState.actions;
export default transactionsFilterServiceState.reducer;

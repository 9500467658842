export var parseNumber = function (value) {
    var firstChar = value[0];
    var secondChar = value[1];
    var isStartsWithPoint = false;
    var isNegative = false;
    if (firstChar === '.') {
        isStartsWithPoint = true;
    }
    if (firstChar === '-') {
        isNegative = true;
    }
    if (firstChar === '-' && secondChar === '.') {
        isStartsWithPoint = true;
    }
    var firstPoint = value.indexOf('.');
    var preparedValue = firstPoint !== -1
        ? value.slice(0, firstPoint).replace(/[^\d]/g, '') + '.' + value.slice(firstPoint + 1).replace(/[^\d]/g, '')
        : value.replace(/[^\d]/g, '');
    return "".concat(isNegative ? '-' : '').concat(isStartsWithPoint ? '0' : '').concat(preparedValue);
};

var _a;
import { combineReducers } from 'redux';
import { surveysServicesRootReducer } from '../surveys-page/services/root-reducer';
import { surveyFormServicesRootReducer } from '../modules/survey-form/services/root-reducer';
import editSurveyFormServiceReducer from '../edit-survey-page/service/reducer';
import { surveyResultsRootServicesReducer, } from '../survey-results/survey-results-page/services/root-reducer';
import { eNPSSurveyResultsRootServicesReducer, } from '../survey-results/enps-survey-results-page/services/root-reducer';
import { copyLinkModalRootReducer } from '../modules/copy-link-modal/services/root-reducer';
import { SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES } from './constants';
export var surveysRootServicesReducer = combineReducers((_a = {},
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.surveysListRoot] = surveysServicesRootReducer,
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.surveyForm] = surveyFormServicesRootReducer,
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.editSurveyForm] = editSurveyFormServiceReducer,
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.surveyResultsRoot] = surveyResultsRootServicesReducer,
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.eNPSSurveyResultsRoot] = eNPSSurveyResultsRootServicesReducer,
    _a[SURVEYS_ROOT_SERVICES_REDUCER_NAMESPACES.copyLinkModalRoot] = copyLinkModalRootReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import cs from 'classnames';
import styles from './menu-section-link.module.scss';
var getActiveClassName = function (cb) {
    return function (_a) {
        var _b;
        var isActive = _a.isActive;
        isActive && (cb === null || cb === void 0 ? void 0 : cb());
        return cs(styles.menuSectionLink, (_b = {}, _b[styles.active] = isActive, _b));
    };
};
export var MenuSectionLink = memo(function (_a) {
    var to = _a.to, label = _a.label, Icon = _a.icon, openSection = _a.openSection;
    return (_jsxs(NavLink, __assign({ className: getActiveClassName(openSection), to: to, end: to === '/' || true }, { children: [Icon && _jsx(Icon, { className: styles.icon, width: "1.25rem", height: "1.25rem", viewBox: "0 0 20 20" }), label] })));
});

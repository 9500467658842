var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { SortingActivitiesType } from '../../types';
import { getThirtyDaysInterval } from 'pages/manager-pages/statistics/helpers/get-thirty-days-interval';
import { MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES } from '../constants';
import { VOID_MODAL_FILTER_VALUES } from 'pages/manager-pages/statistics/modular-analytics-activities-page/constants';
export var initialState = {
    searchString: '',
    modalFilterValues: __assign(__assign({}, VOID_MODAL_FILTER_VALUES), { period: getThirtyDaysInterval(), statusSorting: SortingActivitiesType.isActive }),
    isFilterModalOpen: false,
};
var activitiesStatisticsFilterServiceState = createSlice({
    name: MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.activitiesStatisticsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterFieldValue: function (state, action) {
            state.modalFilterValues[action.payload] = null;
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = VOID_MODAL_FILTER_VALUES;
        },
        openFilterModal: function (state) {
            state.isFilterModalOpen = true;
        },
        closeFilterModal: function (state) {
            state.isFilterModalOpen = false;
        },
        removeCategoryId: function (state, action) {
            var _a, _b;
            if (state.modalFilterValues.category) {
                state.modalFilterValues.category = (_b = (_a = state.modalFilterValues) === null || _a === void 0 ? void 0 : _a.category) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return action.payload !== id; });
            }
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = activitiesStatisticsFilterServiceState.actions;
export default activitiesStatisticsFilterServiceState.reducer;

export var AwardType;
(function (AwardType) {
    AwardType["Auto"] = "Auto";
    AwardType["Manual"] = "Manual";
})(AwardType || (AwardType = {}));
export var AwardStatus;
(function (AwardStatus) {
    AwardStatus["Active"] = "Active";
    AwardStatus["Disabled"] = "Disabled";
    AwardStatus["Archived"] = "Archived";
})(AwardStatus || (AwardStatus = {}));

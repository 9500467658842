import { OPERATION_SERVICES_REDUCER_NAMESPACES } from '../profile-page/modules/operations-list/services/constants';
export var USER_SERVICES_REDUCER_NAMESPACES = {
    user: 'user',
    operations: OPERATION_SERVICES_REDUCER_NAMESPACES.operations,
    orders: 'orders',
    userSurveys: 'user-surveys',
    userSupportRequests: 'user-support-requests',
    title: 'title',
    settingsProfile: 'settings-profile',
    myAchievements: 'my-achievements',
};

export var getNormalizedPhone = function (value) {
    var phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length === 11 && phoneNumber[0] !== '7') {
        var firstNumber = Number(phoneNumber[0]) || 1;
        phoneNumber = "".concat(firstNumber - 1) + phoneNumber.slice(1, 11);
    }
    if (phoneNumber.length === 10)
        phoneNumber = '1 ' + phoneNumber.slice(0, 10);
    return phoneNumber.length > 18 ? phoneNumber.slice(0, 18) : phoneNumber;
};
export var getFormattedPhone = function (phone, withNormalize) {
    if (withNormalize === void 0) { withNormalize = true; }
    var normalizedPhone = withNormalize ? getNormalizedPhone(phone) : phone.replace(/[^\d]/g, '');
    return normalizedPhone
        ? "+".concat(normalizedPhone.slice(0, 1)).concat(normalizedPhone.slice(1, 4) ? " ".concat(normalizedPhone.slice(1, 4)) : '').concat(normalizedPhone.slice(4, 7) ? " ".concat(normalizedPhone.slice(4, 7)) : '').concat(normalizedPhone.slice(7, 9) ? "-".concat(normalizedPhone.slice(7, 9)) : '').concat(normalizedPhone.slice(9, 11) ? "-".concat(normalizedPhone.slice(9, 11)) : '').concat(normalizedPhone.slice(11) ? "".concat(normalizedPhone.slice(11)) : '')
        : '';
};

import { useContext, useState, useEffect, useMemo } from 'react';
import { TabsObservableContext } from './tabs-observable-context';
export var useTabsSubscribers = function (_a) {
    var observedTabIndex = _a.observedTabIndex, observeActiveTab = _a.observeActiveTab, observedDisabledTabIndex = _a.observedDisabledTabIndex, observeAllDisabledTabs = _a.observeAllDisabledTabs;
    var _b = useContext(TabsObservableContext), subscribeActiveByIndex = _b.subscribeActiveByIndex, subscribeActiveTab = _b.subscribeActiveTab, setActiveTab = _b.setActiveTab, getActiveTab = _b.getActiveTab, subscribeDisabledTabByIndex = _b.subscribeDisabledTabByIndex, subscribeAllDisabledTabs = _b.subscribeAllDisabledTabs, toggleDisabledTab = _b.toggleDisabledTab, setDisabledTabsHandler = _b.setDisabledTabs, getDisabledTabs = _b.getDisabledTabs;
    var _c = useState(function () { return getActiveTab(); }), activeTabIndex = _c[0], setActiveTabIndex = _c[1];
    var _d = useState(function () { return getDisabledTabs(); }), disabledTabs = _d[0], setDisabledTabs = _d[1];
    useEffect(function () {
        if (observedTabIndex !== undefined) {
            var unSubscribeTabIndex_1 = subscribeActiveByIndex(observedTabIndex, function (activeTab) {
                return setActiveTabIndex(activeTab);
            });
            return function () {
                if (unSubscribeTabIndex_1) {
                    unSubscribeTabIndex_1();
                }
            };
        }
    }, [subscribeActiveByIndex, observedTabIndex]);
    useEffect(function () {
        if (observeActiveTab) {
            var unSubscribeActiveTab_1 = subscribeActiveTab(function (activeTab) { return setActiveTabIndex(activeTab); });
            return function () {
                if (unSubscribeActiveTab_1) {
                    unSubscribeActiveTab_1();
                }
            };
        }
    }, [subscribeActiveTab, observeActiveTab]);
    useEffect(function () {
        if (observedDisabledTabIndex !== undefined) {
            var unSubscribeDisabledTabIndex_1 = subscribeDisabledTabByIndex(observedDisabledTabIndex, function (disabledTabs) { return setDisabledTabs(disabledTabs); });
            return function () {
                if (unSubscribeDisabledTabIndex_1) {
                    unSubscribeDisabledTabIndex_1();
                }
            };
        }
    }, [subscribeDisabledTabByIndex, observedDisabledTabIndex]);
    useEffect(function () {
        if (observeAllDisabledTabs) {
            var unSubscribeDisabledTabIndex_2 = subscribeAllDisabledTabs(function (disabledTabs) {
                return setDisabledTabs(disabledTabs);
            });
            return function () {
                if (unSubscribeDisabledTabIndex_2) {
                    unSubscribeDisabledTabIndex_2();
                }
            };
        }
    }, [subscribeAllDisabledTabs, observeAllDisabledTabs]);
    return useMemo(function () { return ({
        isActiveTab: observedTabIndex === activeTabIndex,
        activeTabIndex: activeTabIndex,
        disabledTabs: disabledTabs,
        setActiveTab: setActiveTab,
        toggleDisabledTab: toggleDisabledTab,
        checkActiveTabStatus: function (index) { return index === activeTabIndex; },
        checkDisabledTabStatus: function (index) { return getDisabledTabs().includes(index); },
        setDisabledTabs: setDisabledTabsHandler,
    }); }, [
        setActiveTab,
        toggleDisabledTab,
        setDisabledTabsHandler,
        getDisabledTabs,
        activeTabIndex,
        disabledTabs,
        observedTabIndex,
    ]);
};

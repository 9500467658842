var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function omit(object, keys) {
    return Object.assign.apply(Object, __spreadArray([{}], Object.keys(object).map(function (key) {
        var _a;
        if (!keys.includes(key)) {
            return _a = {}, _a[key] = object[key], _a;
        }
    }), false));
}

import { useTranslation } from 'react-i18next';
import { FeedbackTriggerType } from 'common/types/server-api-dtos';
export var useTitle = function (feedbackTriggerType) {
    var _a;
    var t = useTranslation().t;
    if (!feedbackTriggerType) {
        return '';
    }
    var TITLES = (_a = {},
        _a[FeedbackTriggerType.ActivityCreated] = t('common.feedbackModal.title.activityCreated'),
        _a[FeedbackTriggerType.ActivityUpdated] = t('common.feedbackModal.title.activityUpdated'),
        _a[FeedbackTriggerType.CurrencyAccrues] = t('common.feedbackModal.title.currencyAccrues'),
        _a[FeedbackTriggerType.OneToOneCommentCreated] = t('common.feedbackModal.title.oneToOneCommentCreated'),
        _a[FeedbackTriggerType.OneToOneCreated] = t('common.feedbackModal.title.oneToOneCreated'),
        _a[FeedbackTriggerType.OneToOneUpdated] = t('common.feedbackModal.title.oneToOneUpdated'),
        _a[FeedbackTriggerType.ProductOrdered] = t('common.feedbackModal.title.productOrdered'),
        _a[FeedbackTriggerType.SupportRequestCreated] = t('common.feedbackModal.title.supportRequestCreated'),
        _a[FeedbackTriggerType.SupportRequestResolved] = t('common.feedbackModal.title.supportRequestResolved'),
        _a[FeedbackTriggerType.SurveyCompleted] = t('common.feedbackModal.title.surveyCompleted'),
        _a[FeedbackTriggerType.SurveyCreated] = t('common.feedbackModal.title.surveyCreated'),
        _a[FeedbackTriggerType.SurveyUpdated] = t('common.feedbackModal.title.surveyUpdated'),
        _a[FeedbackTriggerType.TealRequestCreated] = t('common.feedbackModal.title.tealRequestCreated'),
        _a);
    return TITLES[feedbackTriggerType];
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Logo } from 'common/components/logo';
import { Wallpaper } from 'common/components/wallpaper';
import styles from './registration-layout.module.scss';
export var RegistrationLayout = function () { return (_jsxs("div", __assign({ className: styles.root }, { children: [_jsx(Logo, {}), _jsx("div", __assign({ className: styles.contentWrapper }, { children: _jsx(Suspense, __assign({ fallback: _jsx(Wallpaper, {}) }, { children: _jsx(Outlet, {}) })) }))] }))); };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Field } from 'react-final-form';
import { Box } from 'ui-kit/box';
import { ScoreButton } from '../score-button';
var SCORE_ARR = new Array(5).fill(0).map(function (el, index) { return index + 1; });
export var ScoreSelect = function (_a) {
    var className = _a.className, name = _a.name, rest = __rest(_a, ["className", "name"]);
    return (_jsx(Field, __assign({ name: name }, { children: function (_a) {
            var input = _a.input;
            return (_jsx(Box, __assign({ className: className }, rest, { children: SCORE_ARR.map(function (value, index) { return (_jsx(ScoreButton, { mr: index < SCORE_ARR.length - 1 ? 10 : undefined, isActive: value <= input.value, onActivate: function () { return input.onChange(value); } }, value)); }) })));
        } })));
};

import { createSlice } from '@reduxjs/toolkit';
import { USER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    selectedCategoryId: null,
    publishedOnInterval: null,
};
var userPostsFilterServiceState = createSlice({
    name: USER_POSTS_ROOT_SERVICES_REDUCER_NAMESPACES.postsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        selectCategoryId: function (state, action) {
            state.selectedCategoryId = action.payload;
        },
        changePublishedOnInterval: function (state, action) {
            state.publishedOnInterval = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = userPostsFilterServiceState.actions;
export default userPostsFilterServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cs from 'classnames';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import { CloseIcon } from './icons';
import styles from './drawer-layout.module.scss';
var CLOSE_DRAWER_BUTTON_TESTID = 'closeDrawerButton';
var CloseButton = function (_a) {
    var className = _a.className, onClick = _a.onClick;
    return (_jsx(Icon, { className: cs(styles.closeButton, className), width: 1.25, color: "text", role: "button", component: CloseIcon, tabIndex: 0, dataTestid: CLOSE_DRAWER_BUTTON_TESTID, onClick: function () { return onClick(); } }));
};
export var DrawerLayout = function (_a) {
    var _b, _c;
    var isOpen = _a.isOpen, size = _a.size, title = _a.title, isShowCloseIcon = _a.isShowCloseIcon, dataTestid = _a.dataTestid, className = _a.className, style = _a.style, children = _a.children, side = _a.side, onClose = _a.onClose;
    return (_jsxs(_Fragment, { children: [isShowCloseIcon && (_jsx(CloseButton, { className: cs(styles.closeButtonMobile, (_b = {}, _b[styles.active] = isOpen, _b)), onClick: onClose })), _jsxs("div", __assign({ className: cs(styles.drawerLayout, styles[size !== null && size !== void 0 ? size : 'medium'], className, (_c = {}, _c[styles.active] = isOpen, _c), styles[side]), "data-testid": dataTestid, style: style }, { children: [isShowCloseIcon && (_jsx(CloseButton, { className: cs(styles.closeButtonDesktop, 'drawer-close-icon'), onClick: onClose })), title ? (_jsx(Typography, __assign({ variant: "h4", component: "p", color: "primary", className: cs(styles.title, 'drawer-title') }, { children: title }))) : null, children && _jsx("div", __assign({ className: cs(styles.contentWrapper, 'drawer-content-wrapper') }, { children: children }))] }))] }));
};

import { useEffect, useState } from 'react';
export var useFieldState = function (_a) {
    var key = _a.key, initialValue = _a.initialValue, setSubscriber = _a.setSubscriber;
    var _b = useState(initialValue), value = _b[0], setValue = _b[1];
    useEffect(function () {
        var unSubscribe = setSubscriber(key, function (newValue) {
            setValue(newValue);
        });
        return function () {
            unSubscribe();
        };
    }, [setSubscriber, key]);
    return value;
};

import { createSlice } from '@reduxjs/toolkit';
import { SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from './constants';
export var initialState = {
    data: null,
};
var questionAnswerCommentsServiceState = createSlice({
    name: SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.questionAnswerComments,
    initialState: initialState,
    reducers: {
        setLoading: function (state, action) {
            var _a;
            var _b;
            var _c = action.payload, questionId = _c.questionId, loading = _c.loading;
            if (!((_b = state.data) === null || _b === void 0 ? void 0 : _b[questionId])) {
                var newData = {
                    data: { total: 0, pageData: { page: INITIAL_PAGE, pageSize: INITIAL_PAGE_SIZE }, content: [] },
                    pageSize: INITIAL_PAGE_SIZE,
                    currentPage: INITIAL_PAGE,
                    totalPages: 1,
                    error: null,
                    loading: loading,
                    initialized: false,
                };
                if (state.data) {
                    state.data[questionId] = newData;
                }
                else {
                    state.data = (_a = {}, _a[questionId] = newData, _a);
                }
            }
            else {
                state.data[questionId].loading = true;
            }
        },
        setData: function (state, action) {
            var _a;
            var _b = action.payload, questionId = _b.questionId, data = _b.data;
            if ((_a = state.data) === null || _a === void 0 ? void 0 : _a[questionId]) {
                var total = data.total, pageData = data.pageData;
                state.data[questionId].totalPages = Math.ceil((total || 1) / pageData.pageSize);
                state.data[questionId].data = data;
                state.data[questionId].currentPage = pageData.page;
                state.data[questionId].loading = false;
                if (state.data[questionId].pageSize !== pageData.pageSize)
                    state.data[questionId].pageSize = pageData.pageSize;
                if (!state.data[questionId].initialized)
                    state.data[questionId].initialized = true;
            }
        },
        setError: function (state, action) {
            var _a;
            var _b = action.payload, questionId = _b.questionId, error = _b.error;
            if ((_a = state.data) === null || _a === void 0 ? void 0 : _a[questionId]) {
                if (error && error.canceled) {
                    if (state.data[questionId].error)
                        state.data[questionId].error = null;
                }
                else {
                    state.data[questionId].error = error;
                }
                state.data[questionId].loading = false;
                if (!state.data[questionId].initialized)
                    state.data[questionId].initialized = true;
            }
        },
        resetState: function () { return initialState; },
    },
});
export var actions = questionAnswerCommentsServiceState.actions;
export default questionAnswerCommentsServiceState.reducer;

var _a;
import { combineReducers } from 'redux';
import { requestsServicesRootReducer } from '../support-requests-page/services/root-reducer';
import { requestProcessingServicesRootReducer, } from '../support-request-processing-page/services/root-reducer';
import { REQUESTS_SERVICES_REDUCER_NAMESPACES } from '../support-requests-page/services/constants';
import { REQUEST_PROCESSING_REDUCER_NAMESPACES } from '../support-request-processing-page/services/constants';
export var supportRequestsServicesReducer = combineReducers((_a = {},
    _a[REQUESTS_SERVICES_REDUCER_NAMESPACES.requests] = requestsServicesRootReducer,
    _a[REQUEST_PROCESSING_REDUCER_NAMESPACES.requestProcessing] = requestProcessingServicesRootReducer,
    _a));

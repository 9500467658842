import { createSlice } from '@reduxjs/toolkit';
import { baseUserDetailPostReducers, baseUserDetailPostState, } from '../../../services/detail-post-service/user/reducer';
import { DETAIL_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
var userDetailPostServiceState = createSlice({
    name: DETAIL_POST_ROOT_SERVICES_REDUCER_NAMESPACES.userDetailPost,
    initialState: baseUserDetailPostState,
    reducers: baseUserDetailPostReducers,
});
export var actions = userDetailPostServiceState.actions;
export default userDetailPostServiceState.reducer;

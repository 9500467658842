var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { FileStatus } from '../types';
import { fileModifier, getFiltredItems, getUniqueFiles } from './utils';
var ERROR_TYPE;
(function (ERROR_TYPE) {
    ERROR_TYPE["fileTooLarge"] = "file-too-large";
    ERROR_TYPE["fileTooSmall"] = "file-too-small";
    ERROR_TYPE["fileInvalidType"] = "file-invalid-type";
    ERROR_TYPE["tooManyFiles"] = "too-many-files";
    ERROR_TYPE["uploadError"] = "upload-error";
})(ERROR_TYPE || (ERROR_TYPE = {}));
var getErrorMessage = function (type) {
    if (type === ERROR_TYPE.fileTooLarge)
        return t('uiKit.dropzone.errors.fileTooLarge');
    if (type === ERROR_TYPE.fileTooSmall)
        return t('uiKit.dropzone.errors.fileTooSmall');
    if (type === ERROR_TYPE.fileInvalidType)
        return t('uiKit.dropzone.errors.fileInvalidType');
    if (type === ERROR_TYPE.tooManyFiles)
        return t('uiKit.dropzone.errors.tooManyFiles');
    return t('uiKit.dropzone.errors.uploadError');
};
export var useDropzoneHandler = function (_a) {
    var value = _a.value, maxFiles = _a.maxFiles, maxSize = _a.maxSize, onChange = _a.onChange;
    var _b = useState([]), rejectedFiles = _b[0], setRejectFiles = _b[1];
    var onDrop = useCallback(function (accepted, rejections) {
        var limitCode = ERROR_TYPE.tooManyFiles;
        var isMultiple = maxFiles > 1;
        var newRejectedFiles = [];
        var filtredAcceptedFiles = [];
        accepted.forEach(function (file) {
            if (file.size > maxSize) {
                newRejectedFiles.push(fileModifier(file, getErrorMessage(ERROR_TYPE.fileTooLarge)));
            }
            else {
                filtredAcceptedFiles.push(file);
            }
        });
        var acceptedFiles = isMultiple
            ? getUniqueFiles({ itemsPrev: value, itemsNew: filtredAcceptedFiles })
            : filtredAcceptedFiles.length
                ? [fileModifier(accepted[0])]
                : value[0]
                    ? [fileModifier(value[0])]
                    : [];
        rejections.forEach(function (_a) {
            var errors = _a.errors, file = _a.file;
            var isRejectedByLimit = errors.map(function (e) { return e.code; }).includes(limitCode);
            if (isRejectedByLimit) {
                if (isMultiple && acceptedFiles.length < maxFiles) {
                    var isDublicate = acceptedFiles.find(function (item) { return item.name === file.name; });
                    if (!isDublicate) {
                        acceptedFiles.push(fileModifier(file));
                    }
                }
                else {
                    newRejectedFiles.push(fileModifier(file, getErrorMessage(limitCode)));
                }
            }
            else {
                newRejectedFiles.push(fileModifier(file, getErrorMessage(errors[0].code)));
            }
        });
        var filtredRejectedFiles = getFiltredItems(acceptedFiles, newRejectedFiles);
        if (isMultiple && acceptedFiles.length > maxFiles) {
            var currentAcceptedFiles = acceptedFiles.splice(0, maxFiles);
            onChange(currentAcceptedFiles);
            setRejectFiles(__spreadArray(__spreadArray([], filtredRejectedFiles, true), acceptedFiles.map(function (item) { return fileModifier(item, getErrorMessage(limitCode)); }), true));
        }
        else {
            onChange(acceptedFiles);
            setRejectFiles(filtredRejectedFiles);
        }
    }, [onChange, maxFiles, value, maxSize]);
    var handleRemoveItem = useCallback(function (name) { return function () {
        var _a = rejectedFiles.filter(function (item) { return item.message === getErrorMessage(ERROR_TYPE.tooManyFiles); }), firstFileExcludedByLimit = _a[0], rest = _a.slice(1);
        var curretnValue = value.filter(function (item) { return item.name !== name; });
        if (firstFileExcludedByLimit) {
            firstFileExcludedByLimit.message = undefined;
            firstFileExcludedByLimit.status = FileStatus.accepted;
            curretnValue.push(firstFileExcludedByLimit);
        }
        onChange(curretnValue);
        setRejectFiles(rest);
    }; }, [onChange, setRejectFiles, value, rejectedFiles]);
    return { rejectedFiles: rejectedFiles, onDrop: onDrop, removeItem: handleRemoveItem };
};

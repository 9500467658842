var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { sidebarServiceIsOpenMenuSelector, sidebarServiceIsPinnedMenuSelector, } from 'common/layouts/main-layout/service/sidebar-service/selectors';
import { Paper } from 'ui-kit/paper';
import { Navigation } from '../../../navigation';
import { Footer, Header } from './modules';
import styles from './menu.module.scss';
export var Menu = function () {
    var _a;
    var isPinned = useSelector(sidebarServiceIsPinnedMenuSelector);
    var isOpen = useSelector(sidebarServiceIsOpenMenuSelector);
    return (_jsx("div", __assign({ className: cs(styles.menuWrapper, (_a = {},
            _a[styles.active] = isOpen && !isPinned,
            _a[styles.pinned] = isPinned,
            _a)) }, { children: _jsxs(Paper, __assign({ className: styles.menu }, { children: [_jsx(Header, { isPinned: isPinned }), _jsx(Navigation, { className: cs(styles.navigationContainer) }), _jsx(Footer, {})] })) })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { EMPLOYEES_LIST_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign({ searchString: '', sortType: undefined, activityType: null, filterFeatureKeys: [], modalFilterValues: {
        tagIds: [],
        positionIds: [],
        birthDateIntervalVariant: null,
        date: null,
        month: null,
        hireDatesInterval: null,
        fireDatesInterval: null,
        probationPeriodEndDatesInterval: null,
        peopleManagerDelegateIds: [],
        peopleManagerIds: [],
        supervisorIds: [],
    }, isFilterModalOpen: false }, baseStateProperties);
var employeesFilterServiceState = createSlice({
    name: EMPLOYEES_LIST_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: __assign({ setFilterFeatureKeys: function (state, action) {
            state.filterFeatureKeys = action.payload;
            state.loading = false;
            state.initialized = true;
        }, changeSearchString: function (state, action) {
            state.searchString = action.payload;
        }, changeActivityType: function (state, action) {
            state.activityType = action.payload;
        }, setSortType: function (state, action) {
            state.sortType = action.payload;
        }, openFilterModal: function (state) {
            state.isFilterModalOpen = true;
        }, closeFilterModal: function (state) {
            state.isFilterModalOpen = false;
        }, changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        }, resetModalFilterField: function (state, action) {
            var key = action.payload;
            if (key === 'birthDateIntervalVariant') {
                state.modalFilterValues[key] = null;
                state.modalFilterValues.date = null;
                state.modalFilterValues.month = null;
            }
            else if (key === 'hireDatesInterval') {
                state.modalFilterValues[key] = null;
            }
            else if (key === 'fireDatesInterval') {
                state.modalFilterValues[key] = null;
            }
            else if (key === 'probationPeriodEndDatesInterval') {
                state.modalFilterValues[key] = null;
            }
            else {
                state.modalFilterValues[key] = [];
            }
        }, removeTagId: function (state, action) {
            state.modalFilterValues.tagIds = state.modalFilterValues.tagIds.filter(function (id) { return action.payload !== id; });
        }, removePositionId: function (state, action) {
            state.modalFilterValues.positionIds = state.modalFilterValues.positionIds.filter(function (id) { return action.payload !== id; });
        }, removeSupervisorIds: function (state, action) {
            state.modalFilterValues.supervisorIds = state.modalFilterValues.supervisorIds.filter(function (id) { return action.payload !== id; });
        }, removePeopleManagerIds: function (state, action) {
            state.modalFilterValues.peopleManagerIds = state.modalFilterValues.peopleManagerIds.filter(function (id) { return action.payload !== id; });
        }, removePeopleManagerDelegateIds: function (state, action) {
            state.modalFilterValues.peopleManagerDelegateIds = state.modalFilterValues.peopleManagerDelegateIds.filter(function (id) { return action.payload !== id; });
        }, resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        }, resetFilter: function () { return initialState; } }, baseReducers),
});
export var actions = employeesFilterServiceState.actions;
export default employeesFilterServiceState.reducer;

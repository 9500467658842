import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
export var ScrollUp = function () {
    var location = useLocation();
    var pathName = location.pathname;
    useLayoutEffect(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathName]);
    return null;
};

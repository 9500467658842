export var FILE_END_POINT = process.env.FILE_END_POINT;
export var PATTERN_STR = '#pattern#';
export var TAKE_SURVEY_LINK_FOR_GUEST = '/take-survey/link-access';
export var LOGIN_URL = process.env.LOGIN_URL;
export var REALM = process.env.REALM;
export var LOCALIZATION_URL = process.env.LOCALIZATION_URL;
export var OPEN_SUPPORT_RUQUEST_FORM_HASH_KEY = '#open_support_request';
export var AXIOS_URL = process.env.AXIOS_URL;
export var SIGNALR_URL = process.env.SIGNALR_URL;
export var STAND = process.env.AXIOS_URL.includes('dev')
    ? 'dev'
    : process.env.AXIOS_URL.includes('stage')
        ? 'stage'
        : 'production';
export var HELP_EMAIL = 'help@teal-hr.pro';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useRef, useCallback, useMemo, useLayoutEffect } from 'react';
import cs from 'classnames';
import { Portal } from 'ui-kit/portal';
import styles from './tooltip.module.scss';
var TooltipTypeMap = {
    success: styles.success,
    warning: styles.warning,
    error: styles.error,
};
var getPercentByWidth = function (value) { return (value * 100) / window.innerWidth; };
var getPercentByHeight = function (value) { return (value * 100) / window.innerHeight; };
var currentPlacement = {
    current: 'top',
    negate: function () {
        if (this.current === 'left')
            return 'right';
        if (this.current === 'right')
            return 'left';
        if (this.current === 'bottom')
            return 'top';
        return 'bottom';
    },
    isHorizontal: function () {
        return this.current === 'left' || this.current === 'right';
    },
    isVertical: function () {
        return this.current === 'top' || this.current === 'bottom';
    },
    getCurrent: function () {
        return this;
    },
    setCurrent: function (p) {
        this.current = p;
        return this;
    },
};
var tooltipPosition = function () { return ({
    x: 0,
    y: 0,
    iX: 0,
    iY: 0,
    reset: function (p) {
        this.x = p.x;
        this.y = p.y;
        this.iX = p.iX;
        this.iY = p.iY;
    },
    restrict: function (rect) {
        if (this.x < rect.l)
            this.x = rect.l;
        else if (this.x > rect.r)
            this.x = rect.r;
        if (this.y < rect.t)
            this.y = rect.t;
        else if (this.y > rect.b)
            this.y = rect.b;
    },
}); };
var getTooltipPosition = function (_a) {
    var el = _a.el, tt = _a.tt, placement = _a.placement, space = _a.space, clientX = _a.clientX;
    var recurCount = 0;
    var pt = tooltipPosition();
    var ttOffsetWidth = tt.offsetWidth;
    var ttOffsetHeight = tt.offsetHeight;
    var _b = el.getBoundingClientRect(), left = _b.left, top = _b.top, right = _b.right, bottom = _b.bottom, height = _b.height, width = _b.width;
    var pTtByRelativeEl = {
        l: getPercentByWidth(space),
        t: getPercentByHeight(space),
        r: getPercentByWidth(document.body.clientWidth - tt.clientWidth + space * 2),
        b: getPercentByHeight(window.innerHeight - tt.clientHeight + space * 2),
    };
    return (function recursive(placement) {
        recurCount++;
        var pos = currentPlacement.setCurrent(placement);
        switch (pos.current) {
            case 'left':
                pt.x = getPercentByWidth(left - ttOffsetWidth - space);
                pt.y = getPercentByHeight(top + height / 2 - ttOffsetHeight / 2);
                break;
            case 'right':
                pt.x = getPercentByWidth(right + space * 1.5);
                pt.y = getPercentByHeight(top + height / 2 - ttOffsetHeight / 2);
                break;
            case 'top':
                pt.x = getPercentByWidth(clientX - ttOffsetWidth + ttOffsetWidth / 4);
                pt.y = getPercentByHeight(top - ttOffsetHeight - space);
                pt.iX = getPercentByWidth(clientX > left + width ? left : clientX);
                pt.iY = getPercentByHeight(top - space);
                break;
            default:
                pt.x = getPercentByWidth(clientX - ttOffsetWidth + ttOffsetWidth / 4);
                pt.y = getPercentByHeight(bottom + space);
                pt.iX = getPercentByWidth(clientX > left + width ? left : clientX);
                pt.iY = getPercentByHeight(bottom + space);
        }
        if (recurCount < 3) {
            if ((pos.isHorizontal() && (pt.x < pTtByRelativeEl.l || pt.x > pTtByRelativeEl.r)) ||
                (pos.isVertical() && (pt.y < pTtByRelativeEl.t || pt.y > pTtByRelativeEl.b))) {
                pt.reset(recursive(pos.negate()));
            }
            pt.restrict(pTtByRelativeEl);
        }
        return pt;
    })(placement);
};
var indicatorClassesMap = {
    left: styles.left,
    right: styles.right,
    top: styles.top,
    bottom: styles.bottom,
};
export var Tooltip = function (_a) {
    var content = _a.content, _b = _a.placement, placement = _b === void 0 ? 'top' : _b, _c = _a.space, space = _c === void 0 ? 12 : _c, type = _a.type, disabled = _a.disabled, className = _a.className, children = _a.children, rest = __rest(_a, ["content", "placement", "space", "type", "disabled", "className", "children"]);
    var _d = useState(false), show = _d[0], setShow = _d[1];
    var posRef = useRef({ x: 0, y: 0, iX: 0, iY: 0 });
    var clientX = useRef(0);
    var tooltipRef = useRef(null);
    var tooltipCurrentPlacement = currentPlacement.getCurrent();
    var inlineStyles = useMemo(function () {
        return {
            tooltip: {
                top: "".concat(posRef.current.y, "%"),
                left: "".concat(posRef.current.x, "%"),
                opacity: show ? 1 : 0,
                zIndex: show ? 1001 : -10,
            },
            indicator: tooltipCurrentPlacement.isVertical()
                ? {
                    left: "".concat(posRef.current.iX, "%"),
                    top: "".concat(posRef.current.iY, "%"),
                }
                : {},
        };
    }, [show, tooltipCurrentPlacement]);
    var handleHoverOff = useCallback(function () { return setShow(false); }, []);
    useLayoutEffect(function () {
        window.addEventListener('scroll', handleHoverOff);
        return function () {
            window.removeEventListener('scroll', handleHoverOff);
        };
    }, [handleHoverOff, show]);
    var handleHoverOn = useCallback(function (e) {
        if (tooltipRef.current) {
            setShow(true);
            posRef.current = getTooltipPosition({
                el: e.currentTarget,
                tt: tooltipRef.current,
                placement: placement,
                space: space,
                clientX: e.clientX,
            });
            clientX.current = e.clientX;
        }
    }, [placement, space]);
    return (_jsxs(_Fragment, { children: [disabled
                ? children
                : React.cloneElement(children, {
                    onMouseEnter: handleHoverOn,
                    onMouseLeave: handleHoverOff,
                    style: { cursor: 'pointer' },
                }), !disabled && (_jsx(Portal, { children: _jsxs("div", __assign({ className: cs(styles.tooltip, className, indicatorClassesMap[tooltipCurrentPlacement.current], type && TooltipTypeMap[type]), ref: tooltipRef }, rest, { style: inlineStyles.tooltip }, { children: [_jsx("span", { className: styles.indicator, style: inlineStyles.indicator }), content] })) }))] }));
};

var _a;
import { combineReducers } from 'redux';
import { awardsPageServicesRootReducer } from '../awards-page/services/root-reducer';
import editAwardStatusFormServiceReducer from '../modules/edit-award-status-modal-form/service/reducer';
import detailAwardServiceReducer from '../detail-award-page/service/reducer';
import { AWARDS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var awardsServicesRootReducer = combineReducers((_a = {},
    _a[AWARDS_SERVICES_REDUCER_NAMESPACES.awardsPageRoot] = awardsPageServicesRootReducer,
    _a[AWARDS_SERVICES_REDUCER_NAMESPACES.editAwardStatusForm] = editAwardStatusFormServiceReducer,
    _a[AWARDS_SERVICES_REDUCER_NAMESPACES.detailAwardPage] = detailAwardServiceReducer,
    _a));

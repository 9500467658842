var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { WalletType } from 'common/types/server-api-dtos';
import { CurrencyIcon } from 'common/components/currency-icon';
import { ThanksCurrencyIcon } from 'common/components/thanks-currency-icon';
export var VOID_MODAL_FILTER_VALUES = {
    period: null,
    category: [],
    status: [],
};
export var GRATITUDES_DETAILED_TABLE_KEYS = {
    indexNumber: 'indexNumber',
    mainTransactionId: 'mainTransactionId',
    fullName: 'fullName',
    category: 'category',
    descriptionData: 'descriptionData',
    walletType: 'walletType',
    amountData: 'amountData',
    transactionDate: 'transactionDate',
    transactionStatusData: 'transactionStatusData',
    cancellationData: 'cancellationData',
};
export var WALLET_ICON_MAP = (_a = {},
    _a[WalletType.Teal] = _jsx(CurrencyIcon, { width: 1 }),
    _a[WalletType.Thank] = _jsx(ThanksCurrencyIcon, { width: 1 }),
    _a);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { SHARED_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        licensesCount: 10,
        promotionCodeId: null,
        tariffs: {
            total: 0,
            pageData: {
                page: 0,
                pageSize: 0,
            },
            content: [],
        },
    } });
var tariffsServiceState = createSlice({
    name: SHARED_SERVICES_REDUCER_NAMESPACES.tariffs,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        } }, baseListReducer),
});
export var actions = tariffsServiceState.actions;
export default tariffsServiceState.reducer;

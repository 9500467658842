import { createSlice } from '@reduxjs/toolkit';
import { baseManagerDetailPostInitialState, baseManagerDetailPostReducers, } from '../../../services/detail-post-service/manager/reducer';
import { DETAIL_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
var managerDetailPostServiceState = createSlice({
    name: DETAIL_POST_ROOT_SERVICES_REDUCER_NAMESPACES.managerDetailPost,
    initialState: baseManagerDetailPostInitialState,
    reducers: baseManagerDetailPostReducers,
});
export var actions = managerDetailPostServiceState.actions;
export default managerDetailPostServiceState.reducer;

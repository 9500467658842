var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from 'ui-kit/typography';
import { checkIsRtlDirection } from 'common/helpers/check-is-rtl-direction';
export var Counter = function (_a) {
    var maxCount = _a.maxCount, currentCount = _a.currentCount, label = _a.label, className = _a.className;
    var isRtlDirection = checkIsRtlDirection();
    return (_jsx(Typography, __assign({ className: className, color: currentCount > maxCount ? 'error' : 'primary', variant: "body-xs", style: { marginInlineStart: 'auto' } }, { children: isRtlDirection
            ? "".concat(label ? "".concat(label, " ") : '').concat(maxCount, "/").concat(currentCount, " ")
            : "".concat(currentCount, "/").concat(maxCount).concat(label ? " ".concat(label) : '') })));
};

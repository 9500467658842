var _a;
import { combineReducers } from 'redux';
import sharedToastServiceReducer from './toast-service/reducer';
import sharedProfileServiceReducer from './profile-service/reducer';
import companyAdditionalInfoServiceReducer from './company-additional-info-service/reducer';
import customizationsServiceReducer from './customizations-service/reducer';
import supportRequestTypesServiceReducer from './support-request-types-service/reducer';
import conversionServiceReducer from './conversion-service/reducer';
import showThanksBalanceServiceReducer from './show-thanks-balance-service/reducer';
import { APP_SERVICES_REDUCER_NAMESPACES } from './constants';
export var appServicesRootReducer = combineReducers((_a = {},
    _a[APP_SERVICES_REDUCER_NAMESPACES.profile] = sharedProfileServiceReducer,
    _a[APP_SERVICES_REDUCER_NAMESPACES.toast] = sharedToastServiceReducer,
    _a[APP_SERVICES_REDUCER_NAMESPACES.customizations] = customizationsServiceReducer,
    _a[APP_SERVICES_REDUCER_NAMESPACES.supportRequestTypes] = supportRequestTypesServiceReducer,
    _a[APP_SERVICES_REDUCER_NAMESPACES.companyAdditionalInfo] = companyAdditionalInfoServiceReducer,
    _a[APP_SERVICES_REDUCER_NAMESPACES.conversion] = conversionServiceReducer,
    _a[APP_SERVICES_REDUCER_NAMESPACES.showThanksBalance] = showThanksBalanceServiceReducer,
    _a));

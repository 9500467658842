import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from 'ui-kit/icon';
import { ArrowSquareDownIcon } from '../icons';
import { useFieldState } from 'ui-kit/controllers/selects/hooks/use-field-state';
var ROTATE_STYLE = { transform: 'rotate(-180deg)' };
export var SelectIcon = function (_a) {
    var setSubscriber = _a.setSubscriber;
    var isActive = useFieldState({ key: 'isActive', initialValue: false, setSubscriber: setSubscriber });
    return _jsx(Icon, { component: ArrowSquareDownIcon, style: isActive ? ROTATE_STYLE : undefined });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import cs from 'classnames';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { Paper } from 'ui-kit/paper';
import { Icon } from 'ui-kit/icon';
import { ArrowRightIcon } from 'common/layouts/icons';
import { MenuSectionLink } from '../menu-section-link';
import styles from './menu-section.module.scss';
var MenuSectionWrapper = function (_a) {
    var hasWrapper = _a.hasWrapper, children = _a.children;
    if (!hasWrapper)
        return _jsx(_Fragment, { children: children });
    return (_jsx(Paper, __assign({ className: styles.menuSectionWrapper, mb: 4, pt: 4 }, { children: children })));
};
export var MenuSection = function (_a) {
    var _b, _c;
    var hasWrapper = _a.hasWrapper, data = _a.data;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var initialized = useRef(false);
    var openMenuHandler = function () {
        setIsOpen(!isOpen);
    };
    var openSection = useCallback(function () {
        if (!initialized.current) {
            setTimeout(function () {
                setIsOpen(true);
            }, 0);
            initialized.current = true;
        }
    }, []);
    var links = data.links.map(function (_a) {
        var to = _a.to, label = _a.label, icon = _a.icon;
        return (_jsx(MenuSectionLink, { to: to, label: label, icon: icon, openSection: openSection }, to));
    });
    return (_jsxs(MenuSectionWrapper, __assign({ hasWrapper: hasWrapper }, { children: [_jsxs(Box, __assign({ className: styles.toggleOpenSectionButton, px: 8, py: 4, alignItems: "center", onClick: openMenuHandler }, { children: [_jsx(Typography, __assign({ variant: "body-s", weight: "semi-bold", color: "main", component: "span" }, { children: data.title })), _jsx(Icon, { className: cs(styles.icon, (_b = {}, _b[styles.active] = isOpen, _b)), width: 1, viewBox: "0 0 16 16", component: ArrowRightIcon })] })), _jsx("div", __assign({ className: cs(styles.navSection, (_c = {}, _c[styles.open] = isOpen, _c)) }, { children: links }))] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Field } from 'react-final-form';
import { TextArea } from 'ui-kit/controllers/text-area';
export var TextAreaField = memo(function (_a) {
    var name = _a.name, disabled = _a.disabled, rest = __rest(_a, ["name", "disabled"]);
    return (_jsx(Field, { name: name, component: function (_a) {
            var input = _a.input, meta = _a.meta;
            return (_jsx(TextArea, __assign({}, rest, { isError: !disabled && meta.touched && !!meta.error, helperText: meta.touched && meta.error ? meta.error : undefined, name: input.name, value: input.value || '', onBlur: input.onBlur, onChange: input.onChange, disabled: disabled })));
        } }));
});

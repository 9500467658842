var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { InfoBlock } from 'ui-kit/info-block';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { HELP_EMAIL } from 'common/constants/common';
import { DEV_REQUEST_ERR_KEY } from '../../constants';
export var ErrorObserver = function (props) {
    var t = useTranslation().t;
    var form = useForm();
    var submitErrors = useFormState().submitErrors;
    var isTypeIdChangedLastSubmit = useField('typeId', { subscription: { dirtySinceLastSubmit: true } }).meta.dirtySinceLastSubmit;
    useEffect(function () {
        if (isTypeIdChangedLastSubmit && (submitErrors === null || submitErrors === void 0 ? void 0 : submitErrors[DEV_REQUEST_ERR_KEY])) {
            delete submitErrors[DEV_REQUEST_ERR_KEY];
            form.blur(DEV_REQUEST_ERR_KEY);
            form.focus('typeId');
        }
    }, [isTypeIdChangedLastSubmit, submitErrors, form]);
    return (submitErrors === null || submitErrors === void 0 ? void 0 : submitErrors[DEV_REQUEST_ERR_KEY]) ? (_jsx(Box, __assign({}, props, { children: _jsx(InfoBlock, { isFullWidth: true, type: "warning", text: _jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "body-s", color: "warning", component: "span" }, { children: t('common.createSupportRequestForm.devRequestError') })), _jsx(Typography, __assign({ variant: "body-s", color: "warning", component: "a", href: "mailto:".concat(HELP_EMAIL) }, { children: HELP_EMAIL }))] }) }) }))) : null;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { ORDERS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, pageSize: 15, editableOrderData: null, loading: true });
var ordersServiceState = createSlice({
    name: ORDERS_SERVICES_REDUCER_NAMESPACES.ordersList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, setEditableOrderDataById: function (state, action) {
            var _a, _b;
            var order = state.data.content.find(function (item) { return item.id === action.payload; });
            if (order) {
                state.editableOrderData = {
                    id: order.id,
                    orderStatus: order.orderStatus,
                    cancellationReason: order.cancellationReason,
                    courierCompanyId: (_b = (_a = order.courierCompany) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
                    trackNumber: order.trackNumber,
                };
            }
        }, removeEditableOrderData: function (state) {
            state.editableOrderData = null;
        }, changeOrder: function (state, action) {
            state.data.content = state.data.content.map(function (item) {
                return item.id === action.payload.id ? __assign(__assign(__assign({}, item), action.payload), { courierCompany: null }) : item;
            });
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = ordersServiceState.actions;
export default ordersServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { baseStateProperties } from 'common/constants/redux-base-state';
import { baseReducers } from 'common/utils/base-reduсer';
export var baseManagerDetailPostInitialState = __assign(__assign({}, baseStateProperties), { data: null });
export var baseManagerDetailPostReducers = __assign({ setData: function (state, action) {
        state.data = action.payload;
        state.loading = false;
        if (!state.initialized)
            state.initialized = true;
    }, editPostLikesCount: function (state, action) {
        var _a = action.payload, reactionsCount = _a.reactionsCount, postIsLiked = _a.postIsLiked;
        if (state.data) {
            state.data = __assign(__assign({}, state.data), { reactionsCount: reactionsCount, currentUserReacted: postIsLiked });
        }
    }, editPostViewsCount: function (state, action) {
        var viewsCount = action.payload.viewsCount;
        if (state.data) {
            state.data = __assign(__assign({}, state.data), { viewsCount: viewsCount });
        }
    }, editPostCommentsCount: function (state, action) {
        if (state.data) {
            state.data = __assign(__assign({}, state.data), { commentsCount: action.payload.commentsCount });
        }
    }, editDetailPost: function (state, action) {
        state.data = action.payload;
        state.loading = false;
    }, findAndEditPost: function (state, action) {
        var _a;
        if (action.payload.id === ((_a = state.data) === null || _a === void 0 ? void 0 : _a.id)) {
            state.data = action.payload;
        }
    }, resetState: function () { return baseManagerDetailPostInitialState; } }, baseReducers);

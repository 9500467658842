var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { REQUESTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { loading: true, pageSize: 10, data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 0,
        },
        content: [],
    }, movableRequestData: null });
var requestsListServiceState = createSlice({
    name: REQUESTS_SERVICES_REDUCER_NAMESPACES.requestsList,
    initialState: initialState,
    reducers: __assign({ setDndBoardData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized)
                state.initialized = true;
        }, setArchivedBoardData: function (state, action) {
            var _a = action.payload, total = _a.total, pageData = _a.pageData;
            state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            state.data = action.payload;
            state.currentPage = pageData.page;
            state.loading = false;
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, moveRequestCard: function (state, action) {
            if (action.payload.isArchived) {
                state.data.content = state.data.content.filter(function (_a) {
                    var id = _a.id;
                    return id !== action.payload.id;
                });
            }
            else {
                var isNewItem_1 = true;
                var newData_1 = [];
                state.data.content.forEach(function (item) {
                    if (item.id === action.payload.id) {
                        newData_1.push(action.payload);
                        isNewItem_1 = false;
                    }
                    else {
                        newData_1.push(item);
                    }
                });
                if (isNewItem_1)
                    newData_1.push(action.payload);
                var preparedData = newData_1.sort(function (a, b) { return a.kanbanCardWeight - b.kanbanCardWeight; });
                state.data.content = preparedData;
            }
            state.loading = false;
        }, setMovableRequestData: function (state, action) {
            if (state.initialized) {
                state.movableRequestData = action.payload;
            }
        }, removeMovableRequestData: function (state) {
            state.movableRequestData = null;
        }, resetRequestsData: function (state) {
            state.data = initialState.data;
        }, resetState: function () { return initialState; } }, baseListReducer),
});
export var actions = requestsListServiceState.actions;
export default requestsListServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { fetchNotificationsApi, readNotificationApi } from './api';
import { DeviceType } from 'common/types/server-api-dtos';
import { actions } from './reducer';
export var fetchNotificationsAction = function (_a) {
    var _b = _a.deviceType, deviceType = _b === void 0 ? DeviceType.Web : _b, successCb = _a.successCb, params = __rest(_a, ["deviceType", "successCb"]);
    return function (dispatch) {
        dispatch(actions.setLoading(true));
        return fetchNotificationsApi(__assign({ deviceType: deviceType }, params))
            .then(function (data) {
            dispatch(actions.setData(data));
            successCb === null || successCb === void 0 ? void 0 : successCb();
        })
            .catch(function (error) {
            console.log('fetchNotificationsAction ->', error);
            dispatch(actions.setError(error));
        });
    };
};
export var readNotificationAction = function (_a) {
    var pushId = _a.pushId, _b = _a.deviceType, deviceType = _b === void 0 ? DeviceType.Web : _b, successCb = _a.successCb, isRead = _a.isRead;
    return function (dispatch) {
        return readNotificationApi({ pushId: pushId, deviceType: deviceType, isRead: isRead })
            .then(function () {
            dispatch(actions.setElementRead(pushId));
            successCb === null || successCb === void 0 ? void 0 : successCb();
        })
            .catch(function (error) {
            console.log('readNotificationAction ->', error);
            dispatch(actions.setError(error));
        });
    };
};

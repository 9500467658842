var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { Field } from 'react-final-form';
import { Dropzone } from 'ui-kit/controllers/dropzone';
export var DropzoneField = memo(function (_a) {
    var name = _a.name, disabled = _a.disabled, helperText = _a.helperText, rest = __rest(_a, ["name", "disabled", "helperText"]);
    var handleChange = useCallback(function (onChange, onBlur) { return function (data) {
        onChange(data);
        onBlur();
    }; }, []);
    return (_jsx(Field, __assign({ name: name }, rest, { children: function (_a) {
            var input = _a.input, meta = _a.meta;
            var value = input.value || [];
            var errorText = meta.error || helperText;
            return (_jsx(Dropzone, __assign({}, rest, { isError: meta.touched && errorText, helperText: meta.touched && errorText, name: input.name, value: value, onChange: handleChange(input.onChange, input.onBlur), disabled: disabled })));
        } })));
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { useSidebarActions } from '../../../../../../service/sidebar-service/hooks';
import { Box } from 'ui-kit/box';
import { Icon } from 'ui-kit/icon';
import { AngleDoubleLeftIcon, PushPinIcon } from 'common/layouts/icons';
import { Logo } from 'common/components/logo';
import styles from './header.module.scss';
export var Header = memo(function (_a) {
    var isPinned = _a.isPinned, rest = __rest(_a, ["isPinned"]);
    var toggleIsPinnedMenu = useSidebarActions().toggleIsPinnedMenu;
    return (_jsxs(Box, __assign({ className: styles.header, px: 8, py: 2, alignItems: "center" }, rest, { children: [_jsx(NavLink, __assign({ className: styles.link, to: "/" }, { children: _jsx(Logo, { size: "small" }) })), _jsx(Icon, { className: styles.togglePinButton, component: isPinned ? AngleDoubleLeftIcon : PushPinIcon, onClick: function () {
                    toggleIsPinnedMenu();
                } })] })));
});

import { createSlice } from '@reduxjs/toolkit';
import { SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {};
var questionAnswerCommentsFilterServiceState = createSlice({
    name: SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.questionAnswerCommentsFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            var _a = action.payload, questionId = _a.questionId, searchString = _a.searchString;
            if (state[questionId]) {
                state[questionId].searchString = searchString;
            }
            state[questionId] = { searchString: searchString };
        },
        resetState: function () { return initialState; },
    },
});
export var actions = questionAnswerCommentsFilterServiceState.actions;
export default questionAnswerCommentsFilterServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { POSTS_COMMENTS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var initialState = {};
export var INITIAL_PAGE = 0;
export var INITIAL_PAGE_SIZE = 3;
var postsCommentsServiceState = createSlice({
    name: POSTS_COMMENTS_SERVICES_REDUCER_NAMESPACES.postsComments,
    initialState: initialState,
    reducers: {
        setLoading: function (state, action) {
            var _a = action.payload, postId = _a.postId, loading = _a.loading;
            if (!state[postId]) {
                var newData = {
                    data: { total: 0, pageData: { page: INITIAL_PAGE, pageSize: INITIAL_PAGE_SIZE }, content: [] },
                    pageSize: INITIAL_PAGE_SIZE,
                    currentPage: INITIAL_PAGE,
                    totalPages: 1,
                    error: null,
                    loading: loading,
                    initialized: false,
                };
                state[postId] = newData;
            }
            else {
                state[postId].loading = loading;
            }
        },
        setData: function (state, action) {
            var _a = action.payload, postId = _a.postId, data = _a.data;
            if (state[postId]) {
                var total = data.total, pageData = data.pageData, content = data.content;
                state[postId].data.total = total;
                state[postId].data.pageData = pageData;
                state[postId].data.content = pageData.page > 0 ? __spreadArray(__spreadArray([], state[postId].data.content, true), content, true) : __spreadArray([], content, true);
                state[postId].totalPages = Math.ceil((total || 1) / pageData.pageSize);
                state[postId].currentPage = pageData.page;
                state[postId].loading = false;
                if (state[postId].pageSize !== pageData.pageSize)
                    state[postId].pageSize = pageData.pageSize;
                if (!state[postId].initialized)
                    state[postId].initialized = true;
            }
        },
        setError: function (state, action) {
            var _a = action.payload, postId = _a.postId, error = _a.error;
            if (state[postId]) {
                state[postId].loading = false;
                if (error && error.canceled) {
                    if (state[postId].error)
                        state[postId].error = null;
                }
                else {
                    state[postId].error = error;
                }
                if (!state[postId].initialized)
                    state[postId].initialized = true;
            }
        },
        editCommentLikesCount: function (state, action) {
            var _a = action.payload, postId = _a.postId, commentId = _a.commentId, data = _a.data;
            if (state[postId]) {
                state[postId].data.content = state[postId].data.content.map(function (item) {
                    return item.id === commentId
                        ? __assign(__assign({}, item), { reactionsCount: data.reactionsCount, currentUserReacted: data.postIsLiked }) : item;
                });
            }
        },
        editCommentState: function (state, action) {
            var _a = action.payload, postId = _a.postId, data = _a.data;
            if (state[postId]) {
                state[postId].data.content = state[postId].data.content.map(function (item) { return (item.id === data.id ? data : item); });
                state[postId].loading = false;
            }
        },
        addCommentState: function (state, action) {
            var _a = action.payload, postId = _a.postId, data = _a.data;
            if (state[postId]) {
                state[postId].data.content = __spreadArray([data], state[postId].data.content, true);
                state[postId].data.total = state[postId].data.total + 1;
                state[postId].loading = false;
            }
        },
        deleteComment: function (state, action) {
            var _a = action.payload, postId = _a.postId, commentId = _a.commentId;
            if (state[postId]) {
                state[postId].data.content = state[postId].data.content.filter(function (_a) {
                    var id = _a.id;
                    return id !== commentId;
                });
                state[postId].data.total = state[postId].data.total - 1;
            }
        },
        resetPostCommentsState: function (state, action) {
            if (state === null || state === void 0 ? void 0 : state[action.payload]) {
                var _a = state, _b = action.payload, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                state = rest;
            }
        },
    },
});
export var actions = postsCommentsServiceState.actions;
export default postsCommentsServiceState.reducer;

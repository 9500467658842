export var TransactionType;
(function (TransactionType) {
    TransactionType["Accrual"] = "Accrual";
    TransactionType["WriteOff"] = "WriteOff";
})(TransactionType || (TransactionType = {}));
export var WalletType;
(function (WalletType) {
    WalletType["Teal"] = "Teal";
    WalletType["Thank"] = "Thank";
})(WalletType || (WalletType = {}));
export var TransactionCategoryType;
(function (TransactionCategoryType) {
    TransactionCategoryType["Activity"] = "Activity";
    TransactionCategoryType["Order"] = "Order";
    TransactionCategoryType["Emission"] = "Emission";
    TransactionCategoryType["Thanks"] = "Thanks";
    TransactionCategoryType["ThanksCommission"] = "ThanksCommission";
    TransactionCategoryType["ThanksJoin"] = "ThanksJoin";
    TransactionCategoryType["ThanksJoinCommission"] = "ThanksJoinCommission";
    TransactionCategoryType["Achievement"] = "Achievement";
})(TransactionCategoryType || (TransactionCategoryType = {}));
export var TransactionCategoryFilterType;
(function (TransactionCategoryFilterType) {
    TransactionCategoryFilterType["Activity"] = "Activity";
    TransactionCategoryFilterType["Order"] = "Order";
    TransactionCategoryFilterType["Emission"] = "Emission";
    TransactionCategoryFilterType["Thanks"] = "Thanks";
    TransactionCategoryFilterType["Achievement"] = "Achievement";
})(TransactionCategoryFilterType || (TransactionCategoryFilterType = {}));
export var TransactionStatusType;
(function (TransactionStatusType) {
    TransactionStatusType["Confirmed"] = "Confirmed";
    TransactionStatusType["Canceled"] = "Canceled";
})(TransactionStatusType || (TransactionStatusType = {}));
export var TransactionFilterType;
(function (TransactionFilterType) {
    TransactionFilterType["ManagerList"] = "ManagerList";
    TransactionFilterType["MyProfileOperations"] = "MyProfileOperations";
})(TransactionFilterType || (TransactionFilterType = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { SURVEYS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    modalFilterValues: {
        authorIds: [],
        endDate: null,
        startDate: null,
    },
    isArchived: false,
    isOpenFiter: false,
    searchString: '',
};
var surveysFilterServiceState = createSlice({
    name: SURVEYS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        toggleIsArchivedStatus: function (state) {
            state.isArchived = !state.isArchived;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilterValue: function (state, action) {
            var _a, _b;
            var key = action.payload;
            if (key === 'authorIds') {
                state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_a = {}, _a[key] = [], _a));
            }
            else {
                state.modalFilterValues = __assign(__assign({}, state.modalFilterValues), (_b = {}, _b[key] = null, _b));
            }
        },
        removeAuthorId: function (state, action) {
            var userId = action.payload;
            if (state.modalFilterValues.authorIds.includes(userId)) {
                state.modalFilterValues.authorIds = state.modalFilterValues.authorIds.filter(function (id) { return userId !== id; });
            }
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        },
        openFilter: function (state) {
            state.isOpenFiter = true;
        },
        closeFilter: function (state) {
            state.isOpenFiter = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = surveysFilterServiceState.actions;
export default surveysFilterServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from 'react';
import { EE, EventsBus } from 'common/utils/events-bus';
export var useOpenCreateRequestFromAction = function (params) {
    return useCallback(function () {
        EE.emit(EventsBus.openCreateRequestForm, __assign({ isOpen: true }, (params !== null && params !== void 0 ? params : {})));
    }, [params]);
};

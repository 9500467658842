var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { customizationsServiceTealBalanceCurrencyIconPathSelector } from 'common/services/customizations-service/selectors';
import { Icon } from 'ui-kit/icon/icon';
import { TealCircleIcon } from 'common/icons';
import { FILE_END_POINT } from 'common/constants/common';
import styles from './currency-icon.module.scss';
export var CurrencyIcon = function (_a) {
    var _b = _a.width, width = _b === void 0 ? 1.5 : _b, h = _a.height, className = _a.className, rest = __rest(_a, ["width", "height", "className"]);
    var currencyIconPath = useSelector(customizationsServiceTealBalanceCurrencyIconPathSelector);
    var height = h || width;
    if (!currencyIconPath)
        return (_jsx(Icon, __assign({}, rest, { withoutRtlMirroring: true, width: width, height: height, className: className, component: TealCircleIcon })));
    return (_jsx("img", { className: cs(styles.currencyIcon, className), src: "".concat(FILE_END_POINT, "/").concat(currencyIconPath), style: { width: "".concat(width, "rem"), height: "".concat(height, "rem") } }));
};

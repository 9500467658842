import { STAND } from 'common/constants/common';
import { DEFAULT_REAL_CURRENCY_NAME, DEFAULT_VIRTUAL_CURRENCY_NAME } from 'common/constants/currency-names';
var POSTFIX = "-".concat(STAND);
var CUSTOMIZATION = 'customization' + POSTFIX;
var COMPANY_CULTURE = 'culture' + POSTFIX;
var COMPANY_ENABLED_FEATURE_KEYS = 'companyEnabledFeatureKeys' + POSTFIX;
var DEFAULT_COMPANY_CULTURE = { id: '', localizationKey: 'en-US' };
var DEFAULT_COMPANY_CUSTOMIZATIONS = {
    id: '',
    realCurrencyName: DEFAULT_REAL_CURRENCY_NAME,
    conversion: null,
    virtualCurrencyName: DEFAULT_VIRTUAL_CURRENCY_NAME,
    logoImage: null,
    tealBalanceCurrencyIcon: null,
    thankBalanceCurrencyIcon: null,
    colorsCustomizationJson: null,
    banners: [],
    newsCompanyTitle: null,
    newsCompanyAvatar: null,
};
var getDataByKey = function (key, defaultData) {
    var data = localStorage.getItem(key) || '';
    return data ? JSON.parse(data) : defaultData;
};
var getCustomisation = function () { return getDataByKey(CUSTOMIZATION, DEFAULT_COMPANY_CUSTOMIZATIONS); };
var getCompanyCulture = function () { return getDataByKey(COMPANY_CULTURE, DEFAULT_COMPANY_CULTURE); };
var getCompanyEnabledFeatureKeys = function () {
    return getDataByKey(COMPANY_ENABLED_FEATURE_KEYS, []);
};
export var customizationService = Object.freeze({
    setData: function (data) {
        if (data.customization) {
            localStorage.setItem(CUSTOMIZATION, JSON.stringify(data.customization));
        }
        localStorage.setItem(COMPANY_CULTURE, JSON.stringify(data.culture));
        localStorage.setItem(COMPANY_ENABLED_FEATURE_KEYS, JSON.stringify(data.companyEnabledFeatureKeys));
    },
    getData: function () { return ({
        customization: getCustomisation(),
        culture: getCompanyCulture(),
        companyEnabledFeatureKeys: getCompanyEnabledFeatureKeys(),
    }); },
    getExistCastomizationStatus: function () {
        return Boolean(localStorage.getItem(CUSTOMIZATION) ||
            localStorage.getItem(COMPANY_CULTURE) ||
            localStorage.getItem(COMPANY_ENABLED_FEATURE_KEYS));
    },
    getVirtualCurrencyName: function () { return getCustomisation().virtualCurrencyName || DEFAULT_VIRTUAL_CURRENCY_NAME; },
    getRealCurrencyName: function () { return getCustomisation().realCurrencyName || DEFAULT_REAL_CURRENCY_NAME; },
    getTealBalanceCurrencyIcon: function () { return getCustomisation().tealBalanceCurrencyIcon; },
    getThankBalanceCurrencyIcon: function () { return getCustomisation().thankBalanceCurrencyIcon; },
    getLogoImage: function () { return getCustomisation().logoImage; },
    getStylesMap: function () { return getCustomisation().colorsCustomizationJson; },
    getNewsCompanyTitle: function () { return getCustomisation().newsCompanyTitle; },
    getNewsCompanyAvatar: function () { var _a, _b; return (_b = (_a = getCustomisation().newsCompanyAvatar) === null || _a === void 0 ? void 0 : _a.fullPath) !== null && _b !== void 0 ? _b : null; },
    getCultureLocalizationKey: function () { return getCompanyCulture().localizationKey; },
    checkEnabledFeatureKey: function (key) { return getCompanyEnabledFeatureKeys().includes(key); },
    reset: function () {
        localStorage.removeItem(CUSTOMIZATION);
        localStorage.removeItem(COMPANY_CULTURE);
        localStorage.removeItem(COMPANY_ENABLED_FEATURE_KEYS);
        return this.getData();
    },
});

import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import { useOnScreen } from '../../hooks/use-on-screen';
export var Observer = function (_a) {
    var cb = _a.cb, isObserved = _a.isObserved, className = _a.className, style = _a.style;
    var observableElementRef = useRef(null);
    var isOnScreen = useOnScreen({ ref: observableElementRef });
    useEffect(function () {
        if (isObserved && isOnScreen) {
            cb();
        }
    }, [isObserved, isOnScreen]);
    return _jsx("div", { ref: observableElementRef, className: className, style: style });
};

var _a;
import { combineReducers } from 'redux';
import { developmentBlockEmployeesRootReducer, } from '../my-employees-page/services/root-reducer';
import { EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES } from '../my-employees-page/services/constants';
import developmentBlockMyDevelopmentServiceReducer from '../my-development-page/services/reducer';
import { DEVELOPMENT_BLOCK_MY_DEVELOPMENT_SERVICES_REDUCER_NAMESPACES } from '../my-development-page/services/constants';
export var developmentBlockRootServicesReducer = combineReducers((_a = {},
    _a[EMPLOYEES_DEVELOPMENT_BLOCK_SERVICES_REDUCER_NAMESPACES.developmentBlockEmployees] = developmentBlockEmployeesRootReducer,
    _a[DEVELOPMENT_BLOCK_MY_DEVELOPMENT_SERVICES_REDUCER_NAMESPACES.developmentBlockMyDevelopment] = developmentBlockMyDevelopmentServiceReducer,
    _a));

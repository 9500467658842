var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateProperties } from 'common/constants/redux-base-state';
import { CHAT_SERVICES_REDUCER_NAMESPACES } from './constants';
export var initialState = __assign(__assign({}, baseStateProperties), { pageSize: 10, isJoiningToChatFailed: false, data: [], loading: true });
var chatServiceState = createSlice({
    name: CHAT_SERVICES_REDUCER_NAMESPACES.chatList,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = __spreadArray(__spreadArray([], state.data, true), action.payload, true);
            state.loading = false;
        }, setIsJoiningToChatFailed: function (state, action) {
            state.isJoiningToChatFailed = action.payload;
        }, addMessage: function (state, action) {
            state.data = __spreadArray([action.payload], state.data, true);
            state.loading = false;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = chatServiceState.actions;
export default chatServiceState.reducer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ContentWrapper } from 'ui-kit/content-wrapper';
import { PageLoader } from 'ui-kit/loader';
import { Logo } from 'common/components/logo';
import { FeedbackModal } from 'pages/modules/feedback-modal';
import styles from './guest-layout.module.scss';
export var GuestLayout = function (_a) {
    var withRiseze = _a.withRiseze;
    return (_jsxs("div", __assign({ className: "".concat(styles.root).concat(withRiseze ? ' mainLayoutOld' : '') }, { children: [_jsx("header", __assign({ className: styles.header }, { children: _jsx(Logo, {}) })), _jsx(ContentWrapper, __assign({ className: styles.content, component: "main" }, { children: _jsx(Suspense, __assign({ fallback: _jsx(PageLoader, {}) }, { children: _jsx(Outlet, {}) })) })), _jsx(FeedbackModal, {})] })));
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyEnabledFeatureKeyType, RoleType } from 'common/types/server-api-dtos';
import { userService } from 'common/utils/user-service';
import { getAdminRoutes, getUserRoutes, getSupervisorRoutes, getManagerRoutes, } from 'common/constants/routes';
var getCurrentLinks = function (routes, featureToggleKeys, hideMyDevelopment) {
    return routes.reduce(function (acc, item) {
        if (item.isNotIncludeInMenu ||
            (item.featureToggleKey &&
                ((item.featureToggleKey === CompanyEnabledFeatureKeyType.MyDevelopment && hideMyDevelopment) ||
                    !featureToggleKeys.includes(item.featureToggleKey))))
            return acc;
        return __spreadArray(__spreadArray([], acc, true), [{ to: item.path, label: item.title, icon: item.icon }], false);
    }, []);
};
export var useMenuSectionData = function (_a) {
    var sectionName = _a.sectionName, featureToggleKeys = _a.featureToggleKeys, isNotDesktop = _a.isNotDesktop, hideMyDevelopment = _a.hideMyDevelopment;
    var t = useTranslation().t;
    return useMemo(function () {
        if (sectionName === 'user' && userService.hasRole(RoleType.Basic)) {
            return {
                title: t('common.menu.sections.user'),
                links: getCurrentLinks(isNotDesktop ? getUserRoutes().filter(function (item) { return !item.isShownInFooter; }) : getUserRoutes(), featureToggleKeys, hideMyDevelopment),
            };
        }
        if (sectionName === 'supervisor' && userService.hasRole(RoleType.Supervisor)) {
            return {
                title: t('common.menu.sections.supervisor'),
                links: getCurrentLinks(getSupervisorRoutes(), featureToggleKeys),
            };
        }
        if (sectionName === 'manager' && userService.hasRole(RoleType.Manager)) {
            return {
                title: t('common.menu.sections.manager'),
                links: getCurrentLinks(getManagerRoutes(), featureToggleKeys),
            };
        }
        if (sectionName === 'admin' && userService.hasRole([RoleType.Admin, RoleType.RootAdmin])) {
            return {
                title: t('common.menu.sections.administrator'),
                links: getCurrentLinks(getAdminRoutes(), featureToggleKeys),
            };
        }
        return null;
    }, [t, sectionName, featureToggleKeys, isNotDesktop, hideMyDevelopment]);
};

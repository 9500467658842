import { createContext } from 'react';
var defaultValue = {
    subscribeActiveByIndex: function () { return function () { }; },
    subscribeActiveTab: function () { return function () { }; },
    setActiveTab: function () { },
    getActiveTab: function () { return 0; },
    subscribeDisabledTabByIndex: function () { return function () { }; },
    subscribeAllDisabledTabs: function () { return function () { }; },
    toggleDisabledTab: function () { },
    setDisabledTabs: function () { },
    getDisabledTabs: function () { return []; },
};
export var TabsObservableContext = createContext(defaultValue);

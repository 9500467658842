var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import cs from 'classnames';
import styles from './tab-content.module.scss';
import { useTabsSubscribers } from '../tabs-observable/use-tabs-subscriber';
export var TabContent = function (_a) {
    var _b;
    var tabIndex = _a.tabIndex, className = _a.className, style = _a.style, children = _a.children;
    var isActiveTab = useTabsSubscribers({ observedTabIndex: tabIndex }).isActiveTab;
    return (_jsx("div", __assign({ className: cs(styles.tabsContent, className, (_b = {},
            _b[styles.active] = isActiveTab,
            _b[styles.hidden] = !isActiveTab,
            _b)), style: style }, { children: children })));
};

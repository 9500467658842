var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cs from 'classnames';
import { Typography } from 'ui-kit/typography';
import { Icon } from 'ui-kit/icon';
import { LoaderSmallIcon, LoaderMiddleIcon, LoaderBigIcon } from './icons';
import styles from './loader.module.scss';
var IconsMap = {
    small: {
        component: LoaderSmallIcon,
        width: 1.5,
        viewBox: '0 0 24 24',
    },
    medium: {
        component: LoaderMiddleIcon,
        width: 5.25,
        viewBox: '0 0 84 84',
    },
    large: {
        component: LoaderBigIcon,
        width: 6.6875,
        viewBox: '0 0 107 107',
    },
};
export var Loader = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'small' : _b, color = _a.color, fill = _a.fill, className = _a.className, style = _a.style;
    var iconData = fill ? __assign(__assign({}, IconsMap[variant]), { fill: fill }) : __assign({}, IconsMap[variant]);
    return _jsx(Icon, __assign({}, iconData, { className: cs(styles.loader, className), color: color, style: style }));
};
export var ListLoader = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'medium' : _b, loading = _a.loading, rest = __rest(_a, ["variant", "loading"]);
    return loading ? (_jsx("div", __assign({ className: styles.listLoader }, { children: _jsx(Loader, __assign({ variant: variant }, rest)) }))) : null;
};
export var LoaderWrapper = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'medium' : _b, title = _a.title, loading = _a.loading, children = _a.children, rest = __rest(_a, ["variant", "title", "loading", "children"]);
    return (_jsxs("div", __assign({ className: styles.root }, { children: [loading && (_jsxs("div", __assign({ className: styles.loaderWrapper }, { children: [_jsx(Loader, __assign({ variant: variant }, rest)), title ? (_jsx(Typography, __assign({ color: "main", className: styles.title }, { children: title }))) : null] }))), children] })));
};
export var PageLoader = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'large' : _b, title = _a.title, rest = __rest(_a, ["variant", "title"]);
    return (_jsxs("div", __assign({ className: styles.pageLoaderWrapper }, { children: [_jsx(Loader, __assign({ variant: variant }, rest)), title ? (_jsx(Typography, __assign({ color: "main", className: styles.title }, { children: title }))) : null] })));
};
export var LoaderCover = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'medium' : _b, rest = __rest(_a, ["variant"]);
    return (_jsx("div", __assign({ className: styles.loaderCover }, { children: _jsx("div", __assign({ className: styles.wrapper }, { children: _jsx(Loader, __assign({ variant: variant }, rest)) })) })));
};

import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from '../modules/error-page';
export var NoMatch = function (_a) {
    var hideGoBackButton = _a.hideGoBackButton;
    var t = useTranslation().t;
    return (_jsx(ErrorPage, { code: 404, title: t('noMatchPage.title'), decription: t('noMatchPage.description'), comment: t('noMatchPage.comment'), hideGoBackButton: hideGoBackButton }));
};
export var NoMatchWithShowErrorSelector = function (_a) {
    var hideGoBackButton = _a.hideGoBackButton, showErrorSelector = _a.showErrorSelector;
    var showError = useSelector(showErrorSelector);
    return showError ? _jsx(NoMatch, { hideGoBackButton: hideGoBackButton }) : null;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { baseListReducer } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES } from './constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { isOpen: false, onlyNotRead: false, lastNotificationDate: undefined, pageSize: 50, data: {
        total: 0,
        pageData: {
            page: 0,
            pageSize: 50,
        },
        content: [],
    } });
var notificationDrawerServiceState = createSlice({
    name: NOTIFICATION_DRAWER_SERVICES_REDUCER_NAMESPACES.drawer,
    initialState: initialState,
    reducers: __assign({ openDrawer: function (state) {
            state.isOpen = true;
            state.lastNotificationDate = new Date().toISOString();
        }, setOnlyNotRead: function (state, action) {
            state.onlyNotRead = action.payload;
        }, setLastNotificationDate: function (state, action) {
            state.lastNotificationDate = action.payload;
        }, setData: function (state, action) {
            var _a;
            var _b = action.payload, total = _b.total, pageData = _b.pageData, content = _b.content;
            if (!state.initialized || ((_a = state.data) === null || _a === void 0 ? void 0 : _a.total) !== total) {
                state.totalPages = Math.ceil((total || 1) / pageData.pageSize);
            }
            state.data.total = total;
            state.data.pageData = pageData;
            state.data.content = pageData.page !== 0 ? __spreadArray(__spreadArray([], state.data.content, true), content, true) : __spreadArray([], content, true);
            state.currentPage = pageData.page;
            state.loading = false;
            if (content.length) {
                state.lastNotificationDate = content[0].createdOn;
            }
            if (state.pageSize !== pageData.pageSize)
                state.pageSize = pageData.pageSize;
            if (!state.initialized)
                state.initialized = true;
        }, setElementRead: function (state, action) {
            if (!action.payload) {
                state.data.content.forEach(function (el) {
                    el.isRead = true;
                });
            }
            else {
                var notification = state.data.content.find(function (el) { return el.id === action.payload; });
                if (notification) {
                    notification.isRead = !notification.isRead;
                }
            }
        }, closeDrawer: function () { return initialState; } }, baseListReducer),
});
export var actions = notificationDrawerServiceState.actions;
export default notificationDrawerServiceState.reducer;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyleTypeDto } from 'common/types/server-api-dtos';
import { customizationsServiceStylesMapPathSelector } from 'common/services/customizations-service/selectors';
import { FILE_END_POINT } from 'common/constants/common';
var getRgbColor = function (value) {
    var match = value.match(/^rgb\((.*)\)$/);
    if (match)
        return match[1];
    return null;
};
function addStyle(value, acc, prevName) {
    if (acc === void 0) { acc = []; }
    if (prevName === void 0) { prevName = '-'; }
    var result = __spreadArray([], acc, true);
    var enteries = Object.entries(value);
    enteries.forEach(function (_a) {
        var key = _a[0], data = _a[1];
        var name = "".concat(prevName, "-").concat(key);
        if ('value' in data) {
            var value_1 = '';
            var rgbValue = null;
            if (data.type === StyleTypeDto.color) {
                value_1 = data.value;
                rgbValue = getRgbColor(value_1);
            }
            else {
                var currentData = data.value;
                var x = Number(currentData.x) && Number(currentData.x) / 16;
                var y = Number(currentData.y) && Number(currentData.y) / 16;
                var spread = Number(currentData.spread) && Number(currentData.spread) / 16;
                var blur = Number(currentData.blur) && Number(currentData.blur) / 16;
                value_1 = "".concat(x, "rem ").concat(y, "rem ").concat(blur, "rem ").concat(spread, "rem ").concat(currentData.color);
            }
            result.push({ key: name, value: value_1 });
            rgbValue && result.push({ key: "".concat(name, "-rgb"), value: rgbValue });
        }
        else {
            var newResult = addStyle(data, result, name);
            result.push.apply(result, newResult);
        }
    });
    return result;
}
var getJsonCustomizationFile = function (jsonPath) {
    return fetch("".concat(FILE_END_POINT, "/").concat(jsonPath)).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!response.ok) return [3 /*break*/, 2];
                    return [4 /*yield*/, response.json()];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data ? data : null];
                case 2:
                    console.log('Error loading customization HTTP: ' + response.status);
                    return [2 /*return*/, null];
            }
        });
    }); });
};
export var ThemeObserver = function () {
    var jsonPath = useSelector(customizationsServiceStylesMapPathSelector);
    useEffect(function () {
        var rootStyles = document.documentElement.style;
        if (jsonPath) {
            getJsonCustomizationFile(jsonPath)
                .then(function (data) {
                if (data) {
                    var stylesCollection = addStyle(data, []);
                    stylesCollection.forEach(function (_a) {
                        var key = _a.key, value = _a.value;
                        rootStyles.setProperty(key, value);
                    });
                }
            })
                .catch(function () {
                console.log('Error loading customization');
            });
        }
    }, [jsonPath]);
    return null;
};

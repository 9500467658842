var _a;
import { createSlice } from '@reduxjs/toolkit';
import { EmployeesStatisticsFilterType } from '../../types';
import { TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = (_a = {},
    _a[EmployeesStatisticsFilterType.EmployeesCount] = { interval: null, selectedIntervalVariant: null },
    _a[EmployeesStatisticsFilterType.StaffTurnover] = { interval: null, selectedIntervalVariant: null },
    _a);
var employeesStatisticsFilterServiceState = createSlice({
    name: TEAM_STATISTICS_SERVICES_REDUCER_NAMESPACES.employeesStatisticsFilter,
    initialState: initialState,
    reducers: {
        setInterval: function (state, action) {
            var _a = action.payload, filterType = _a.filterType, interval = _a.interval, cb = _a.cb;
            cb === null || cb === void 0 ? void 0 : cb(interval);
            state[filterType].interval = interval;
        },
        setIntervalVariant: function (state, action) {
            state[action.payload.filterType].selectedIntervalVariant = action.payload.intervalVariant;
        },
        resetState: function () { return initialState; },
    },
});
export var actions = employeesStatisticsFilterServiceState.actions;
export default employeesStatisticsFilterServiceState.reducer;

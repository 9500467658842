export var PostTypeDto;
(function (PostTypeDto) {
    PostTypeDto["NewsPost"] = "NewsPost";
    PostTypeDto["ThankPost"] = "ThankPost";
    PostTypeDto["Achievement"] = "Achievement";
    PostTypeDto["WeekLeadersPost"] = "WeekLeadersPost";
})(PostTypeDto || (PostTypeDto = {}));
export var PostStateType;
(function (PostStateType) {
    PostStateType["Draft"] = "Draft";
    PostStateType["Published"] = "Published";
    PostStateType["Archived"] = "Archived";
})(PostStateType || (PostStateType = {}));
export var SupervisorType;
(function (SupervisorType) {
    SupervisorType["Supervisor"] = "Supervisor";
    SupervisorType["PeopleManager"] = "PeopleManager";
    SupervisorType["PeopleManagerDelegate"] = "PeopleManagerDelegate";
})(SupervisorType || (SupervisorType = {}));
export var PostCommentType;
(function (PostCommentType) {
    PostCommentType["CompanyComment"] = "CompanyComment";
    PostCommentType["PersonalComment"] = "PersonalComment";
})(PostCommentType || (PostCommentType = {}));
export var PostCommentStateType;
(function (PostCommentStateType) {
    PostCommentStateType["Hidden"] = "Hidden";
    PostCommentStateType["Active"] = "Active";
})(PostCommentStateType || (PostCommentStateType = {}));
export var WeekLeaderCategoryType;
(function (WeekLeaderCategoryType) {
    WeekLeaderCategoryType["ByThanks"] = "ByThanks";
    WeekLeaderCategoryType["ByCorporateCurrency"] = "ByCorporateCurrency";
    WeekLeaderCategoryType["ByAwardsAchieved"] = "ByAwardsAchieved";
})(WeekLeaderCategoryType || (WeekLeaderCategoryType = {}));
export var WeekLeaderType;
(function (WeekLeaderType) {
    WeekLeaderType["LeaderByCorporateCurrency"] = "LeaderByCorporateCurrency";
    WeekLeaderType["LeaderByAwardsAchieved"] = "LeaderByAwardsAchieved";
    WeekLeaderType["LeaderByGivenThanks"] = "LeaderByGivenThanks";
    WeekLeaderType["LeaderByReceivedThanks"] = "LeaderByReceivedThanks";
    WeekLeaderType["LeaderByGivenThanksWithCurrency"] = "LeaderByGivenThanksWithCurrency";
    WeekLeaderType["LeaderByReceivedThanksWithCurrency"] = "LeaderByReceivedThanksWithCurrency";
})(WeekLeaderType || (WeekLeaderType = {}));

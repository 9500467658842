var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var baseStateProperties = {
    loading: false,
    error: null,
    initialized: false,
};
export var baseStateListProperties = __assign({ currentPage: 0, pageSize: 12, totalPages: 1 }, baseStateProperties);

var _a;
import { combineReducers } from 'redux';
import activitiesFilterServiceReducer from './filter-service/reducer';
import activitiesServiceReducer from './activities-service/reducer';
import activitySuggestionsServiceReducer from './activity-suggestions-service/reducer';
import activitiesCategorieForFormServiceReducer from './activities-categories-for-form-service/reducer';
import { ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES } from './constants';
export var activitiesPageServicesRootReducer = combineReducers((_a = {},
    _a[ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES.activitiesList] = activitiesServiceReducer,
    _a[ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES.activitiesFilter] = activitiesFilterServiceReducer,
    _a[ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES.activitySuggestionsList] = activitySuggestionsServiceReducer,
    _a[ACTIVITIES_PAGE_SERVICES_REDUCER_NAMESPACES.activitiesCategoriesForForm] = activitiesCategorieForFormServiceReducer,
    _a));

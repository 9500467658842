var _a;
import { combineReducers } from 'redux';
import eNPSSurveyResultsServiceReducer from './enps-survey-results-service/reducer';
import eNPSAnswersListFilterServiceReducer from './enps-answers-list-filter-service/reducer';
import eNPSAnswersListServiceReducer from './enps-answers-list-service/reducer';
import { ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var eNPSSurveyResultsRootServicesReducer = combineReducers((_a = {},
    _a[ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.eNPSSurveyResults] = eNPSSurveyResultsServiceReducer,
    _a[ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.eNPSAnswersListFilter] = eNPSAnswersListFilterServiceReducer,
    _a[ENPS_SURVEY_RESULTS_SERVICES_REDUCER_NAMESPACES.eNPSAnswersList] = eNPSAnswersListServiceReducer,
    _a));

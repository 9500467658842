import { useEffect, useState, useRef } from 'react';
export var useMounted = function (_a) {
    var isOpen = _a.isOpen, animationTime = _a.animationTime;
    var _b = useState(false), mounted = _b[0], setMounted = _b[1];
    var timerIdRef = useRef(null);
    var mountedRef = useRef(false);
    useEffect(function () { return function () {
        if (timerIdRef.current)
            clearTimeout(timerIdRef.current);
    }; }, []);
    useEffect(function () {
        mountedRef.current = mounted;
    }, [mounted]);
    useEffect(function () {
        if (isOpen) {
            if (!mountedRef.current)
                setMounted(true);
            if (timerIdRef.current)
                clearTimeout(timerIdRef.current);
        }
        else if (mountedRef.current) {
            timerIdRef.current = Number(setTimeout(function () {
                setMounted(false);
            }, animationTime !== null && animationTime !== void 0 ? animationTime : 300));
        }
    }, [isOpen, animationTime]);
    return mounted;
};

var _a;
import { combineReducers } from 'redux';
import gratitudesStatisticsServiceReducer from './gratitudes-statistics-service/reducer';
import gratitudesStatisticsChartServiceReducer from './gratitudes-statistics-charts-service/reducer';
import gratitudesStatisticsFilterServiceReducer from './gratitudes-statistics-filter-service/reducer';
import { MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var modularAnalyticsGratitudesStatisticsRootReducer = combineReducers((_a = {},
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.gratitudesStatistics] = gratitudesStatisticsServiceReducer,
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.gratitudesStatisticsChart] = gratitudesStatisticsChartServiceReducer,
    _a[MODULAR_ANALYTICS_SERVICES_REDUCER_NAMESPACES.gratitudesStatisticsFilter] = gratitudesStatisticsFilterServiceReducer,
    _a));

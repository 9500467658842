var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNotificationDrawerActions } from 'common/layouts/main-layout/service/notification-drawer-service/hooks';
import { useProfileActions } from 'common/services/profile-service/hooks';
import { notificationDrawerServiceInitializedSelector, notificationDrawerServiceIsNotReadSelector, notificationDrawerServiceLoadingSelector, notificationDrawerServicePageSizeSelector, } from 'common/layouts/main-layout/service/notification-drawer-service/selectors';
import { profileServiceUserHasNewNotificationsSelector, profileServiceUserHasUnreadNotificationsSelector, } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { Button } from 'ui-kit/button';
export var NotificationListActions = function (_a) {
    var notificationContainerRef = _a.notificationContainerRef, props = __rest(_a, ["notificationContainerRef"]);
    var t = useTranslation().t;
    var _b = useNotificationDrawerActions(), fetchNotificationsAction = _b.fetchNotificationsAction, readNotificationAction = _b.readNotificationAction;
    var _c = useProfileActions(), setHasUnreadNotifications = _c.setHasUnreadNotifications, setHasNewNotifications = _c.setHasNewNotifications;
    var initialized = useSelector(notificationDrawerServiceInitializedSelector);
    var loading = useSelector(notificationDrawerServiceLoadingSelector);
    var hasNewNotifications = useSelector(profileServiceUserHasNewNotificationsSelector);
    var hasNotReadNotifications = useSelector(profileServiceUserHasUnreadNotificationsSelector);
    var pageSize = useSelector(notificationDrawerServicePageSizeSelector);
    var onlyNotRead = useSelector(notificationDrawerServiceIsNotReadSelector);
    var handleReadAll = function () {
        readNotificationAction({ successCb: function () { return setHasUnreadNotifications(false); }, isRead: true });
    };
    var handleUpdateList = function () {
        fetchNotificationsAction({
            page: 0,
            pageSize: pageSize,
            onlyNotRead: onlyNotRead,
            successCb: function () {
                var _a;
                setHasNewNotifications(false);
                (_a = notificationContainerRef === null || notificationContainerRef === void 0 ? void 0 : notificationContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0 });
            },
        });
    };
    return (_jsxs(Box, __assign({ display: "flex", justify: "space-between", px: 8 }, props, { children: [_jsx(Button, __assign({ variant: "text", disabled: !initialized || loading || !hasNotReadNotifications, onClick: handleReadAll }, { children: t('common.notificationDrawer.actions.readAll') })), hasNewNotifications && (_jsx(Button, __assign({ variant: "text", onClick: handleUpdateList }, { children: t('common.notificationDrawer.actions.updateList') })))] })));
};

var _a;
import { combineReducers } from 'redux';
import surveysFilterServiceReducer from './filter-service/reducer';
import surveysListServiceReducer from './surveys-list-service/reducer';
import surveysAuthorsServiceReducer from './surveys-authors-sevice/reducer';
import { SURVEYS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var surveysServicesRootReducer = combineReducers((_a = {},
    _a[SURVEYS_SERVICES_REDUCER_NAMESPACES.filter] = surveysFilterServiceReducer,
    _a[SURVEYS_SERVICES_REDUCER_NAMESPACES.surveysList] = surveysListServiceReducer,
    _a[SURVEYS_SERVICES_REDUCER_NAMESPACES.surveysAuthors] = surveysAuthorsServiceReducer,
    _a));

var _a;
import { combineReducers } from 'redux';
import productsServiceReducer from './products-service/reducer';
import productsFilterServiceReducer from './filter-service/reducer';
import editProductServiceReducer from '../edit-product-page/service/reducer';
import { PRODUCTS_SERVICES_REDUCER_NAMESPACES } from './constants';
export var productsServicesRootReducer = combineReducers((_a = {},
    _a[PRODUCTS_SERVICES_REDUCER_NAMESPACES.filter] = productsFilterServiceReducer,
    _a[PRODUCTS_SERVICES_REDUCER_NAMESPACES.productsList] = productsServiceReducer,
    _a[PRODUCTS_SERVICES_REDUCER_NAMESPACES.editProduct] = editProductServiceReducer,
    _a));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { baseReducers } from 'common/utils/base-reduсer';
import { baseStateListProperties } from 'common/constants/redux-base-state';
import { ADDRESS_BOOK_EMPLOYEE_INFO_SERVICES_REDUCER_NAMESPACES } from './constants';
export var initialState = __assign(__assign({}, baseStateListProperties), { data: null, awards: [], loading: true, pageSize: 8 });
var addressBookEmployeeInfoServiceState = createSlice({
    name: ADDRESS_BOOK_EMPLOYEE_INFO_SERVICES_REDUCER_NAMESPACES.addressBookEmployeeInfo,
    initialState: initialState,
    reducers: __assign({ setData: function (state, action) {
            state.data = action.payload;
            state.loading = false;
            if (!state.initialized) {
                state.totalPages = Math.ceil((action.payload.achievements.length || 1) / state.pageSize);
                state.awards = action.payload.achievements.slice(0, state.pageSize);
                state.initialized = true;
            }
        }, setCurrentPage: function (state, action) {
            var _a, _b;
            state.awards = ((_b = (_a = state.data) === null || _a === void 0 ? void 0 : _a.achievements) !== null && _b !== void 0 ? _b : []).slice(action.payload * state.pageSize, (action.payload + 1) * state.pageSize);
            state.currentPage = action.payload;
        }, setPageSize: function (state, action) {
            var _a, _b, _c;
            state.awards = ((_b = (_a = state.data) === null || _a === void 0 ? void 0 : _a.achievements) !== null && _b !== void 0 ? _b : []).slice(0, action.payload);
            state.totalPages = Math.ceil((((_c = state.data) === null || _c === void 0 ? void 0 : _c.achievements.length) || 1) / action.payload);
            state.currentPage = 0;
            state.pageSize = action.payload;
        }, resetState: function () { return initialState; } }, baseReducers),
});
export var actions = addressBookEmployeeInfoServiceState.actions;
export default addressBookEmployeeInfoServiceState.reducer;

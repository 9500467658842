import { createSlice } from '@reduxjs/toolkit';
import { PRODUCTS_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    modalFilterValues: {
        status: null,
        productType: null,
    },
    isFilterModalOpen: false,
    categoryId: null,
};
var productsFilterServiceState = createSlice({
    name: PRODUCTS_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeModalFilterValues: function (state, action) {
            state.modalFilterValues = action.payload;
        },
        resetModalFilter: function (state) {
            state.modalFilterValues = initialState.modalFilterValues;
        },
        resetStatus: function (state) {
            state.modalFilterValues.status = null;
        },
        resetProductType: function (state) {
            state.modalFilterValues.productType = null;
        },
        openFilterModal: function (state) {
            state.isFilterModalOpen = true;
        },
        closeFilterModal: function (state) {
            state.isFilterModalOpen = false;
        },
        setCategoryId: function (state, action) {
            state.categoryId = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = productsFilterServiceState.actions;
export default productsFilterServiceState.reducer;
